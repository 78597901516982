import {Draft, produce} from "immer";

interface BasicChangeEvent {
  target: {
    value: string
  }
}

export type ImmerUpdateType<T> = (recipe: (draft: Draft<T>) => void) => void;
export type HandleImmerUpdateType<T> = <E extends BasicChangeEvent,>(recipe: (draft: Draft<T>, targetValue: string, event: E) => void) => (event: E) => void;

export function useImmerUpdaters<T>(value: T, setValue: (value: T) => void): [(recipe: (draft: Draft<T>) => void) => void, <E extends BasicChangeEvent,>(recipe: (draft: Draft<T>, targetValue: string, event: E) => void) => (e: E) => void] {
    const update = (recipe: (draft: Draft<T>) => void) => setValue(produce(value, draft => {
      recipe(draft);
    }));
    const handleUpdate: HandleImmerUpdateType<T> = <E extends BasicChangeEvent,>(recipe: (draft: Draft<T>, targetValue: string, event: E) => void) => {
      return (event: E) => {
        setValue(
          produce(value, (draft) => {
            recipe(draft, event.target.value, event);
          })
        );
      };
    };
  
    return [update, handleUpdate];
  }
export const styles = {
  cardContainer: {
    margin: 2,
    width: 260,
    minHeight: 260,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "center",
    padding: 10,
  },
  actionCardText: {
    typography: { sm: "h6", xs: "h8" },
  },
  boxContainer: {
    p: 2,
    mb: 2,
    border: "2px solid #D3D3D3",
  },
  boxSubTitle: {
    mb: 2,
    mt: 1,
    display: "flex",
    alignItems: "center",
    hight: 25,
  },
  personWithParams: {
    mb: 2,
    mt: 2,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    minHeight: "60px",
    fontSize: "1rem",
  },
  checkCircleOutlineIcon: {
    mr: 2,
    ml: 2,
    color: "#408844",
  },
  errorOutlineOutlinedIcon: {
    mr: 2,
    ml: 2,
    color: "#ee7918",
  },
  infoOutlinedIcon: {
    mr: 2,
    ml: 2,
    color: "#1892d5",
  },
  dialogMessage: {
    mb: 0,
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    lineHeight: 1.2,
  },
  dialogActionsContainer: {
    mr: 2, 
    mb: 2
  },
  validateAddressBoxContainer: {
    minHeight: 400,
    p: 2, 
    mb: 2, 
    border: "2px solid #D3D3D3"
  },
  validateSpinner: {
    minHeight: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  }
};

export const chipStyles = {
  chip: {
    mb: 0,
    fontSize: 10,
  },
};

export const iconButtonStyles = {
  iconButton: {
    style: {
      maxWidth: "30px",
      maxHeight: "25px",
      minWidth: "30px",
      minHeight: "25px",
    },
  },
};

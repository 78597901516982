import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { IPublicClientApplication, AccountInfo } from '@azure/msal-browser';
import { Dispatch, SetStateAction } from "react";
import authConfig from "../config/app-config.json";

interface UpdateSuccessFunction {  (data: any, refresh?: boolean, message?: string): void; }
interface UpdateErrorFunction { (error: string): void; }
interface Scope {
    url: string;
    scope: string;
}

export class AxiosService {
    private axiosInstance: AxiosInstance;

    constructor(
        baseURL: string,
        instance: IPublicClientApplication,
        accounts: AccountInfo[]
    ) {
        this.axiosInstance = this.createAxiosInstanceSilent(baseURL, instance, accounts);
    }
    private getServiceScopeByUrl = (baseUrl: string) => {
        const authScopes: Scope[] = authConfig?.authScopes || null;
        if (authScopes) {
            for (const scope of authScopes) {
                if (scope?.url === baseUrl) {
                    return scope?.scope;
                }
            }
        }
        return null;
    }
    private createAxiosInstanceSilent = (baseURL: string, instance: any, accounts: any): AxiosInstance => {
        const axiosInstance: AxiosInstance = axios.create({
            baseURL
        });
        const scope: string | null = this.getServiceScopeByUrl(baseURL);
        axiosInstance.interceptors.request.use(
            async (config: AxiosRequestConfig) => {
                try {
                    const silentTokenResponse = await instance.acquireTokenSilent({
                        scopes: [scope], account: accounts[0],
                    });
                    if (silentTokenResponse && silentTokenResponse.accessToken) {
                        if (config.headers) {
                            config.headers['Authorization'] = `Bearer ${silentTokenResponse.accessToken}`;
                        } else {
                            config.headers = { Authorization: `Bearer ${silentTokenResponse.accessToken}` };
                        }
                    }
                } catch (error) {
                    console.error("Silent token acquisition failed:", error);
                }

                return config;
            },
            (error: any) => {
                return Promise.reject(error);
            }
        );

        axiosInstance.interceptors.response.use(
            (response: AxiosResponse) => response,
            async (error: any) => {
                if (error.response && error.response.status === 401) {
                    try {
                        await instance.loginRedirect();
                    } catch (error) {
                        console.error("Login redirect failed:", error);
                    }
                }
                return Promise.reject(error);
            }
        );

        return axiosInstance;
    }
    get(
        endpoint: string,
        params: any,
        setData: Dispatch<SetStateAction<any>>,
        updateSuccess: UpdateSuccessFunction,
        updateError: UpdateErrorFunction
    ) {
        return this.axiosInstance
            .get(`/${endpoint}`, params)
            .then((response: AxiosResponse) => {
                const { data } = response;
                setData(data);
                typeof updateSuccess === 'function' && updateSuccess(data);
                return data;
            })
            .catch((error) => {
                console.error("Fetch error:", error);
                typeof updateError === 'function' && updateError(error);
            });
    }

    post(
        endpoint: string,
        data: any,
        params: any,
        setData: Dispatch<SetStateAction<any>>,
        updateSuccess: UpdateSuccessFunction,
        updateError: UpdateErrorFunction
    ) {
        return this.axiosInstance
            .post(`/${endpoint}`, data, params)
            .then((response: AxiosResponse) => {
                const { data } = response;
                setData(data);
                updateSuccess(data);
            })
            .catch((error) => {
                console.error("Fetch error:", error);
                updateError(error);
            });
    }

    put(
        endpoint: string,
        data: any,
        params: any,
        setData: Dispatch<SetStateAction<any>>,
        updateSuccess: UpdateSuccessFunction,
        updateError: UpdateErrorFunction
    ) {
        return this.axiosInstance
            .put(`/${endpoint}`, data, params)
            .then((response: AxiosResponse) => {
                const { data } = response;
                setData(data);
                updateSuccess(data);
            })
            .catch((error) => {
                console.error("Fetch error:", error);
                updateError(error);
            });
    }
}

import React, { useState } from "react";
import {
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import RetrieveCard from "./ManageCard/retrieveCard";
import RetrieveMember from "./ManageMember/retrieveMember";
import RetrieveAccount from "./ManageAccount/retrieveAccount";
import RetrievePerson from "./ManagePerson/retrievePerson";
import RetrieveOrganization from "./ManageOrganization/retrieveOrganization";
import RetrieveSearch from "./Search/retrieveSearch";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styles } from "../styles/homeStyles";

const buttonLabels = [
  "any",
  "card",
  "member",
  "account",
  "organization",
  "person",
];

const Home = ({ code }) => {
  const [entity, setEntity] = useState(buttonLabels[0]);
  const [show, setShow] = useState(false);

  const hideShow = () => {
    setShow((prev) => !prev);
  };

  const searchEntity = () => {
    switch (entity) {
      case "card":
        return <RetrieveCard />;
      case "member":
        return <RetrieveMember />;
      case "account":
        return <RetrieveAccount code={code} />;
      case "person":
        return <RetrievePerson code={code} />;
      case "organization":
        return <RetrieveOrganization code={code} />;
      default:
        return <RetrieveSearch />;
    }
  };

  return (
    <>
      <Container disableGutters sx={styles.radioContainer} onClick={hideShow}>
        <ExpandMoreIcon
          sx={{
            transform: show ? "rotate(180deg)" : "rotate(1800deg)",
          }}
        />
        <Typography>Search by entity</Typography>
      </Container>
      {show && (
        <RadioGroup
          style={{ justifyContent: "center" }}
          row
          aria-labelledby="control-label-placement"
          name="position"
          defaultValue="top"
        >
          {buttonLabels.map((label) => (
            <FormControlLabel
              value="top"
              control={<Radio />}
              label={label}
              labelPlacement="top"
              checked={label === entity}
              onClick={() => setEntity(label)}
              key={label}
            />
          ))}
        </RadioGroup>
      )}
      {searchEntity()}
    </>
  );
};

export default Home;

import React, { useState, useContext } from "react";
import { Container } from "@mui/material";
import OrderComponent from "../../components/SaleOrderComponents/OrderComponent";
import SearchComponent from "../../components/SearchComponent";
import { useCurrentAccountInfo } from "../../utils/useCurrentAccountInfo";
import useAxiosInstance from "../../service/useAxiosInstance";
import { msalInstance } from "../../service/msalConfig";
import { axiosGetV2 } from "../../service/axiosApi";
import { AlertContext } from "../../utils/context";
import authConfig from "../../config/app-config.json";
const baseURL = authConfig.baseSaleOrderUrl;

const RetrieveOrder = ({ fromAnySearch, searchId, code }) => {
  const [orderID, setOrderID] = useState(searchId);
  const { username, warehouseLocation } = useCurrentAccountInfo();
  const [orderDetails, setOrderDetails] = useState(null);
  const alertFunction = useContext(AlertContext);

  const updateError = (error) => alertFunction("updateError", error);
  const updateSuccess = (data, refresh, message) =>
    alertFunction("updateSuccess", { data, refresh, message });

  const axiosInstance = useAxiosInstance(baseURL, msalInstance);
  const headers = { "country-code": code };

  const handleOrderIDChange = (id) => setOrderID(id);

  const handleSearch = (id) => {
    setOrderDetails(null);

    axiosGetV2(
      axiosInstance,
      `retrieve-order/?orderId=${id}`,
      { headers },
      setOrderDetails,
      updateSuccess,
      updateError
    );
  };

  return (
    <Container disableGutters justifyContent="center">
      {!fromAnySearch && (
        <SearchComponent
          callback={() => handleSearch(orderID)}
          label={"Enter Order ID"}
          handleIdChange={handleOrderIDChange}
        />
      )}
      {orderDetails && (
        <>
          <OrderComponent
            orderData={orderDetails}
            orderId={orderDetails.orderId}
          />
        </>
      )}
    </Container>
  );
};

export default RetrieveOrder;

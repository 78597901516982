import { styles as width } from "./generalStyles";

export const styles = {
  commonContainer: { mt: 3, justifyContent: "center" },
  itemContainer: {
    p: 1,
    m: 1,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    position: "relative",
    maxWidth: "100%",
  },
  photoIcon: {
    color: "#A9A9A9",
    width: 50,
    height: 50,
  },
  textContainer: {
    pl: 2,
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-start",
  },
  name: {
    ml: 1,
    wordWrap: "break-word",
    width: "17rem",
    fontWeight: "550",
  },
  address: {
    ml: 1,
    wordWrap: "break-word",
    width: "17rem",
  }
};

import { generateUUID } from "../utils/generateUUID";
import { axiosPostV2 } from "../service/axiosApi";

export const createAccount = (
  axiosInstance,
  path,
  data,
  setData,
  updateSuccess,
  updateError
) => {
  const mgloTransactionId = generateUUID();
  const fullUpdateData = {
    ...data,
    mgloTransactionId,
    mgloTransactionType: "MGLOSignup",
    audit: {
      ...data.audit,
      createdByChannel: "Process",
      mgloTransactionId,
      mgloTransactionType: "MGLOSignup",
      createdOnUtcDateTime: new Date(),
    },
  };

  return axiosPostV2(
    axiosInstance,
    path,
    fullUpdateData,
    {
      headers: {
        "Content-Type": "application/json",
        "country-code": data.country,
      },
    },
    setData,
    updateSuccess,
    updateError
  );
};

export const updateAccount = (
  axiosInstance,
  updateData,
  updateSuccess,
  updateError,
  headers = {}
) => {
  const url = updateData.path.split("/");
  const endpoint = url[url.length - 1];
  delete updateData.path;
  const mgloTransactionId = generateUUID();
  const fullUpdateData = {
    ...updateData,
    mgloTransactionId,
    audit: {
      ...updateData.audit,
      createdByChannel: "Process",
      mgloTransactionType: updateData.mgloTransactionType,
      mgloTransactionId,
      createdOnUtcDateTime: new Date(),
    },
  };

  return axiosPostV2(
    axiosInstance,
    endpoint,
    fullUpdateData,
    { headers: { ...headers, "Content-Type": "application/json" } },
    () => {},
    updateSuccess,
    updateError
  );
};
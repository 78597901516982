import {
  Button,
  Paper,
  Typography,
  FormControlLabel,
  Grid,
  Switch,
} from "@mui/material";
import React, { useState } from "react";
import {
  HandleImmerUpdateType,
  useImmerUpdaters,
} from "../../utils/useImmerUpdaters";
import marketingPreferencesDefaults from "../../data/marketingPreferencesDefaults.json";
import { MarketingPref } from "../../dataTypes/person/personTypes";
import { Audited } from "../../dataTypes/shared/sharedRequestTypes";

interface PersonMarketingPreferenceProps {
  personInfo: Audited<MarketingPref>;
  isProtectedPerson: boolean;
  onUpdate: (personInformation: Audited<MarketingPref>) => void;
}

interface PersonPreferencesOptionsProps {
  preferenceOptions: MarketingPref;
  handleUpdatePersonInfo: HandleImmerUpdateType<MarketingPref>;
  isProtectedPerson: boolean;
}

interface PreferenceItemProps {
  name?: string;
  id: string;
  checked?: boolean;
  label: string;
  labelId: string;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PreferenceItem = (props: PreferenceItemProps) => {
  const handleSwitchChange = (event: React.SyntheticEvent) => {
    props.onChange(event as React.ChangeEvent<HTMLInputElement>);
  };
  return (
    <Grid item xs={12} sm={4}>
      <FormControlLabel
        name={props.name}
        control={<Switch id={props.id} checked={props.checked} />}
        label={props.label}
        onChange={handleSwitchChange}
        disabled={props.disabled}
      />
    </Grid>
  );
};

const mergeMarketingPreferencesDefaults = (
  data: MarketingPref,
  country: string,
) => {
  const defaultMarketingPreferences = marketingPreferencesDefaults[country];
  return { ...defaultMarketingPreferences, ...data };
};

const Preferences = ({
  preferenceOptions,
  handleUpdatePersonInfo,
  isProtectedPerson,
}: PersonPreferencesOptionsProps) => {
  const isUSA = preferenceOptions.country === "USA";
  const isCAN = preferenceOptions.country === "CAN";

  return (
    <Grid container spacing={3}>
      {isUSA && (
        <PreferenceItem
          label="Mail Magazine"
          labelId="mailMagazine"
          id="mailMagazine"
          disabled={isProtectedPerson}
          checked={preferenceOptions.mailMagazine}
          onChange={handleUpdatePersonInfo(
            (p, _, e) => (p.mailMagazine = e.target.checked),
          )}
        />
      )}
      {(isUSA || isCAN) && (
        <PreferenceItem
          label="Mail Coupons"
          labelId="mailCoupons"
          id="mailCoupons"
          disabled={isProtectedPerson}
          checked={preferenceOptions.mailCoupons}
          onChange={handleUpdatePersonInfo(
            (p, _, e) => (p.mailCoupons = e.target.checked),
          )}
        />
      )}
      {isUSA && (
        <PreferenceItem
          label="Digital Magazine"
          labelId="digMagazine"
          id="digMagazine"
          disabled={isProtectedPerson}
          checked={preferenceOptions.digMagazine}
          onChange={handleUpdatePersonInfo(
            (p, _, e) => (p.digMagazine = e.target.checked),
          )}
        />
      )}
    </Grid>
  );
};

const PersonMarketingPreference = ({
  personInfo,
  isProtectedPerson,
  onUpdate,
}: PersonMarketingPreferenceProps) => {
  const [personInformation, setPersonInformation] = useState(
    mergeMarketingPreferencesDefaults(personInfo, personInfo.country),
  );
  const [_, handleUpdatePersonInfo] = useImmerUpdaters(
    personInformation,
    setPersonInformation,
  );

  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onUpdate(personInformation);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Marketing Preferences
      </Typography>

      <Preferences
        preferenceOptions={personInformation}
        isProtectedPerson={isProtectedPerson}
        handleUpdatePersonInfo={handleUpdatePersonInfo}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: 3 }}
        onClick={handleSubmit}
        disabled={isProtectedPerson}
      >
        Update Marketing Preferences
      </Button>
    </Paper>
  );
};

export default PersonMarketingPreference;

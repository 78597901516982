import React, { FC, useEffect, useState } from "react";
import { Button, Container, TextField } from "@mui/material";
import { styles } from "../styles/generalStyles";
import { useLocation } from "@reach/router";
import { SearchComponentProps } from "../dataTypes/search/searchTypes";

const SearchComponent: FC<SearchComponentProps> = ({
  callback,
  label,
  handleIdChange,
}) => {
  const [searchID, setSearchID] = useState("");
  const location = useLocation();

  useEffect(() => {
    const cardId = location.search.match(/[1-9]{1}[0-9]{5,14}/i);
    const GUID = location.search.match(
      /[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}/i
    );
    let id = "";

    if (GUID?.length && GUID.length > 0) {
      id = GUID[0];
    } else if (cardId?.length && cardId.length > 0) {
      id = cardId[0];
    }
    setSearchID(id);
  }, [location.search]);

  const handleSearchIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchID(event.target.value);
    handleIdChange(event.target.value);
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      callback((event.target as HTMLInputElement).value);
      setSearchID((event.target as HTMLInputElement).value);
    }
  };

  const clearSearch = () => {
    handleIdChange("");
    setSearchID("");
  };

  const handleSearchButtonClick = () => callback(searchID);

  return (
    <Container disableGutters sx={styles.cardWidth}>
      <TextField
        label={label}
        variant="outlined"
        value={searchID}
        onChange={handleSearchIDChange}
        fullWidth
        style={{ marginRight: "10px" }}
        onKeyDown={handleOnKeyDown}
        sx={{ mt: 2 }}
        InputProps={{
          endAdornment: (
            <Button
              variant="text"
              onClick={clearSearch}
              sx={{ mr: 0, px: 0, color: "GrayText" }}
            >
              X
            </Button>
          ),
        }}
      />
      <Button
        variant="contained"
        sx={{ m: 1, ml: 0, mb: 1.5 }}
        onClick={handleSearchButtonClick}
      >
        Search
      </Button>
    </Container>
  );
};
export default SearchComponent;

import React, { FC, useState } from "react";
import { TextField, Typography, Paper, Container, Button } from "@mui/material";
import { navigate } from "gatsby";
import { styles } from "../../styles/generalStyles";
import cardTypes from "../../data/cardTypes.json";
import { MemberCardComponentProps } from "../../dataTypes/member/memberComponentTypes";
import { MemberCard } from "../../dataTypes/member/memberTypes";

const MemberCardComponent: FC<MemberCardComponentProps> = ({
  memberCardView,
  onAdd,
}) => {
  const [cardData, setCardData] = useState<MemberCard>();
  const [addCardIsOpen, setAddCardIsOpen] = useState(false);

  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();
    cardData && onAdd(cardData);
  };

  const handleNewCardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    cardData && setCardData({ ...cardData, [name]: value });
  };

  return (
    <Container disableGutters sx={styles.cardWidth}>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Card Information
        </Typography>
        {memberCardView.length > 0 ? (
          memberCardView.map((card, index) => (
            <Container key={index} sx={{ marginBottom: "20px" }}>
              <Typography variant="body1" sx={{ mb: 1 }}>
                Card {index + 1}
              </Typography>
              <>
                <TextField
                  margin="normal"
                  fullWidth
                  name="cardNumber"
                  label="Card Number"
                  variant="outlined"
                  value={card.number}
                  //onChange={handleChange}
                  onClick={() =>
                    navigate(`/ManageCard/retrieveCard?CardID=${card.number}`)
                  }
                  InputProps={styles.underlineInputProps}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  name="status"
                  label="Status"
                  variant="outlined"
                  value={card.status}
                  disabled={true}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  name="country"
                  label="Country"
                  variant="outlined"
                  value={card.country}
                  disabled={true}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  name="type"
                  label="Type"
                  variant="outlined"
                  value={card.type}
                  disabled={true}
                />
              </>
            </Container>
          ))
        ) : (
          <Typography>No Cards available.</Typography>
        )}
        {!addCardIsOpen && (
          <Button
            sx={{ m: 3 }}
            id="addButton"
            variant="contained"
            color="primary"
            onClick={() => setAddCardIsOpen(true)}
          >
            Issue a New Card
          </Button>
        )}
        {addCardIsOpen && (
          <>
            <Typography variant="body1" sx={{ m: 1 }}>
              New Card
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              name="validFrom"
              label="Valid From"
              type="date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={handleNewCardChange}
              required={true}
              sx={{ mb: 1 }}
            />
            <TextField
              fullWidth
              margin="normal"
              name="type"
              label="Card Type"
              variant="outlined"
              required={true}
              onChange={handleNewCardChange}
              sx={{ mb: 1 }}
              select
              SelectProps={{
                native: true,
              }}
            >
              {Object.keys(cardTypes).map((key) => (
                <option key={key} value={key}>
                  {cardTypes[key]}
                </option>
              ))}
            </TextField>
            <Button
              id="addButton"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Add Card
            </Button>
          </>
        )}
      </Paper>
    </Container>
  );
};

export default MemberCardComponent;

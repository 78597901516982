import React, { useState } from "react";
import {
  Paper,
  TextField,
  Typography,
  Grid,
  AccordionDetails,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import AddIcon from "@mui/icons-material/Add";
import { styles } from "../../styles/generalStyles";
import { navigate } from "gatsby";
import { Member } from "../../dataTypes/person/personViewTypes";

interface PersonDetailsProps {
  personDetailsMembers?: Member[];
  isProtectedPerson:  boolean;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": { borderBottom: 0 },
  "&::before": { display: "none" },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<AddIcon color="primary" sx={{ fontSize: "1.5rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(45deg)",
  },
  "& .MuiAccordionSummary-content": { marginLeft: theme.spacing(2) },
}));

const PersonMember = ({ personDetailsMembers, isProtectedPerson }: PersonDetailsProps) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const viewMemberHandle = (
    event: React.MouseEvent<HTMLElement>,
    member: Member,
  ) => {
    event.preventDefault();
    navigate(`/ManageMember/retrieveMember?memberID=${member?.memberId}`);
  };

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Member Information
      </Typography>
      {personDetailsMembers && personDetailsMembers.length > 0 ? (
        personDetailsMembers.map((member: Member) => (
          <Accordion
            expanded={expanded === member?.memberId}
            onChange={handleAccordionChange(member?.memberId)}
          >
            <AccordionSummary
              aria-controls={`member-${member?.memberId}-content`}
              id={`member-${member?.memberId}-header`}
            >
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} sx={{ mt: 1, mb: 0 }}>
                  <TextField
                    fullWidth
                    margin="none"
                    name="memberID"
                    label="Member ID"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={member?.memberId || ""}
                    autoFocus={false}
                    InputProps={styles.underlineInputProps}
                    disabled={isProtectedPerson}
                    onClick={(e) => viewMemberHandle(e, member)}
                  />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 0, pl: 7 }}>
              <Grid container spacing={0}>
                <Grid container spacing={3} sx={{ mt: 0 }}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="none"
                      fullWidth
                      disabled
                      name="legacyMemberId"
                      label="Legacy Member ID"
                      variant="outlined"
                      value={member?.legacyMemberId || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="none"
                      fullWidth
                      disabled
                      name="lrole"
                      label="Role"
                      variant="outlined"
                      value={member?.role || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      disabled
                      margin="none"
                      name="status"
                      label="Status"
                      variant="outlined"
                      value={member?.status || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="none"
                      name="statusDate"
                      label="Acknowledgement Date"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      id="birthDate"
                      disabled
                      value={
                        member?.pncAcknowledgementDate
                          ? member?.pncAcknowledgementDate.split("T")[0]
                          : ""
                      }
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Typography>Member Information is not available.</Typography>
      )}
    </Paper>
  );
};

export default PersonMember;

import React, { FC, useState } from "react";
import { TextField, Button, Typography, Paper, Grid } from "@mui/material";
import { OrgProfile, ProfileComponentType } from "../../dataTypes/organization/orgTypes";

const ProfileComponent: FC<ProfileComponentType> = ({ profileData, onUpdate }) => {
  const [profile, setProfile] = useState<OrgProfile>(profileData);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    await onUpdate(profile);
    setIsSubmitting(false);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Profile Information
      </Typography>
      <TextField
        margin="normal"
        fullWidth
        name="legalName"
        label="Legal Name"
        variant="outlined"
        value={profile?.legalName}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        fullWidth
        name="businessLicenseNumber"
        label="Business License Number"
        variant="outlined"
        value={profile?.businessLicenseNumber || ""}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        fullWidth
        name="country"
        label="Country"
        variant="outlined"
        value={profile?.country}
        onChange={handleChange}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            margin="normal"
            fullWidth
            name="status"
            label="Status"
            variant="outlined"
            value={profile?.status || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            margin="normal"
            fullWidth
            name="statusDate"
            label="Status Date"
            variant="outlined"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={profile?.statusDate ? profile.statusDate.split("T")[0] : ""}
            disabled={true}
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: 3 }}
        onClick={handleSubmit}
        disabled={isSubmitting}
      >
        {isSubmitting ? "Updating..." : "Update Profile"}
      </Button>
    </Paper>
  );
};

export default ProfileComponent;

import React, { useState } from "react";
import {
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
} from "@mui/material";
import buttons from "../../data/cardEligibility.json";
import authConfig from "../../config/app-config.json";
import { msalInstance } from "../../service/msalConfig";
import useAxiosInstance from "../../service/useAxiosInstance";
import { axiosGetV2 } from "../../service/axiosApi";
import { MemberEligibilityResponse } from "../../dataTypes/member/memberTypes";

const { memberEligibility } = authConfig;

const MemberEligibilityDialog = ({
  memberDetailsData,
  open,
  handleDialogClose,
}) => {
  const [checked, setChecked] = useState<number>(0);
  const [eligibility, setEligibility] =
    useState<MemberEligibilityResponse | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const axiosEligibilityInstance = useAxiosInstance(
    memberEligibility,
    msalInstance
  );

  const cardNumber = memberDetailsData?.cards[0]?.number;
  const countryCode = memberDetailsData?.cards[0]?.country;
  const headers = { headers: { "country-code": countryCode || "USA" } };

  const getEligibility = () => {
    setEligibility(null);
    setError(null);
    axiosGetV2(
      axiosEligibilityInstance,
      `${buttons[checked].endpoint}?cardNumber=${cardNumber}`,
      headers,
      setEligibility,
      () => {},
      setError
    );
  };

  const onClose = (e: React.MouseEvent) => {
    setEligibility(null);
    setError(null);
    setChecked(0);
    handleDialogClose(e);
  };

  const isEligible = eligibility?.shopResponse?.eligible;
  const color = isEligible ? "green" : "red";
  const result = isEligible ? "ELIGIBLE" : "NOT ELIGIBLE";
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>Verify Member Eligibility</DialogTitle>
      <DialogContent>
        <Box flexDirection="row" sx={{ display: "flex", flexWrap: "wrap" }}>
          {eligibility?.photo && (
            <Box
              component="img"
              sx={{ flex: 1 }}
              alt="Member photo"
              src={`data:image/jpeg;base64,${eligibility?.photo}`}
            />
          )}
          <Box
            sx={{
              flex: 5,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              pl: 1,
            }}
          >
            <Typography sx={{ color: color }}>
              {eligibility?.shopResponse && result}
            </Typography>
            <Typography sx={{ color: color }}>
              {eligibility?.shopResponse?.denialReasonDescription &&
                `Reason: ${eligibility?.shopResponse?.denialReasonDescription}`}
            </Typography>
            <Typography sx={{ color: color }}>
              {error && `Error: ${error.message}`}
            </Typography>
          </Box>
        </Box>
        <RadioGroup
          row
          aria-labelledby="control-label-placement"
          name="position"
          defaultValue="top"
          sx={{ ml: 2 }}
        >
          <Box>
            {buttons.map((button, index) => (
              <FormControlLabel
                value="top"
                control={<Radio />}
                label={button.label}
                disabled={index !== 0} //Remove when we have endpoints for online and gas
                labelPlacement="top"
                checked={checked === index}
                sx={{ mt: 2 }}
                onClick={() => setChecked(index)}
                key={index}
              />
            ))}
          </Box>
        </RadioGroup>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={getEligibility}
          >
            {"Verify"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MemberEligibilityDialog;

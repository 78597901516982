import { AxiosInstance, AxiosRequestConfig, AxiosResponse,  } from 'axios';
import { Dispatch, SetStateAction } from 'react';
interface UpdateSuccessFunction {  (data: any, refresh?: boolean, message?: string): void; }
interface CustomHeaders { 'Content-Type'?: 'application/json', "country-code": string; }
interface CustomHeadersV2 { "headers": CustomHeaders; }

export const axiosGetPromise = (
  axiosInstance: AxiosInstance,
  endPoint: string,
  queryParam: string | null,
  ID: string | number,
  headersObj: CustomHeaders
) => {
  const endpointWithParameter = queryParam
    ? `${endPoint}?${queryParam}=${ID}`
    : `${endPoint}/${ID}`;
  return axiosInstance
    .get(`/${endpointWithParameter}`, {
      headers: headersObj,
    })
    .then((response: AxiosResponse<any>) => response.data)
    .catch((error) => {
      console.error("Fetch error:", error);
      throw "An error occurred while fetching data.";
    });
};

export const axiosGetExtended = (
  axiosInstance: AxiosInstance,
  endPoint: string,
  urlParam: string | null,
  queryParamsObj: unknown,
  headersObj: CustomHeaders,
  setData: Dispatch<SetStateAction<any>>,
  setErrorDetails: Dispatch<SetStateAction<any>>,
  setErrorPopupOpen: Dispatch<SetStateAction<any>>
) => {
  const endpointWithParameter = urlParam ? `${endPoint}/${urlParam}` : endPoint;
  axiosInstance
    .get(`/${endpointWithParameter}`, {
      headers: headersObj,
      params: queryParamsObj,
    })
    .then((response: AxiosResponse<any>) => {
      setData(response.data);
    })
    .catch((error) => {
      console.error("Fetch error:", error);
      setErrorDetails &&
        setErrorDetails({
          message: "An error occurred while fetching data.",
        });
      setErrorPopupOpen && setErrorPopupOpen(true);
    });
};

//POST
export const axiosPost = (
  axiosInstance: AxiosInstance,
  endPoint: string,
  headersObj: CustomHeaders,
  body: unknown,
  setData?: Dispatch<SetStateAction<any>>,
  setErrorDetails?: Dispatch<SetStateAction<any>>,
  setErrorPopupOpen?: Dispatch<SetStateAction<any>>
) => {
  const endpointWithParameter = `${endPoint}`;
  axiosInstance
    .post(`/${endpointWithParameter}`, body, {
      headers: headersObj,
    })
    .then((response: AxiosResponse<any>) => {
      setData && setData(response.data);
    })
    .catch((error) => {
      console.error("Fetch error:", error);
      setErrorDetails &&
        setErrorDetails({
          message: "An error occurred while fetching data.",
        });
      setErrorPopupOpen && setErrorPopupOpen(true);
    });
};

export const axiosPutPromise = (
  axiosInstance: AxiosInstance,
  endPoint: string,
  body: unknown,
  headersObj: CustomHeaders,
  ) => {
  return axiosInstance
    .put(`/${endPoint}`, body, {
      headers: headersObj,
    })
    .then((response: AxiosResponse<any>) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Fetch error:", error);
      throw {
        message: "An error occurred while fetching data.",
        error,
      };
    });
};

export const axiosPostPromiseWithConfig = async (
  axiosInstance: AxiosInstance,
  endPoint: string,
  body: unknown,
  config: AxiosRequestConfig,
  ) => {
  try {
    const response = await axiosInstance
      .post(`/${endPoint}`, body, config);
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw {
      message: "An error occurred while fetching data.",
      error,
    };
  }
};

export const axiosPostPromise = (
  axiosInstance: AxiosInstance,
  endPoint: string,
  body: unknown,
  headersObj: CustomHeaders,
  ) => axiosPostPromiseWithConfig(axiosInstance, endPoint, body, {
    headers: headersObj,
  });


//GET V2
export const axiosGetV2 = (
  axiosInstance: AxiosInstance,
  endpoint: string, //Example: `retrieve-card-details/?cardNumber=${id}`
  params: CustomHeadersV2 | {}, //Example: { headers: headers, params: params }
  setData: Dispatch<SetStateAction<any>>,
  updateSuccess: UpdateSuccessFunction,
  updateError: any
) => {
  return axiosInstance
    .get(`/${endpoint}`, params)
    .then((response: AxiosResponse<any>) => {
      const { data } = response;
      setData(data);
      typeof updateSuccess === "function" && updateSuccess(data);
      return data;
    })
    .catch((error) => {
      console.error("Fetch error:", error);
      typeof updateError === "function" && updateError(error);
    });
};

//POST V2
export const axiosPostV2 = (
  axiosInstance: AxiosInstance,
  endpoint: string, //Example: `retrieve-card-details/?cardNumber=${id}`
  data: unknown,
  params: CustomHeadersV2, //Example: { headers: headers, params: params }
  setData: Dispatch<SetStateAction<any>>,
  updateSuccess: UpdateSuccessFunction,
  updateError: any
) => {
  return axiosInstance
    .post(`/${endpoint}`, data, params)
    .then((response: AxiosResponse<any>) => {
      const { data } = response;
      setData(data);
      updateSuccess(data);
    })
    .catch((error) => {
      console.error("Fetch error:", error);
      updateError(error);
    });
};

//PUT V2
export const axiosPutV2 = (
  axiosInstance: AxiosInstance,
  endpoint: string, //Example: `retrieve-card-details/?cardNumber=${id}`
  data: unknown,
  params: CustomHeadersV2, //Example: { headers: headers, params: params }
  setData: Dispatch<SetStateAction<any>>,
  updateSuccess: UpdateSuccessFunction,
  updateError: any
) => {
  return axiosInstance
    .put(`/${endpoint}`, data, params)
    .then((response: AxiosResponse<any>) => {
      const { data } = response;
      setData(data);
      updateSuccess(data);
    })
    .catch((error) => {
      console.error("Fetch error:", error);
      updateError(error);
    });
};

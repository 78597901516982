import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  FormControl,
  Grid,
  Autocomplete,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import PersonPhoto from "./PersonPhoto";
import { useImmerUpdaters } from "../../utils/useImmerUpdaters";
import { styles } from "../../styles/actionCardsStyles";
import stateList from "../../data/stateList.json";
import idDocumentsTypes from "../../data/idDocumentsTypes.json";
import languagePref from "../../data/languagePref.json";
import { getAge, deltaDate } from "../../utils/getAge";
import { Profile } from "../../dataTypes/person/personTypes";
import { PersonViewResponse } from "../../dataTypes/person/personRequestTypes";
import { Audited } from "../../dataTypes/shared/sharedRequestTypes";

const isNotEmptyString = (str?: string | null) =>
  str != null && str.trim().length > 0;

interface personInfoProps {
  personInfo: Audited<Profile>;
  personDetails: PersonViewResponse | null;
  isProtectedPerson: boolean;
  onUpdate: (profile: Audited<Profile>) => void;
}

const PersonProfile = ({
  personInfo,
  personDetails,
  isProtectedPerson,
  onUpdate,
}: personInfoProps) => {
  const [personInformation, setPersonInformation] = useState(personInfo);
  const [updatePersonInfo, handleUpdatePersonInfo] = useImmerUpdaters(
    personInformation,
    setPersonInformation,
  );
  const [invalidDocNumber, setInvalidDocNumber] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const idType = personInformation.idDocuments[0]?.type;
  const isUSA = personInfo.country === "USA";
  const isCAN = personInfo.country === "CAN";
  const isDeceasedPerson = personInfo.status === "Deceased";

  // Form Validity
  const age = getAge(personInformation.birthDate);
  const minDate = deltaDate(new Date(), 0, 0, -120).toISOString().split("T")[0];
  const maxDate = deltaDate(new Date(), 0, 0, -16).toISOString().split("T")[0];
  const isIdStateRequired = idType !== "PASSPORT" && idType !== "MILITARYID";
  const isIdSCountryRequired = idType !== "PASSPORT";

  const isFieldValid = {
    age: age >= 16 && age <= 120,
    firstName: isNotEmptyString(personInformation.firstName),
    lastName: isNotEmptyString(personInformation.lastName),
    idDocType: isUSA || (invalidDocNumber && isNotEmptyString(personInformation.idDocuments[0]?.type)),
    idDocNumber: isUSA || (invalidDocNumber && isNotEmptyString(personInformation.idDocuments[0]?.number)),
    idDocState: isUSA || (invalidDocNumber && isNotEmptyString(personInformation.idDocuments[0]?.stateProvince)),
    idDocCountry: isUSA || (invalidDocNumber && isNotEmptyString(personInformation.idDocuments[0]?.country)),
  };
  const isFormValid = Object.keys(isFieldValid).every((k) => isFieldValid[k]);

  // Check Valid Id Documents change
  const handleIDDocNumberChange = (event: { target: { value: string } }) => {
    updatePersonInfo((p) => {
      p.idDocuments[0].number = event.target.value;
      setInvalidDocNumber(false);
    });
  };

  // Check Id Documents Type
  const handleIDTypeChange = (event: { target: { value: string } }) => {
    const newIdType = event.target.value;
    if (newIdType === "PASSPORT") {
      updatePersonInfo((p) => {
        p.idDocuments[0].type = newIdType;
        p.idDocuments[0].stateProvince = "";
        p.idDocuments[0].country = "";
      });
    } else if (newIdType === "MILITARYID") {
      updatePersonInfo((p) => {
        p.idDocuments[0].type = newIdType;
        p.idDocuments[0].stateProvince = "";
      });
    } else {
      updatePersonInfo((p) => {
        p.idDocuments[0].type = newIdType;
      });
    }
  };

  // Handle Error
  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (!isFormValid) {
      setShowErrors(true);
      return;
    }
    onUpdate(personInformation);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4, mt: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Profile
      </Typography>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        {/* person photo */}
        <Grid item xs={12} sm={12}>
          <PersonPhoto personDetails={personDetails} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            required
            name="firstlName"
            label="First Name"
            variant="outlined"
            id="firstName"
            error={showErrors && !isFieldValid.firstName}
            value={personInformation?.firstName}
            disabled={isProtectedPerson}
            onChange={handleUpdatePersonInfo((p, v) => (p.firstName = v))}
            helperText={
              showErrors && !isFieldValid.firstName
                ? "First Name is required"
                : " "
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            name="middleInitial"
            label="Middle Initial"
            variant="outlined"
            id="middleInitial"
            onChange={handleUpdatePersonInfo((p, v) => (p.middleInitial = v))}
            value={personInformation?.middleInitial}
            disabled={isProtectedPerson}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            required
            name="lastName"
            label="Last Name"
            variant="outlined"
            id="lastName"
            error={showErrors && !isFieldValid.lastName}
            onChange={handleUpdatePersonInfo((p, v) => (p.lastName = v))}
            value={personInformation?.lastName}
            disabled={isProtectedPerson}
            helperText={
              showErrors && !isFieldValid.lastName
                ? "Last Name is required"
                : " "
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            name="suffix"
            label="Suffix"
            variant="outlined"
            id="suffix"
            onChange={handleUpdatePersonInfo((p, v) => (p.suffix = v))}
            value={personInformation?.suffix}
            disabled={isProtectedPerson}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            required
            id="birthDate"
            label="Birth Date"
            type="date"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            error={showErrors && !isFieldValid.age}
            InputProps={{ inputProps: { min: minDate, max: maxDate } }}
            value={personInformation?.birthDate}
            onChange={handleUpdatePersonInfo((p, v) => (p.birthDate = v))}
            disabled={isProtectedPerson}
            helperText="Must be 16 to 120 years old"
          />
        </Grid>
      </Grid>

      {personInformation?.idDocuments?.map(() => {
        return (
          <Box component="section" sx={styles.boxContainer}>
            <Typography variant="subtitle1" sx={styles.boxSubTitle}>
              <b>ID Documents</b>
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  required={isUSA}
                  disabled={isProtectedPerson || isCAN}
                >
                  <InputLabel id="idDocumentType">Type</InputLabel>
                  <Select
                    id="idDocumentType"
                    name="idDocumentType"
                    label="Type"
                    error={showErrors && !isFieldValid.idDocType}
                    value={personInformation.idDocuments[0]?.type}
                    onChange={handleIDTypeChange}
                  >
                    {Object.keys(idDocumentsTypes).map((key) => (
                      <MenuItem key={key} value={key}>
                        {idDocumentsTypes[key]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    required
                    name="idDocumentnumber"
                    id="idDocumentNumber"
                    label="Number"
                    variant="outlined"
                    disabled={isProtectedPerson}
                    error={showErrors && !isFieldValid.idDocNumber}
                    value={personInformation.idDocuments[0]?.number}
                    onChange={handleIDDocNumberChange}
                    helperText={
                      showErrors && !isFieldValid.idDocNumber
                        ? "Invalid ID Number"
                        : " "
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  fullWidth
                  id="idDocumentstateProvince"
                  disabled={(isCAN || !isIdStateRequired) && isProtectedPerson}
                  options={
                    personInformation.idDocuments[0]?.country === "USA"
                      ? stateList.USA
                      : stateList.CAN
                  }
                  value={personInformation.idDocuments[0]?.stateProvince}
                  onChange={(e, v) =>
                    updatePersonInfo(
                      (p) => (p.idDocuments[0].stateProvince = v),
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      error={showErrors && !isFieldValid.idDocState}
                      {...params}
                      name="idDocumentstateProvince"
                      value={personInformation.idDocuments[0]?.stateProvince}
                      label="State"
                      required={isUSA && isIdStateRequired}
                      helperText={
                        showErrors && !isFieldValid.idDocState
                          ? "Invalid State Province"
                          : " "
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  disabled={isCAN || !isIdSCountryRequired}
                  required={isUSA && isIdSCountryRequired}
                >
                  <InputLabel id="country-label">Country</InputLabel>
                  <Select
                    id="idDocumentCountry"
                    name="idDocumentCountry"
                    label="Country"
                    error={showErrors && !isFieldValid.idDocCountry}
                    defaultValue="USA"
                    onChange={handleUpdatePersonInfo(
                      (p, v) => (p.idDocuments[0].country = v),
                    )}
                  >
                    <MenuItem
                      key={personInformation.country}
                      value={personInformation.country}
                    >
                      {personInformation.country}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        );
      })}

      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            required
            variant="outlined"
            disabled={isProtectedPerson}
          >
            <InputLabel>Country</InputLabel>
            <Select
              id="profile-country"
              name="country"
              label="Country"
              value={personInformation?.country}
              onChange={handleUpdatePersonInfo((p, v) => (p.country = v))}
            >
              {[personInformation?.country].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            variant="outlined"
            disabled={isProtectedPerson}
          >
            <InputLabel>Language Preference</InputLabel>
            <Select
              id="languagePref"
              name="language"
              label="Language Preference"
              value={personInformation?.languagePref as string}
              onChange={handleUpdatePersonInfo((p, v) => (p.languagePref = v))}
            >
              {languagePref[personInformation?.country].map((option: any) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 1, mb: 3 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            disabled
            id="status"
            name="status"
            label="Status"
            variant="outlined"
            value={personInformation?.status}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mb: 1 }}>
          <TextField
            fullWidth
            disabled
            id="statusDate"
            name="statusDate"
            label="Status Date"
            type="date"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={
              personInformation?.statusDate
                ? personInformation?.statusDate.split("T")[0]
                : ""
            }
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isProtectedPerson || isDeceasedPerson}
      >
        Update Profile
      </Button>
    </Paper>
  );
};

export default PersonProfile;

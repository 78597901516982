import React, { useState, useEffect, FC, ChangeEvent } from "react";
import {
  Container,
  TextField,
  Button,
  Grid,
  Typography,
  Select,
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import memberRoles from "../../data/memberRoles.json";
import countryList from "../../data/countryList.json";
import { styles } from "../../styles/generalStyles";
import MemberEligibilityDialog from "./MemberEligibilityDialog";
import { MemberComponentProps } from "../../dataTypes/member/memberComponentTypes";
import { MemberInfoResponse, JSON } from "../../dataTypes/member/memberTypes";

const MemberComponent: FC<MemberComponentProps> = ({
  memberInfoData,
  memberDetailsData,
  onUpdate,
  onAction,
}) => {
  const [member, setMember] = useState<MemberInfoResponse>(memberInfoData);
  const [enrollmentObj, setEnrollment] = useState(memberInfoData.enrollment);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const memberView = memberDetailsData.member;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMember({ ...member, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setMember({ ...member, ["enrollment"]: enrollmentObj });
  }, [enrollmentObj]);

  // TODO: fix all onChange types
  const handleEnrollment = (e: SelectChangeEvent) => {
    setEnrollment((enrollmentObj) => ({
      ...enrollmentObj,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e: MouseEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    onUpdate(member);
    setIsSubmitting(false);
  };

  const handleVerifyEligibilityClick = (e: MouseEvent) => {
    e.preventDefault();
    setOpenDialog(true);
  };

  const handleDialogClose = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setOpenDialog(false);
  };

  function MemberRoles(role: JSON) {
    const handleSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
      role.onChange(event);
    };

    return (
      <Grid item xs={12} sm={6}>
        <FormControl margin="normal" fullWidth>
          <InputLabel>{role.label}</InputLabel>
          <Select
            defaultValue={role.value}
            id={role.id}
            name={role.name}
            label={role.label}
            onChange={handleSelectChange}
          >
            {Object.keys(memberRoles).map(function (key) {
              return <MenuItem value={memberRoles[key]}>{key}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Grid>
    );
  }

  function CountryList(country: JSON) {
    const handleSelectChange = (e: SelectChangeEvent) => {
      country.onChange(e);
    };
    return (
      <Grid item xs={12} sm={6}>
        <FormControl margin="normal" fullWidth>
          <InputLabel>{country.label}</InputLabel>
          <Select
            defaultValue={country.value}
            id={country.id}
            name={country.name}
            label={country.label}
            onChange={handleSelectChange}
          >
            {Object.keys(countryList).map(function (key) {
              return <MenuItem value={countryList[key]}>{key}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Grid>
    );
  }

  function mapActionTextToUrl(text: string) {
    switch (text) {
      case "AcceptPnC":
        return "accept-privileges-and-conditions";
      case "Establish":
        return "establish-member";
      case "Reinstate":
        return "reinstate-member";
      case "Terminate":
        return "terminate-member";
      default:
        throw new Error(`Unexpected action text: ${text}`);
    }
  }

  const defaultActionTexts = [
    "AcceptPnC",
    "Establish",
    "Reinstate",
    "Terminate",
  ];

  const actionTexts =
    memberView?.actions && memberView?.actions?.length > 0
      ? memberView.actions.map((action) => action.text)
      : defaultActionTexts;

  const actions =
    //handle AceptPnC and Establish when status is Pending based on pncAcknowledgementDate
    memberView.status == "Pending"
      ? memberView.pncAcknowledgementDate === undefined
        ? actionTexts.slice(1).map((text) => ({
            text: "AcceptPnC",
            path: mapActionTextToUrl("AcceptPnC"),
          }))
        : actionTexts.slice(1).map((text) => ({
            text: "Establish",
            path: mapActionTextToUrl("Establish"),
          }))
      : actionTexts.map((text) => ({
          text,
          path: mapActionTextToUrl(text),
        }));

  const handleActionClick = async (
    actionURL: string,
    onSuccessMessage: string
  ) => {
    setIsSubmitting(true);
    onAction(actionURL, member, onSuccessMessage);
    setIsSubmitting(false);
  };

  return (
    <Container disableGutters sx={styles.cardWidth}>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Member Information
        </Typography>

        <TextField
          margin="normal"
          fullWidth
          name="legacyMemberId"
          label="Legacy Member Id"
          variant="outlined"
          value={member.legacyMemberId}
          onChange={handleChange}
          disabled={true}
        />

        <TextField
          margin="normal"
          fullWidth
          name="accountId"
          label="Account Id"
          variant="outlined"
          value={member.accountId}
          onChange={handleChange}
          disabled={true}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="status"
              label="Member Status"
              variant="outlined"
              value={member.status}
              onChange={handleChange}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="statusDate"
              label="Status Date"
              variant="outlined"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={
                member.statusDate
                  ? (member.statusDate as unknown as string).slice(0, 10)
                  : ""
              }
              onChange={handleChange}
              disabled={true}
            />
          </Grid>
        </Grid>
        <MemberRoles
          fullWidth
          name="role"
          label="Member Role"
          variant="outlined"
          value={member.role}
          key={member.role}
          onChange={handleChange}
        />
        <CountryList
          fullWidth
          name="country"
          label="Enrolllment Country"
          variant="outlined"
          value={enrollmentObj.country}
          onChange={handleEnrollment}
        />

        <TextField
          margin="normal"
          fullWidth
          name="locationNumber"
          label="Enrolllment Location Number"
          variant="outlined"
          value={enrollmentObj.locationNumber}
          onChange={handleEnrollment}
        />
        <TextField
          margin="normal"
          fullWidth
          name="date"
          label="Enrolllment Date"
          variant="outlined"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={enrollmentObj.date ? enrollmentObj.date.split("T")[0] : ""}
          onChange={handleEnrollment}
        />

        <TextField
          margin="normal"
          fullWidth
          name="pncAcknowledgementDate"
          label="PnC Acknowledgement Date"
          variant="outlined"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={enrollmentObj.pncAcknowledgementDate}
          onChange={handleEnrollment}
          disabled={true}
        />

        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="error"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Overwrite Member Info
            </Button>
          </Grid>
          {actions.map((action) => (
            <Grid item>
              <Button
                variant="contained"
                key={action.text}
                onClick={async (e) => {
                  handleActionClick(
                    action.path,
                    `Action '${action.text}' successful!`
                  );
                }}
                disabled={isSubmitting}
              >
                {action.text}
              </Button>
            </Grid>
          ))}
          <Grid item>
            <Button
              variant="contained"
              key="verify-eligibility"
              onClick={handleVerifyEligibilityClick}
              disabled={isSubmitting || memberDetailsData.cards.length === 0}
            >
              {"Verify Member Eligibility"}
            </Button>
          </Grid>
        </Grid>
        <MemberEligibilityDialog
          memberDetailsData={memberDetailsData}
          open={openDialog}
          handleDialogClose={handleDialogClose}
        />
      </Paper>
    </Container>
  );
};

export default MemberComponent;

import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, useState } from "react";
import cardActions from "../../data/cardActions.json";
import cardTypes from "../../data/cardTypes.json";
import countryList from "../../data/countryList.json";
import { styles } from "../../styles/generalStyles";
import { CardComponentProps } from "../../dataTypes/card/cardComponentsTypes";
import { Action } from "../../dataTypes/shared/sharedRequestTypes";
import { Card } from "../../dataTypes/card/cardTypes";

const CardComponent: FC<CardComponentProps> = ({
  cardData,
  onUpdate,
  onAction,
  onReplace,
}) => {
  const [card, setCard] = useState<Card>(cardData);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCard({ ...card, [name]: value });
  };

  const handleUserAction = (e: React.MouseEvent, action: Action) => {
    e.preventDefault();
    setIsSubmitting(true);
    onAction(card, action);
    setIsSubmitting(false);
  };

  const handleOverWrite = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    onUpdate(card);
    setIsSubmitting(false);
  };

  const handleReplaceCard = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    onReplace();
    setIsSubmitting(false);
  };

  const hasActions: boolean = cardData?.actions?.length > 0;
  const actions: Action[] = hasActions
    ? cardData?.actions
    : (cardActions as Action[]);
  return (
    <Container sx={styles.cardWidth} disableGutters>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Card Information
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          name="type"
          label="Type"
          variant="outlined"
          onChange={handleChange}
          select
          defaultValue={card?.type || ""}
          SelectProps={{
            native: true,
          }}
        >
          {Object.keys(cardTypes).map((key) => (
            <option key={key} value={key}>
              {cardTypes[key]}
            </option>
          ))}
        </TextField>
        <TextField
          margin="normal"
          fullWidth
          name="validFrom"
          label="Valid From"
          variant="outlined"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={card?.validFrom || ""}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          fullWidth
          name="country"
          label="Country"
          variant="outlined"
          onChange={handleChange}
          select
          defaultValue={card?.country || ""}
          SelectProps={{
            native: true,
          }}
        >
          {Object.keys(countryList).map((key) => (
            <option key={key} value={key}>
              {countryList[key]}
            </option>
          ))}
        </TextField>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              disabled
              name="status"
              label="Status"
              variant="outlined"
              value={card?.status || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              disabled
              name="statusDate"
              label="Status Date"
              variant="outlined"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={card?.statusDate ? card?.statusDate?.split("T")[0] : ""}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item={true} key="overwrite">
            <Button
              type="submit"
              variant="contained"
              color="error"
              sx={{ mt: 3, mr: 3 }}
              onClick={handleOverWrite}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Updating..." : "Overwrite Card"}
            </Button>
          </Grid>
          {actions.map((action: Action) => (
            <Grid item={true} key={action.text}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 3, mr: 3 }}
                onClick={(e) => handleUserAction(e, action)}
                disabled={isSubmitting || !hasActions}
                key={action.code}
              >
                {isSubmitting ? "Updating..." : action.text}
              </Button>
            </Grid>
          ))}
          <Grid item={true} key="replace">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 3, mr: 3 }}
              onClick={handleReplaceCard}
              disabled={isSubmitting}
              key="replace-card"
            >
              {isSubmitting ? "Updating..." : "Replace Card"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default CardComponent;

import React, { FC, useState } from "react";
import { Button, Grid, Typography, Paper, Container } from "@mui/material";
import { navigate } from "gatsby";
import { OrganizationActionsType } from "../../dataTypes/organization/orgTypes";


const OrganizationActions: FC<OrganizationActionsType> = ({ actionData, onAction, orgID }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  function mapActionTextToUrl(text) {
    switch (text) {
      case "Suspend":
        return "suspend-organization";
      case "Resume":
        return "resume-organization";
      case "Terminate":
        return "terminate-organization";
      case "Dissolve":
        return "dissolve-organization";
      default:
        throw new Error(`Unexpected action text: ${text}`);
    }
  }

  const defaultActionTexts = ["Suspend", "Resume", "Terminate", "Dissolve"];

  const actionTexts =
    actionData?.actions?.length > 0
      ? actionData.actions.map((action) => action.text)
      : defaultActionTexts;

  const actions = actionTexts.map((text) => ({
    text,
    path: mapActionTextToUrl(text),
  }));

  const handleActionClick = async (actionURL, onSuccessMessage) => {
    setIsSubmitting(true);
    await onAction(actionURL, onSuccessMessage);
    setIsSubmitting(false);
  };

  return (
    <Container disableGutters>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Organization Actions
        </Typography>
        <Grid container spacing={2}>
          {actions.map((action) => (
            <Grid item>
              <Button
                variant="contained"
                key={action.code}
                onClick={async (e) => {
                  handleActionClick(
                    action.path,
                    `Action '${action.text}' successful!`
                  );
                }}
                disabled={isSubmitting}
              >
                {action.text}
              </Button>
            </Grid>
          ))}
          <Grid item>
            <Button
              variant="contained"
              onClick={() =>
                navigate(
                  `/ManageOrganization/OpenBusinessAccount?orgID=${orgID}`
                )
              }
            >
              Open Business Acc
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() =>
                navigate(
                  `/ManageOrganization/OpenBusinessAccount?orgID=${orgID}&compliment=true`
                )
              }
            >
              Open Complimentary Business Account
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default OrganizationActions;

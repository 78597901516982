import React, { FC, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { axiosPostV2 } from "../../service/axiosApi";
import authConfig from "../../config/app-config.json";
import { msalInstance } from "../../service/msalConfig";
import useAxiosInstance from "../../service/useAxiosInstance";
import { generateUUID } from "../../utils/generateUUID";
import { useCurrentAccountInfo } from "../../utils/useCurrentAccountInfo";
import { styles } from "../../styles/generalStyles";
import { navigate } from "gatsby";
import { memberRoles } from "../../data/memberRolesAndEndpoints";
import { InitiateMemberDialogProps } from "../../dataTypes/member/memberComponentTypes";
import { AxiosInstance } from "axios";
import { PersonViewResponse } from "../../dataTypes/person/personRequestTypes";
import {
  InitiateMemberRequestBody,
  InitiateMemberResponse,
  MemberRole,
} from "../../dataTypes/member/memberTypes";
import { Country } from "../../dataTypes/person/sharedPersonTypes";

const { baseManageMemberUrl } = authConfig;

const InitiateMemberDialog: FC<InitiateMemberDialogProps> = ({
  open,
  handleClose,
  personDetails,
}) => {
  const [checked, setChecked] = useState<number>(0);
  const [data, setData] = useState<InitiateMemberResponse | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const { username, warehouseLocation } = useCurrentAccountInfo();
  const countryCode: Country = personDetails?.person?.profile?.country || "USA";
  const accountId = personDetails?.accounts[0]?.accountId || "";
  const headers = {
    headers: {
      "country-code": countryCode,
      "Content-Type": "application/json",
    },
  };

  const axiosInstance: AxiosInstance = useAxiosInstance(
    baseManageMemberUrl,
    msalInstance
  );

  const handleRoleChange = (index: number) => {
    setChecked(index);
  };

  const hasPrimary = (data: PersonViewResponse) => {
    for (let member of data.members) {
      if (member.role === "Primary") return true;
    }
    return false;
  };

  const onClose = (e: React.MouseEvent<HTMLElement>) => {
    setChecked(0);
    setData(null);
    handleClose(e);
    setError(null);
  };

  const initiateRole = () => {
    const currentDate = new Date().toISOString();
    const body: InitiateMemberRequestBody = {
      accountId: accountId,
      docType: "memberModel",
      role:
        (memberRoles[checked].role.split(" ")[1] as MemberRole) ||
        memberRoles[checked].role, //Splitting for "(Business) Manager" and "(Business) Owner", only need "Manager" or "Owner"
      personId: personDetails?.person?.id || "",
      enrollment: {
        country: countryCode,
        locationNumber: warehouseLocation,
        date: currentDate,
        pnCAcknowledgementDate: currentDate,
      },
      status: "Pending",
      statusDate: currentDate,
      audit: {
        createdByChannel: "Desktop",
        createdByUser: username,
        createdAtLocationNumber: warehouseLocation,
        createdOnUtcDateTime: currentDate,
        mgloTransactionType: "MGLOUpdate",
        mgloTransactionId: generateUUID(),
      },
    };

    setData(null);
    setError(null);
    axiosPostV2(
      axiosInstance,
      memberRoles[checked].endpoint,
      body,
      headers,
      setData,
      () => {},
      setError
    );
  };

  const disablePrimary: boolean = hasPrimary(personDetails);

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>Initiate Member</DialogTitle>
      <DialogContent>
        {accountId ? (
          <>
            <TextField
              margin="normal"
              fullWidth
              name="accountId"
              label="Account ID"
              variant="outlined"
              value={accountId}
              InputProps={styles.underlineInputProps}
              onClick={() =>
                navigate(
                  `/ManageAccount/retrieveAccount?accountID=${accountId}`
                )
              }
            />
            {disablePrimary && checked === 0 && (
              <Typography color="red">
                This account already has a primary member
              </Typography>
            )}
            <RadioGroup
              style={{ justifyContent: "center" }}
              row
              aria-labelledby="control-label-placement"
              name="position"
              defaultValue="top"
            >
              {memberRoles.map((item, index) => (
                <FormControlLabel
                  value="top"
                  control={<Radio />}
                  label={item.role}
                  labelPlacement="top"
                  checked={index === checked}
                  onClick={() => handleRoleChange(index)}
                  key={item.role}
                />
              ))}
            </RadioGroup>
            {data?.id && (
              <TextField
                margin="normal"
                fullWidth
                name="accountId"
                label="New member ID"
                variant="outlined"
                value={data.id}
                InputProps={styles.underlineInputProps}
                onClick={() =>
                  navigate(`/ManageMember/retrieveMember?memberID=${data.id}`)
                }
              />
            )}
            {error && <Typography color="red">{error.toString()}</Typography>}
            <Grid display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 1 }}
                disabled={checked === 0 && disablePrimary}
                onClick={initiateRole}
              >
                {"Initiate"}
              </Button>
            </Grid>
          </>
        ) : (
          <Typography>
            This person doesn't have any accounts created. Initiating member is
            not possible without an account.
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default InitiateMemberDialog;

import {
  Button,
  Paper,
  Typography,
  Box,
  Switch,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  FormControlLabel,
} from "@mui/material";
import React, { useState } from "react";
import {
  HandleImmerUpdateType,
  useImmerUpdaters,
} from "../../utils/useImmerUpdaters";
import { styles } from "../../styles/actionCardsStyles";
import {
  PrivacyConsent,
  PrivacyLevel,
  Residence,
} from "../../dataTypes/person/personTypes";
import { Audited } from "../../dataTypes/sharedRequestTypes";

const privacyLevel = ["Default", "Strong", "Stronger", "Stronger", "Strongest"];

interface PersonPrivacyConsentProps {
  personPrivacyConsent: Audited<PrivacyConsent>;
  personPrivacyConsentResidence: Residence;
  onUpdate: (privacyConsent: Audited<PrivacyConsent>) => void;
}

interface PersonPrivacyConsentOptionsProps {
  privacyConsentOptions: PrivacyConsent;
  PrivacyConsentResidence: Residence;
  handleUpdatePrivacyConsent: HandleImmerUpdateType<PrivacyConsent>;
  isProtectedPerson: boolean;
}

interface PrivacyConsentItemProps {
  name?: string;
  id: string;
  checked?: boolean;
  label: string;
  labelId: string;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PrivacyConsentItem = (props: PrivacyConsentItemProps) => {
  const handleSwitchChange = (event: React.SyntheticEvent) => {
    props.onChange(event as React.ChangeEvent<HTMLInputElement>);
  };
  return (
    <Grid item xs={12} sm={6}>
      <FormControlLabel
        name={props.name}
        control={<Switch id={props.id} checked={props.checked} />}
        label={props.label}
        onChange={handleSwitchChange}
        disabled={props.disabled}
      />
    </Grid>
  );
};

const PrivacyConsentOptions = ({
  privacyConsentOptions,
  PrivacyConsentResidence,
  handleUpdatePrivacyConsent,
  isProtectedPerson,
}: PersonPrivacyConsentOptionsProps) => {
  const isCali = PrivacyConsentResidence?.address.stateProvince === "CA";
  const isUSA = PrivacyConsentResidence?.address.country === "USA";

  return (
    <>
      <Box component="section" sx={styles.boxContainer}>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          <b>Marketing Consent</b>
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <PrivacyConsentItem
            label="Do Not Mail"
            labelId="doNotMail"
            id="doNotMail"
            disabled={isProtectedPerson}
            checked={privacyConsentOptions?.objectToMarketing.doNotMail as boolean}
            onChange={handleUpdatePrivacyConsent(
              (p, _, e) => (p.objectToMarketing.doNotMail = e.target.checked),
            )}
          />
          <PrivacyConsentItem
            label="Do Not Email"
            labelId="doNotEmail"
            id="doNotEmail"
            disabled={isProtectedPerson}
            checked={privacyConsentOptions?.objectToMarketing.doNotEmail as boolean}
            onChange={handleUpdatePrivacyConsent(
              (p, _, e) => (p.objectToMarketing.doNotEmail = e.target.checked),
            )}
          />
          <PrivacyConsentItem
            label="Do Not Call"
            labelId="doNotCall"
            id="doNotCall"
            disabled={isProtectedPerson}
            checked={privacyConsentOptions?.objectToMarketing.doNotCall as boolean}
            onChange={handleUpdatePrivacyConsent(
              (p, _, e) => (p.objectToMarketing.doNotCall = e.target.checked),
            )}
          />
          <PrivacyConsentItem
            label="Do Not Text"
            labelId="doNotText"
            id="doNotText"
            disabled={isProtectedPerson}
            checked={privacyConsentOptions?.objectToMarketing.doNotText as boolean}
            onChange={handleUpdatePrivacyConsent(
              (p, _, e) => (p.objectToMarketing.doNotText = e.target.checked),
            )}
          />
          <PrivacyConsentItem
            label="Do Not Upsell"
            labelId="doNotUpsell"
            id="doNotUpsell"
            disabled={isProtectedPerson}
            checked={privacyConsentOptions?.objectToMarketing.doNotUpsell as boolean}
            onChange={handleUpdatePrivacyConsent(
              (p, _, e) => (p.objectToMarketing.doNotUpsell = e.target.checked),
            )}
          />
        </Grid>
      </Box>

      <Box component="section" sx={styles.boxContainer}>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          <b>Sharing Consent</b>
        </Typography>
        {/* "DNSMI RESERVED FOR CALIFORNIA, USA ONLY" */}
        {isUSA && isCali && (
          <PrivacyConsentItem
            label="Do Not Sell My Personal Infomation"
            labelId="doNotSellMyPI"
            id="doNotSellMyPI"
            checked={privacyConsentOptions?.restrictPIUsage.doNotSellMyPI as boolean}
            onChange={handleUpdatePrivacyConsent(
              (p, _, e) => (p.restrictPIUsage.doNotSellMyPI = e.target.checked),
            )}
          />
        )}

        {isUSA && !isCali && (
          <PrivacyConsentItem
            label="Do Not Share My Personal Infomation"
            labelId="doNotShareMyPI"
            id="doNotShareMyPI"
            disabled={isProtectedPerson}
            checked={privacyConsentOptions?.restrictPIUsage.doNotShareMyPI as boolean}
            onChange={handleUpdatePrivacyConsent(
              (p, _, e) =>
                (p.restrictPIUsage.doNotShareMyPI = e.target.checked),
            )}
          />
        )}

        <PrivacyConsentItem
          label="Do Not Transfer My Personal Infomation"
          labelId="doNotTransferMyPI"
          id="doNotTransferMyPI"
          disabled={isProtectedPerson}
          checked={privacyConsentOptions?.restrictPIUsage.doNotTransferMyPI as boolean}
          onChange={handleUpdatePrivacyConsent(
            (p, _, e) =>
              (p.restrictPIUsage.doNotTransferMyPI = e.target.checked),
          )}
        />

        {/* Pervacy consent level */}
        <Grid item xs={12} sx={{ mt: 3, mb: 2 }}>
          <Box>
            <FormControl fullWidth>
              <InputLabel>Privacy Level</InputLabel>
              <Select
                labelId="privacyLevel"
                id="privacyLevel"
                label="Privacy Level"
                value={privacyConsentOptions?.restrictPIUsage.privacyLevel}
                disabled={isProtectedPerson}
                defaultValue={privacyLevel[0]}
                onChange={handleUpdatePrivacyConsent(
                  (p, v, _) =>
                    (p.restrictPIUsage.privacyLevel = v as PrivacyLevel),
                )}
              >
                {privacyLevel.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Box>

      <Box component="section" sx={styles.boxContainer}>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          <b>Profiling Consent</b>
        </Typography>
        <PrivacyConsentItem
          label="Do Not Profile"
          labelId="doNotProfile"
          id="doNotProfile"
          disabled={isProtectedPerson}
          checked={privacyConsentOptions?.rejectPIProcessing.doNotProfile as boolean}
          onChange={handleUpdatePrivacyConsent(
            (p, _, e) => (p.rejectPIProcessing.doNotProfile = e.target.checked),
          )}
        />
        <PrivacyConsentItem
          label="Do Not Change Address"
          labelId="doNotChangeAddress"
          id="doNotChangeAddress"
          disabled={isProtectedPerson}
          checked={privacyConsentOptions?.rejectPIProcessing.doNotChangeAddress as boolean}
          onChange={handleUpdatePrivacyConsent(
            (p, _, e) =>
              (p.rejectPIProcessing.doNotChangeAddress = e.target.checked),
          )}
        />
        <PrivacyConsentItem
          label="Do Not Track Geolocation"
          labelId="doNotTrackGeoLocation"
          id="doNotTrackGeoLocation"
          disabled={isProtectedPerson}
          checked={
            privacyConsentOptions?.rejectPIProcessing.doNotTrackGeoLocation as boolean
          }
          onChange={handleUpdatePrivacyConsent(
            (p, _, e) =>
              (p.rejectPIProcessing.doNotTrackGeoLocation = e.target.checked),
          )}
        />
      </Box>
    </>
  );
};

const PersonPrivacyConsent = ({
  personPrivacyConsent,
  personPrivacyConsentResidence,
  onUpdate,
}: PersonPrivacyConsentProps) => {
  const [privacyConsent, setPrivacyConsent] = useState(personPrivacyConsent);
  const [_, handleUpdatePrivacyConsent] = useImmerUpdaters(
    privacyConsent,
    setPrivacyConsent,
  );
  const isProtectedPerson =
    personPrivacyConsent.restrictPIUsage.privacyLevel !== "Default";

  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onUpdate(privacyConsent);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Privacy Consent
      </Typography>
      <PrivacyConsentOptions
        privacyConsentOptions={privacyConsent}
        PrivacyConsentResidence={personPrivacyConsentResidence}
        isProtectedPerson={isProtectedPerson}
        handleUpdatePrivacyConsent={handleUpdatePrivacyConsent}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: 3 }}
        onClick={handleSubmit}
        disabled={isProtectedPerson}
      >
        Update Privacy Consent
      </Button>
    </Paper>
  );
};

export default PersonPrivacyConsent;

// AccountComponent.js
import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Paper,
  Switch,
  FormControlLabel,
} from "@mui/material";
import countryList from "../../data/countryList.json";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { navigate } from "gatsby";
import { styles } from "../../styles/generalStyles";

const AllActions = [
  {
    code: "Modify",
    text: "Modify",
    api: {
      httpMethod: "POST",
      url: "/mglo-manage-account/v1/modify-account",
      requiredParams: "accountId,mgloTransactionId",
      optionalParams:
        "mgloTransactionType,expirationDate,awarenessCode,awarenessDescription",
    },
  },
  {
    code: "Activate",
    text: "Activate",
    api: {
      httpMethod: "POST",
      url: "/mglo-manage-account/v1/activate-account",
      requiredParams: "accountId,mgloTransactionId",
      optionalParams: "mgloTransactionType",
    },
  },
  {
    code: "Renew",
    text: "Renew",
    api: {
      httpMethod: "POST",
      url: "/mglo-manage-account/v1/renew-account",
      requiredParams: "accountId,mgloTransactionId",
      optionalParams: "mgloTransactionType",
    },
  },
  {
    code: "Cancel",
    text: "Cancel",
    api: {
      httpMethod: "POST",
      url: "/mglo-manage-account/v1/cancel-account",
      requiredParams: "accountId,mgloTransactionId",
      optionalParams: "mgloTransactionType",
    },
  },
  {
    code: "Expire",
    text: "Expire",
    api: {
      httpMethod: "POST",
      url: "/mglo-manage-account/v1/expire-account",
      requiredParams: "accountId,mgloTransactionId",
      optionalParams: "mgloTransactionType",
    },
  },
  {
    code: "Close",
    text: "Close",
    api: {
      httpMethod: "POST",
      url: "/mglo-manage-account/v1/close-account",
      requiredParams: "accountId,mgloTransactionId",
      optionalParams: "mgloTransactionType",
    },
  },
];
function CountryList(country) {
  const handleSelectChange = (e) => {
    country.onChange(e);
  };
  return (
    <Grid item xs={12} sm={6}>
      <FormControl margin="normal" fullWidth>
        <InputLabel>{country.label}</InputLabel>
        <Select
          defaultValue={country.value}
          id={country.id}
          //value={role.selectValue}
          name={country.name}
          label={country.label}
          onChange={handleSelectChange}
        >
          {" "}
          {Object.keys(countryList).map(function (key) {
            return <MenuItem value={countryList[key]}>{key}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </Grid>
  );
}
const AccountComponent = ({ accountData, accountId, onUpdate }) => {
  const [account, setAccount] = useState({ ...accountData, accountId });
  const [updatePayload, setUpdatePayload] = useState({
    accountId: accountId,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setUpdatePayload({
      ...updatePayload,
      [name]: name === "autoRenew" ? checked : value,
    });
    setAccount({ ...account, [name]: name === "autoRenew" ? checked : value });
  };

  const handleSubmit = async (e) => {
    const action = JSON.parse(e.target.dataset.action);
    e.preventDefault();
    let payload = { accountId: account.accountId };
    if (action.code === "Modify") {
      payload = { ...updatePayload, mgloTransactionType: "MGLOUpdate" };
    } else if (action.code === "Upgrade") {
      payload.tier = "Executive";
    } else if (action.code === "Downgrade") {
      payload.tier = "Regular";
    } else if (action.code === "Renew") {
      payload.tier = account.tier;
    }
    // return;
    setIsSubmitting(true);
    await onUpdate({
      payload: {
        path: action.api.url,
        mgloTransactionType: `MGLO${action.code}`,
        ...payload,
      },
      code: action.code,
    });
    setIsSubmitting(false);
  };
  useEffect(() => {
    if (!account.actions || account.actions?.length === 0) {
      setAccount({ ...account, actions: AllActions });
    }
  }, [account]);

  return (
    <Container disableGutters sx={styles.cardWidth}>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Account Information
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          name="type"
          label="Type"
          variant="outlined"
          value={account.type}
          disabled={true}
        />
        <TextField
          margin="normal"
          fullWidth
          name="legacyAccountId"
          label="Legacy Account Id"
          variant="outlined"
          value={account.legacyAccountId}
          disabled={true}
        />

        {account.country && (
          <CountryList
            margin="normal"
            fullWidth
            name="country"
            label="Account Country"
            variant="outlined"
            value={account.country}
            onChange={handleChange}
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="status"
              label="Status"
              variant="outlined"
              value={account.status || ""}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="statusDate"
              label="Status Date"
              variant="outlined"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={account.statusDate ? account.statusDate.split("T")[0] : ""}
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="awarenessCode"
              label="Awareness Code"
              variant="outlined"
              value={account.awarenessCode}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="awarenessDescription"
              label="Awareness Description"
              variant="outlined"
              value={account.awarenessDescription}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="locationNumber"
              label="Location Number"
              variant="outlined"
              value={account.locationNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="homeLocationNumber"
              label="Home Location Number"
              variant="outlined"
              value={account.homeLocationNumber}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="beginDate"
              label="Begin Date"
              variant="outlined"
              value={account.beginDate}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="expirationDate"
              label="Expiration Date"
              variant="outlined"
              value={account.expirationDate}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="tier"
              label="Tier"
              variant="outlined"
              value={account.tier}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="tierStartDate"
              label="Tier Start Date"
              variant="outlined"
              value={account.tierStartDate}
            />
          </Grid>
        </Grid>
        <TextField
          margin="normal"
          fullWidth
          name="organizationId"
          label="organization Id"
          variant="outlined"
          value={account.organizationId}
          className="redirect-link"
          onClick={() =>
            navigate(
              `../../ManageOrganization/retrieveOrganization?orgID=${account.organizationId}`
            )
          }
          InputProps={{
            readOnly: true,
          }}
        />
        <FormControlLabel
          name="autoRenew"
          labelPlacement="start"
          control={
            <Switch
              checked={account.autoRenew}
              onChange={handleChange}
              inputProps={{ "aria-label": "Auto Renew" }}
            />
          }
          label="Auto Renew"
        />
      </Paper>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Account Action
        </Typography>
        <Grid container spacing={2}>
          {account.actions?.map((val) => (
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                data-action={JSON.stringify(val)}
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Updating..." : val.text}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Container>
  );
};

export default AccountComponent;

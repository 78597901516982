import React, { useEffect, useState } from "react";
import { ActionCards } from "../components/HomePageComponents/ActionCards";
import MembershipItemsMenu from "../components/HomePageComponents/MembershipItemsMenu";
import RetrieveOrganization from "./ManageOrganization/retrieveOrganization";
import RetrieveMember from "./ManageMember/retrieveMember";
import RetrieveAccount from "./ManageAccount/retrieveAccount";
import OpenAccountComponent from "../components/AccountComponents/OpenAccountComponent";
import RetrieveOrder from "./ManageSaleOrder/retrieveOrder";
import RetrieveCard from "./ManageCard/retrieveCard";
import Home from "./home";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import LoginSuccess from "../components/LoginSuccess";
import LoginFailed from "../components/LoginFailed";
import "./index.css";
import RetrievePerson from "./ManagePerson/retrievePerson";
import RegisterOrganization from "./ManageOrganization/registerOrganization";
import AlertComponent from "./alert";

function CurrentPage({
  setComponentAction,
  currentComponent,
  currentComponentAction,
  countryCode,
}) {
  const setCurrentComponentAction = (text) => {
    setComponentAction(text);
  };

  // If no component is selected, display welcome message
  if (currentComponent === null) return <Home code={countryCode} />;
  else if (currentComponentAction === null)
    return (
      <ActionCards
        currentComponent={currentComponent}
        setComponentAction={setCurrentComponentAction}
      />
    );

  switch (currentComponent) {
    case "Membership Account":
      switch (currentComponentAction) {
        case "Retrieve/Update": {
          return <RetrieveAccount code={countryCode} />;
        }
        case "Open Goldstar Account": {
          return <OpenAccountComponent compliment={false} />;
        }
        case "Open Complimentary Goldstar Account": {
          return <OpenAccountComponent compliment={true} />;
        }
        case "Sale Order": {
          return <RetrieveOrder code={countryCode} />;
        }
      }
      break;
    case "Membership Card":
      switch (currentComponentAction) {
        case "Retrieve/Update": {
          return <RetrieveCard />;
        }
      }
      break;
    case "Organization Profile":
      switch (currentComponentAction) {
        case "Retrieve/Update": {
          return <RetrieveOrganization code={countryCode} />;
        }
        case "Register Organization": {
          return <RegisterOrganization />;
        }
      }
      break;
    case "Person Profile":
      switch (currentComponentAction) {
        case "Retrieve/Update": {
          return <RetrievePerson code={countryCode} />;
        }
      }
      break;
    case "Member Profile":
      switch (currentComponentAction) {
        case "Retrieve/Update": {
          return <RetrieveMember />;
        }
      }
      break;
  }
}

export default function Dashboard({ path, children }) {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [currentComponent, setCurrentComponent] = useState(null);
  const [currentComponentAction, setCurrentComponentAction] = useState(null);
  const [showChildren, setShowChildren] = useState(false);
  const [countryCode, setCountryCode] = useState("USA");

  useEffect(() => {
    if (path === "/") {
      if (
        !instance.getActiveAccount() &&
        instance.getAllAccounts().length > 0
      ) {
        instance.setActiveAccount(instance.getActiveAccount()?.[0]);
      }

      setShowChildren(false);
    } else {
      setShowChildren(true);
    }
  }, [path]);

  const setcontext = (value) => {
    if (value === "Home") {
      setCurrentComponent(null);
      setCurrentComponentAction(null);
    } else {
      setCurrentComponent(value);
      setCurrentComponentAction(null);
    }
  };

  const setComponentAction = (value) => {
    if (currentComponent == null) {
      setCurrentComponentAction(null);
    } else {
      setCurrentComponentAction(value);
    }
  };

  const handleLogin = async () => {
    try {
      await instance.loginRedirect();
    } catch (error) {
      console.error("Login redirect failed:", error);
    }
  };

  return (
    <MembershipItemsMenu
      setCurrentContext={setcontext}
      handleLogin={handleLogin}
    >
      <AlertComponent>
        {isAuthenticated ? (
          <LoginSuccess accounts={accounts} />
        ) : (
          <LoginFailed />
        )}
        {showChildren ? (
          children
        ) : (
          <CurrentPage
            currentComponent={currentComponent}
            currentComponentAction={currentComponentAction}
            setComponentAction={setComponentAction}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
          />
        )}
      </AlertComponent>
    </MembershipItemsMenu>
  );
}

import React from "react";
import Dashboard from "./src/pages";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./src/service/msalConfig";

// Wraps every page in a component
export function wrapPageElement({ element, props }) {
  return (
    <MsalProvider instance={msalInstance}>
      <Dashboard {...props}>{element}</Dashboard>
    </MsalProvider>
  );
}

import authConfig from "../config/app-config.json";
import { axiosPostV2 } from "../service/axiosApi";
import { msalInstance } from "../service/msalConfig";
import useAxiosInstance from "../service/useAxiosInstance";
import { TextSearchRequestBody } from "../dataTypes/textSearchTypes";
import { GetTextResults } from "../dataTypes/searchTypes";

const { searchBaseURL, searchOrganizationBaseURL } = authConfig;

export const useFetchByText = () => {
  const axiosInstance = useAxiosInstance(searchBaseURL, msalInstance);
  const axiosOrgInstance = useAxiosInstance(
    searchOrganizationBaseURL,
    msalInstance
  );

  const getTextResults: GetTextResults = (
    text,
    isOrg,
    code,
    setResult,
    updateSuccess,
    updateError
  ) => {
    const body: TextSearchRequestBody = {
      searchText: text,
      limit: 20,
      offset: 0,
      searchFilter: {
        country: code || "USA",
        state: "",
        firstName: "",
        lastName: "",
        city: "",
        postalCode: "",
      },
    };

    const endpoint = isOrg ? "search-organization" : "searchperson";
    const instance = isOrg ? axiosOrgInstance : axiosInstance;

    axiosPostV2(
      instance,
      endpoint,
      body,
      {},
      setResult,
      updateSuccess,
      updateError
    );
  };

  return getTextResults;
};

import React from "react";
import { Grid, Button, Container, Card, Typography } from "@mui/material";
import SubscribePerson from "../../pages/SubscriberPerson/subscriberPerson";
import IdentifyPerson from "../../pages/IndentifyPerson/identifyPerson";

import { styles } from "../../styles/actionCardsStyles";

function ActionCard(props) {
  return (
    <Button
      size="large"
      onClick={() => {
        props.action(props.text);
      }}
    >
      <Card item xs={3} sx={styles.cardContainer}>
        <Typography
          sx={styles.actionCardText}
        >
          {props.text}
        </Typography>
      </Card>
    </Button>
  );
}

export function ActionCards(props) {
  const setCurrentComponentAction = (text) => {
    props.setComponentAction(text);
  };

  function showAdditionalCards() {
    switch (props.currentComponent) {
      case "Person Profile": {
        return <>
          <SubscribePerson />
         <IdentifyPerson />
        </>;
      }
      case "Membership Account": {
        return (
          <>
            <ActionCard
              text="Open Goldstar Account"
              action={setCurrentComponentAction}
            />
            <ActionCard
              text="Open Complimentary Goldstar Account"
              action={setCurrentComponentAction}
            />
            <ActionCard
              text="Sale Order"
              action={setCurrentComponentAction}
            />
          </>
        );
      }
      case "Organization Profile": {
        return (
          <ActionCard
            text="Register Organization"
            action={setCurrentComponentAction}
          />
        );
      }
    }
  }

  return (
    <Container sx={{ mt: 5 }}>
      <Typography sx={{ typography: { sm: "h4", xs: "h5" } }}>
        {props.currentComponent}
      </Typography>
      <Grid
        container
        spacing={3}
        display={"flex"}
        justify-content={"center"}
        padding={5}
      >
        <ActionCard text="Retrieve/Update" action={setCurrentComponentAction} />
        {showAdditionalCards()}
      </Grid>
    </Container>
  );
}

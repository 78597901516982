import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const CountryPickerComponent = ({
  countryCode,
  setCountryCode,
  fromItemsMenu,
  countryList,
  required
}) => {
  const picker = (
    <Select
      id="country"
      value={countryCode}
      label="country"
      onChange={(event) => setCountryCode(event.target.value)}
    >
      {countryList.map((country) => (
        <MenuItem value={country} key={country}>
          {country}
        </MenuItem>
      ))}
    </Select>
  );

  return (
    <>
      {fromItemsMenu ? (
        picker
      ) : (
        <FormControl xs={2} fullWidth required={required} sx={{ minWidth: 120}} justify="flex-start">
          <InputLabel>Country</InputLabel>
          {picker}
        </FormControl>
      )}
    </>
  );
};
export default CountryPickerComponent;

import React, { FC, useContext, useState, useEffect } from "react";
import {
  Typography,
  Container,
  Paper,
} from "@mui/material";
import ProfileComponent from "../../components/OrganizationComponents/ProfileComponent";
import DemographicsComponent from "../../components/OrganizationComponents/DemographicsComponent";
import ContactsComponent from "../../components/OrganizationComponents/ContactsComponent";
import LicensesComponent from "../../components/OrganizationComponents/LicensesComponent";
import SearchComponent from "../../components/SearchComponent";
import AccountsComponent from "../../components/OrganizationComponents/ViewOrganizationComponents/AccountsComponent";
import MembersComponent from "../../components/OrganizationComponents/ViewOrganizationComponents/MembersComponent";
import CardsComponent from "../../components/OrganizationComponents/ViewOrganizationComponents/CardsComponent";
import PersonsComponent from "../../components/OrganizationComponents/ViewOrganizationComponents/PersonsComponent";
import { generateUUID } from "../../utils/generateUUID";
import { useLocation } from "@reach/router";
import authConfig from "../../config/app-config.json";
import OrganizationActions from "../../components/OrganizationComponents/OrganizationActions";
import { useCurrentAccountInfo } from "../../utils/useCurrentAccountInfo";
import { useMsal } from "@azure/msal-react";
import { AxiosService } from '../../service/restApiHandler';
import { useFetchByText } from "../../utils/useFetchByText";
import TextSearchResultsComponent from "../../components/TextSearchResultsComponent";
import { styles } from "../../styles/generalStyles";
import { AlertContext, CountryCodeContext } from "../../utils/context";

import { RetrieveOrgProps } from "../../dataTypes/organization/organization";
import { OrgInfoReponse } from "../../dataTypes/organization/organization-info";
import { OrgDetailsReponse, Organization } from "../../dataTypes/organization/organization-details";
import { TextSearchPersonResponse } from "../../dataTypes/search/textSearchTypes";

const baseURL = authConfig.manageOrganizationBaseURL;

const RetrieveOrganization: FC<RetrieveOrgProps> = ({ fromAnySearch, searchId, code }) => {
  const [orgID, setOrgID] = useState<string>(searchId);
  const [organizationInfo, setOrganizationInfo] = useState<OrgInfoReponse | null>(null);
  const [organizationDetails, setOrganizationDetails] = useState<OrgDetailsReponse | null>(null);
  const [isFromText, setIsFromText] = useState(false);
  const [textSearchResults, setTextSearchResults] = useState<TextSearchPersonResponse | null>(null);
  const handleOrgIDChange = (id) => setOrgID(id);
  const { username, warehouseLocation } = useCurrentAccountInfo();
  const { accounts, instance } = useMsal()
  const headers = { "country-code": code || "USA" };
  const getTextResults = useFetchByText();
  const alertFunction = useContext(AlertContext);
  const countryCode = useContext(CountryCodeContext);
  const axiosService = new AxiosService( baseURL, instance, accounts );

  const updateError = (error: string) => alertFunction("updateError", error);
  const updateSuccess = (data: OrgDetailsReponse, refresh?: boolean, onSuccessMessage?: string) =>
    alertFunction("updateSuccess", { data, refresh, onSuccessMessage });

  useEffect(() => {
    if (fromAnySearch) handleSearch(searchId);
  }, []);

  const refreshOrg = () => {
    handleSearch(orgID);
  };

  const handleSearch = (id: string) => {
    setOrganizationInfo(null);
    setOrganizationDetails(null);

    const isOrgId =
      /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/i.test(id);

    if (isOrgId) {
      setIsFromText(false);
      axiosService.get(   
        `retrieve-organization-info/${id}`,
        {headers: headers},
        setOrganizationInfo,
        updateSuccess,
        updateError
      );
      axiosService.get(        
        `retrieve-organization-details?organizationid=${id}`,
        {headers: headers},
        setOrganizationDetails,
        updateSuccess,
        updateError
      );
    } else {
      setIsFromText(true);
      getTextResults(
        id,
        true,
        code,
        setTextSearchResults,
        (data) => alertFunction("updateTextSearchSuccess", data),
        (error) => alertFunction("updateError", error)
      );
    }
  };

  const handleUpdate = async (
    entity,
    updatePath,
    updateData,
    onSuccessMessage
  ) => {
    const fullUpdateData = {
      mgloTransactionId: generateUUID(),
      mgloTransactionType: "MGLOUpdate",
      [entity]: {
        ...updateData,
        audit: {
          createdByChannel: "Desktop",
          createdByUser: username,
          createdAtLocationNumber: warehouseLocation,
          createdOnUtcDateTime: new Date(),
          mgloTransactionId: generateUUID(),
          mgloTransactionType: "MGLOUpdate",
        },
      },
    };
    const updateOverwriteSuccess = (data) =>
      updateSuccess(data, onSuccessMessage);

    axiosService.put(
      updatePath,
      fullUpdateData,
      {headers: headers},
      () => { },
      updateOverwriteSuccess,
      updateError
    );
  };

  //handle License updates
  const handleLicenseUpdate = async (
    updatePath: string,
    updateData: OrgDetailsReponse,
    onSuccessMessage: string
  ) => {
    const fullUpdateData = {
      ...updateData,
      mgloTransactionId: generateUUID(),
      mgloTransactionType: "MGLOUpdate",
      organizationId: orgID,
      createdByChannel: "Desktop",
      createdByUser: username,
      createdAtLocationNumber: warehouseLocation,
      createdOnUtcDateTime: new Date(),
    };

    if (
      updatePath === "remove-resale-license" ||
      updatePath === "add-resale-license"
    ) {
      axiosService.post(      
        updatePath,
        fullUpdateData,
        {headers: headers},
        () => { },
        () => { },
        updateError
      );
  
    } else {
      axiosService.put( 
        updatePath,
        fullUpdateData,
        {headers: headers},
        () => { },
        () => { },
        updateError
      );
    }
  };

  // handle Actions Data
  const handleAction = async (actionURL: string, onSuccessMessage: string) => {
    const actionData = {
      organizationId: orgID,
      mgloTransactionId: generateUUID(),
      pncAcknowledgementDate: new Date(),
      mgloTransactionType: "MGLOUpdate",
    };

    axiosService.post(
      actionURL,
      actionData,
      {headers: headers},
      () => { },
      () => { },
      updateError
    );

  };

  const location = useLocation();

  useEffect(() => {
    // Parse the query string to get the orgID value
    const queryParams = new URLSearchParams(location.search);
    const orgIdFromQuery = queryParams.get("orgID");
    if (orgIdFromQuery) {
      setOrgID(orgIdFromQuery);
      if (location.pathname === "/ManageOrganization/retrieveOrganization/")
        handleSearch(orgIdFromQuery);
    }
  }, [location.search, location.pathname]);

  return (    
      <Container disableGutters sx={{ justifyContent: "center" }}>
      {!fromAnySearch && (
        <SearchComponent
          callback={() => handleSearch(orgID)}
          label={"Enter Organization ID"}
          handleIdChange={handleOrgIDChange}
        />
      )}
      {!isFromText ? (
        organizationInfo ? (
          <Container disableGutters sx={styles.cardWidth}>
            <ProfileComponent
              profileData={organizationInfo.profile}
              onUpdate={(data) =>
                handleUpdate(
                  "profile",
                  "modify-organization-profile",
                  //{ profile: data },
                  data,
                  "Profile updated successfully!"
                )
              }
            />
            <DemographicsComponent
              demographicsData={organizationInfo.demographic}
              onUpdate={(data) =>
                handleUpdate(
                  "demographics",
                  "modify-organization-demographics",
                  data,
                  "Business Address updated successfully!"
                )
              }
            />
            <ContactsComponent
              contactsData={organizationInfo.contact}
              onUpdate={(data) =>
                handleUpdate(
                  "contact",
                  "modify-organization-contacts",
                  data,
                  "Contacts updated successfully!"
                )
              }
            />
            <LicensesComponent
              licensesData={organizationInfo?.licenses}
              membersData={organizationDetails?.members}
              createdByUser={username}
              onRemove={(data) =>
                handleLicenseUpdate(
                  "remove-resale-license",
                  data,
                  "License removed successfully!"
                )
              }
              onAdd={(data) =>
                handleLicenseUpdate(
                  "add-resale-license",
                  data,
                  "License added successfully!"
                )
              }
              onUpdate={(data) =>
                handleLicenseUpdate(
                  "edit-resale-license",
                  data,
                  "License updated successfully!"
                )
              }
            />
            {organizationDetails?.organization?.actions ? (
              <OrganizationActions
                actionData={organizationDetails.organization}
                onAction={handleAction}
                orgID={orgID}
              ></OrganizationActions>
            ) : (
              <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Organization Actions
                </Typography>
                <Typography>Organization Actions not available.</Typography>
              </Paper>
            )}
            {organizationDetails?.accounts ? (
              <AccountsComponent
                accountsDetails={organizationDetails?.accounts}
              />
            ) : (
              <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Accounts Information
                </Typography>
                <Typography>Accounts Information not available.</Typography>
              </Paper>
            )}
            {organizationDetails?.members ? (
              <MembersComponent membersDetails={organizationDetails?.members} />
            ) : (
              <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Members Information
                </Typography>
                <Typography>Members Information not available.</Typography>
              </Paper>
            )}
            {organizationDetails?.cards ? (
              <CardsComponent cardsDetails={organizationDetails?.cards} />
            ) : (
              <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Cards Information
                </Typography>
                <Typography>Cards Information not available.</Typography>
              </Paper>
            )}
            {organizationDetails?.persons ? (
              <PersonsComponent personsDetails={organizationDetails?.persons} />
            ) : (
              <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Persons Information
                </Typography>
                <Typography>Persons Information not available.</Typography>
              </Paper>
            )}
          </Container>
        ) : (
          <></>
        )
      ) : (
        textSearchResults?.results && (
          <TextSearchResultsComponent data={textSearchResults?.results} />
        )
      )}
    </Container>
  );
};

export default RetrieveOrganization;

import { Button, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import InitiateMemberDialog from "./InitiateMemberDialog";
import { Action } from "../../dataTypes/shared/sharedRequestTypes";
import { PersonViewResponse } from "../../dataTypes/person/personRequestTypes";

const defaultActionTexts = [
  "Violated policy",
  "Reinstate",
  "Forget",
  "Died",
  "Identify",
];

const mapActionTextToPath = (text: string) => {
  switch (text) {
    case "Violated policy":
      return "person-violated-policy";
    case "Reinstate":
      return "reinstate-person";
    case "Forget":
      return "forget-person";
    case "Died":
      return "person-died";
    case "Identify":
      return "identify-person";
    default:
      throw new Error(`Unexpected action text: ${text}`);
  }
};

interface PersonDetailsProps {
  personDetails: PersonViewResponse;
  isProtectedPerson: boolean;
  onAction: (actionPath: string, onSuccessMessage: string) => void;
}

const PersonActions = ({
  personDetails,
  isProtectedPerson,
  onAction,
}: PersonDetailsProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openInitiateMemberDialog, setOpenInitiateMemberDialog] =
    useState(false);
  const isDeceasedPerson = personDetails.person.profile.status === "Deceased";

  const actionTexts =
    personDetails?.person?.actions?.length > 0
      ? personDetails.person.actions.map((action: Action) => action.text)
      : defaultActionTexts;

  const actions = actionTexts.map((text: string) => ({
    text,
    path: mapActionTextToPath(text),
  }));

  const handleActionClick = (actionURL: string, onSuccessMessage: string) => {
    setIsSubmitting(true);
    onAction(actionURL, onSuccessMessage);
    setIsSubmitting(false);
  };

  const handleDialogClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setOpenInitiateMemberDialog(false);
  };

  const openDialog = () => {
    setOpenInitiateMemberDialog(true);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Person Actions
      </Typography>
      <Grid container spacing={1}>
        {actions.map((action) => {
          return (
            <Grid item={true} key={action.text}>
              <Button
                variant="contained"
                key={action.text}
                onClick={() => {
                  // using lambda here because it's a loop variable. ;-)
                  handleActionClick(
                    action.path,
                    `Action '${action.text}' successful!`
                  );
                }}
                disabled={isSubmitting || isProtectedPerson || isDeceasedPerson}
              >
                {action.text}
              </Button>
            </Grid>
          );
        })}
        <Grid item={true} key={"initiate-member-item"}>
          <Button
            variant="contained"
            key={"initiate-member"}
            onClick={openDialog}
            disabled={isSubmitting || isProtectedPerson || isDeceasedPerson}
          >
            {"Initiate Member"}
          </Button>
        </Grid>
      </Grid>
      <InitiateMemberDialog
        open={openInitiateMemberDialog}
        handleClose={handleDialogClose}
        personDetails={personDetails}
      />
    </Paper>
  );
};

export default PersonActions;

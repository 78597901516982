// ProfileComponent.js
import React, { useState } from "react";
import { TextField, Typography, Paper } from "@mui/material";

const CardsComponent = ({ cardsDetails }) => {
	const [cards] = useState(cardsDetails);

	return (
		<Paper elevation={3} sx={{ p: 3, mb: 4 }}>
			<Typography variant="h6" sx={{ mb: 2 }}>
				Cards Information
			</Typography>
			{cards?.map((card, index) => (
				<Paper key={index} elevation={3} sx={{ p: 3, mb: 4 }}>
					<Typography variant="body1" sx={{ mb: 1 }}>
						Card {index + 1}
					</Typography>
					<TextField
						margin="normal"
						fullWidth
						name="country"
						label="Country"
						variant="outlined"
						value={card?.country}
						disabled={true}
					/>
					<TextField
						margin="normal"
						fullWidth
						name="cardNumber"
						label="Card Number"
						variant="outlined"
						value={card?.cardNumber}
						disabled={true}
					/>
					<TextField
						margin="normal"
						fullWidth
						name="memberId"
						label="Member ID"
						variant="outlined"
						value={card?.memberId}
						disabled={true}
					/>
					<TextField
						margin="normal"
						fullWidth
						name="type"
						label="Type"
						variant="outlined"
						value={card?.type || ""}
						disabled={true}
					/>
					<TextField
						margin="normal"
						fullWidth
						name="status"
						label="Status"
						variant="outlined"
						value={card?.status || ""}
						disabled={true}
					/>
				</Paper>
			))}
		</Paper>
	);
};

export default CardsComponent;

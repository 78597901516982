import React, { FC, useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
	TextField,
	Button,
	Typography,
	Container,
	Paper,
	Grid,
	Switch,
	FormControlLabel,
	Box,
	MenuItem
} from "@mui/material";
import { styles } from "../../styles/orgStyles"
import useAxiosInstance from "../../service/useAxiosInstance";
import { msalInstance } from "../../service/msalConfig";
import { axiosPost } from "../../service/axiosApi";
import authConfig from "../../config/app-config.json";
import { generateUUID } from "../../utils/generateUUID";
import { License, LicensesComponentInput, MemberCardType, MembersDisplayType, OrgMember } from "../../dataTypes/organization/orgTypes";
const baseURL = authConfig.manageOrganizationBaseURL;


const LicensesComponent: FC<LicensesComponentInput> = ({ licensesData, membersData, createdByUser, onUpdate, onRemove, onAdd }) => {
	const initialLicenseVal = {
		"type": "RESALE-General",
		"reason": "",
		"number": "",
		"licenseNumber": null,
		"country": "",
		"stateProvince": "",
		'createdByUser': createdByUser,
		"addedOn": null,
		"expiresOn": "",
		"licenseDoesnotExpire": false,
		"tobaccoPreference": false,
		"address": {},
		"organizationId": "",
		"legacyOrganizationId": "",
		"createdByChannel": "",
		"createdAtLocationNumber": null,
		"mgloTransactionType": "",
		"mgloTransactionId": ""
	}
	const initialNewAddress = {
		"line1": "",
		"line2": "",
		"city": "",
		"stateProvince": "",
		"postalCode": "",
		"country": "",
		"type": "",
		"status": "Active"
	}
	const [licenses, setLicenses] = useState(licensesData || []);
	const [addLicenseIsOpen, setAddLicenseIsOpen] = useState<boolean>(false);
	const [newLicense, setNewLicense] = useState(initialLicenseVal);
	const [newLicenseAddress, setNewLicenseAddress] = useState(initialNewAddress);
	const axiosInstance = useAxiosInstance(baseURL, msalInstance);

	const handleNewLicenseChange = (e: any, field: string) => {
		const updatedLicense = { ...newLicense, 'createdByUser': createdByUser };
		const updatedNewLicenseAddress = { ...newLicenseAddress };
		updatedLicense.organizationId = licenses[0]["organizationId"]
		if (
			field === "licenseDoesnotExpire" ||
			field === "tobaccoPreference"
		) {
			// Handling boolean fields
			if (field === "licenseDoesnotExpire")
				updatedLicense.expiresOn = "";
			updatedLicense[field] = e.target.checked;
		} if (
			field === "line1" ||
			field === "line2" ||
			field === "city" ||
			field === "postalCode" ||
			field === "type" ||
			field === "status" ||
			field === "country" ||
			field === "stateProvince"
		) {
			updatedNewLicenseAddress[field] = e.target.value;
			updatedLicense.address = updatedNewLicenseAddress;
		} else {
			updatedLicense[field] = e.target.value;
		}
		setNewLicenseAddress(updatedNewLicenseAddress)
		setNewLicense(updatedLicense);
	};

	const handleChange = (e: any, index: number, field: string) => {
		const updatedLicenses: License[]  = [...licenses];
		if (field.startsWith("address.")) {
			// Handling nested address object
			const addressField = field.split(".")[1];
			updatedLicenses[index].address[addressField] = e.target.value;
		} else if (
			field === "licenseDoesnotExpire" ||
			field === "tobaccoPreference"
		) {
			// Handling boolean fields
			if (field === "licenseDoesnotExpire")
				updatedLicenses[index]["expiresOn"] = "";
			updatedLicenses[index][field] = e.target.checked;
		} else {
			updatedLicenses[index][field] = e.target.value;
			updatedLicenses[index]['createdByUser'] = createdByUser;
		}
		setLicenses(updatedLicenses);
	};

	const handleSubmit = (index: number, e: any) => {
		e?.preventDefault();
		const buttonId = e?.nativeEvent?.submitter?.id;
		let data = (buttonId === "addButton") ? newLicense : licenses[index]
		if (data?.address?.["line3"]) delete data?.address["line3"]
		if (buttonId === "updateButton")
			onUpdate({ ...data, "licenseNumber": data?.number });
		else if (buttonId === "removeButton")
			onRemove({ ...data, "licenseNumber": data?.number });
		else if (buttonId === "addButton") {
			onAdd({ ...data, "licenseNumber": data?.number });
			setAddLicenseIsOpen(false)
			setNewLicense(initialLicenseVal);
		}
	};
	const licenseTypeOptions = [
		'RESALE-General',
		'EXEMPT-Nonprofit',
		'EXEMPT-Church/Religious Org',
		'EXEMPT-GOV\'T-State/Local',
		'EXEMPT-GOV\'T-US/Fed/Red Cross',
		'EXEMPT-Education-Public',
		'EXEMPT-Education-Private',
		'EXEMPT-Credit Union-Federal',
		'EXEMPT-Credit Union-State',
		'EXEMPT-Direct Pay',
		'EXEMPT-Enterprise Zone',
		'RESALE-Tobacco',
		'RESALE-Liquor',
		'RESALE-City/County',
		'EXEMPT-Other'
	];

	const fetchAllMemberNames = (members: OrgMember[] | undefined, authMembers: OrgMember[] | undefined) => {		
		const membersWithNames: any = [];
		const isMemberInArray = (memberId, membersArray) => {
			return membersArray.some(member => member.memberId === memberId);
		};

		members?.map((member, index) => {
			membersWithNames[index] = { memberId: member.memberId, personName: member.personId, authorized: false };
			if (authMembers && isMemberInArray(member.memberId, authMembers)) {
				membersWithNames[index] = { memberId: member.memberId, personName: member.personId, authorized: true };
			}
		});

		return membersWithNames;
	};

	const toggleAuthorization = async (license: License, memberId: string | number, authorized: boolean) => {
		const data = {
			"organizationId": license.organizationId,
			"memberId": memberId,
			"number": license.number,
			"stateProvince": license.stateProvince,
			"mgloTransactionId": generateUUID(),
			"mgloTransactionType": "Migration"
		}		
		let endPoint = "authorize-license-use"
		if(authorized) {
			endPoint = 	"unauthorize-license-use"
		}
		axiosPost(
			axiosInstance,
			endPoint,
			{
				'Content-Type': 'application/json',
				"country-code": license.country
			},
			data,
		);

	};


	const MemberCard: FC<MemberCardType> = ({ license, memberId, personName, authorized }) => {
		return (
			<Button
				onClick={() => toggleAuthorization(license, memberId, authorized ? true : false)}
				sx={styles.memberCard(authorized)}
			>
				<Typography>{personName}</Typography>
				{authorized && <CheckCircleIcon sx={{ color: '#28a745', marginLeft: '10px' }} />}
			</Button>
		);
	};

	const MembersDisplay: FC<MembersDisplayType> = ({ license, members }) => {
		return (
			<>
				<Typography variant="h6" sx={{ marginBottom: '20px', marginTop: '40px' }}>Members</Typography>
				<Grid container spacing={2} sx={{ marginBottom: '40px' }}>
					{members?.map(member => (
						<Grid item key={member.memberId}>
							<MemberCard license={license} memberId={member.memberId} personName={member.personName} authorized={member.authorized} />
						</Grid>
					))}
				</Grid>
			</>
		);
	};

	return (
		<Paper elevation={3} sx={{ p: 3, mb: 4, mt: 4 }}>

			<Box display="flex" justifyContent="space-between">
				<Typography variant="h6" sx={{ mb: 2 }}>
					Licenses Information test
				</Typography>
				{!addLicenseIsOpen && <Button type="submit" id="addButton" variant="contained" color="primary" onClick={() => setAddLicenseIsOpen(true)}>
					Add License
				</Button>}
			</Box>

			{licenses.length > 0 ? (

				licenses.filter((license) => license.audit?.event?.name !== "RemoveResaleLicense")
					.map((license, index) => (

						<Container key={index} sx={{ marginBottom: "20px" }}>
							<form onSubmit={(e) => handleSubmit(index, e)}>
								<Typography variant="body1" sx={{ mb: 1 }}
									onClick={() => console.log("test")}
								>
									License {license.number}
								</Typography>

								<TextField
									select
									fullWidth
									margin="normal"
									name={`type-${index}`}
									label="License Type"
									variant="outlined"
									value={license.type}
									onChange={(e) => handleChange(e, index, "type")}
									sx={{ mb: 1 }}
								>
									{licenseTypeOptions.map((option) => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
								</TextField>
								<TextField
									fullWidth
									margin="normal"
									name={`number-${index}`}
									label="License Number"
									variant="outlined"
									value={license.number}
									disabled
									// onChange={(e) => handleChange(e, index, "number")}
									sx={{ mb: 1 }}
								/>
								<Grid container spacing={2}>
									<Grid item xs={12} md={6}>
										<TextField
											fullWidth
											margin="normal"
											name={`country-${index}`}
											label="Country"
											variant="outlined"
											value={license.country}
											onChange={(e) => handleChange(e, index, "country")}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextField
											fullWidth
											margin="normal"
											name={`stateProvince-${index}`}
											label="State/Province"
											variant="outlined"
											value={license.stateProvince}
											onChange={(e) => handleChange(e, index, "stateProvince")}
										/>
									</Grid>
								</Grid>
								<TextField
									fullWidth
									margin="normal"
									name={`addedOn-${index}`}
									label="Added On"
									type="date"
									InputLabelProps={{ shrink: true }}
									variant="outlined"
									value={license.addedOn}
									onChange={(e) => handleChange(e, index, "addedOn")}
									sx={{ mb: 1 }}
								/>
								<TextField
									fullWidth
									margin="normal"
									name={`expiresOn-${index}`}
									label="Expires On"
									type="date"
									InputLabelProps={{ shrink: true }}
									variant="outlined"
									value={license.expiresOn}
									onChange={(e) => handleChange(e, index, "expiresOn")}
									sx={{ mb: 1 }}
								/>
								<Grid container spacing={2} display="flex">
								<Grid item>
									<FormControlLabel
										control={
											<Switch
												checked={license.licenseDoesnotExpire}
												onChange={(e) =>
													handleChange(e, index, "licenseDoesnotExpire")
												}
											/>
										}
										label="License Does Not Expire"
									/>
								</Grid>
								<Grid item>
									<FormControlLabel
										control={
											<Switch
												checked={license.tobaccoPreference}
												onChange={(e) =>
													handleChange(e, index, "tobaccoPreference")
												}
											/>
										}
										label="Tobacco Preference"
									/>
								</Grid>	
								</Grid>
								<Grid container spacing={2} sx={{ mt: 1 }}>
								<Grid item>
									<Button type="submit" id="updateButton" variant="contained" color="primary" style={{ marginRight: '10px' }}>
										Update License
									</Button>
								</Grid>	
								<Grid item>
									<Button id="removeButton" variant="contained" sx={{ color: "primary" }}>
										Remove License
									</Button>
								</Grid>	
								</Grid>
									<MembersDisplay license={license} members={fetchAllMemberNames(membersData, licenses[index]?.authorizedMembers)} />
							</form>
						</Container>

					))
			) : (
				<Typography>No licenses available.</Typography>
			)}
			{addLicenseIsOpen && (
				<form onSubmit={(e) => handleSubmit(NaN, e)}>
					<Typography variant="body1" sx={{ mb: 1 }}>
						New License
					</Typography>
					<TextField
						select
						fullWidth
						margin="normal"
						name={`type-new`}
						label="License Type"
						variant="outlined"
						value={newLicense.type}
						onChange={(e) => handleNewLicenseChange(e, "type")}
						sx={{ mb: 1 }}
					>
						{licenseTypeOptions.map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
						))}
					</TextField>
					<TextField
						fullWidth
						type="number"
						margin="normal"
						name={`number-new`}
						label="License Number"
						variant="outlined"
						value={newLicense.number}
						onChange={(e) => handleNewLicenseChange(e, "number")}
						sx={{ mb: 1 }}
					/>
					<TextField
						fullWidth
						margin="normal"
						name={`createdAtLocationNumber-new`}
						label="Created At Location Number"
						variant="outlined"
						value={newLicense.createdAtLocationNumber}
						onChange={(e) => handleNewLicenseChange(e, "createdAtLocationNumber")}
						sx={{ mb: 1 }}
					/>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<TextField
								fullWidth
								margin="normal"
								name={`line1-new`}
								label="Line1"
								variant="outlined"
								value={newLicenseAddress.line1}
								onChange={(e) => handleNewLicenseChange(e, "line1")}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								fullWidth
								margin="normal"
								name={`line2-new`}
								label="Line2"
								variant="outlined"
								value={newLicenseAddress.line2}
								onChange={(e) => handleNewLicenseChange(e, "line2")}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								fullWidth
								margin="normal"
								name={`city-new`}
								label="City"
								variant="outlined"
								value={newLicenseAddress.city}
								onChange={(e) => handleNewLicenseChange(e, "city")}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								fullWidth
								margin="normal"
								name={`stateProvince-new`}
								label="State/Province"
								variant="outlined"
								value={newLicenseAddress.stateProvince}
								onChange={(e) => handleNewLicenseChange(e, "stateProvince")}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								fullWidth
								margin="normal"
								name={`country-new`}
								label="Country"
								variant="outlined"
								value={newLicenseAddress.country}
								onChange={(e) => handleNewLicenseChange(e, "country")}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								fullWidth
								margin="normal"
								name={`postalCode-new`}
								label="Postal Code"
								variant="outlined"
								value={newLicenseAddress.postalCode}
								onChange={(e) => handleNewLicenseChange(e, "postalCode")}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								fullWidth
								margin="normal"
								name={`type-new`}
								label="Type"
								variant="outlined"
								value={newLicenseAddress.type}
								onChange={(e) => handleNewLicenseChange(e, "type")}

							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								fullWidth
								margin="normal"
								name={`status-new`}
								label="Status"
								variant="outlined"
								value={newLicenseAddress.status}
								onChange={(e) => handleNewLicenseChange(e, "status")}
							/>
						</Grid>
					</Grid>
					<TextField
						fullWidth
						margin="normal"
						name={`addedOn-new`}
						label="Added On"
						type="date"
						InputLabelProps={{ shrink: true }}
						variant="outlined"
						value={newLicense?.addedOn}
						onChange={(e) => handleNewLicenseChange(e, "addedOn")}
						sx={{ mb: 1 }}
					/>
					<TextField
						fullWidth
						margin="normal"
						name={`expiresOn-new`}
						label="Expires On"
						type="date"
						InputLabelProps={{ shrink: true }}
						variant="outlined"
						value={newLicense?.expiresOn}
						onChange={(e) => handleNewLicenseChange(e, "expiresOn")}
						sx={{ mb: 1 }}
					/>
					<Box display="flex" justifyContent="space-between">
						<FormControlLabel
							control={
								<Switch
									checked={newLicense.licenseDoesnotExpire}
									onChange={(e) =>
										handleNewLicenseChange(e, "licenseDoesnotExpire")
									}
								/>
							}
							label="License Does Not Expire"
							name={`LicenseDoesNotExpire-new`}
						/>
						<FormControlLabel
							control={
								<Switch
									checked={newLicense.tobaccoPreference}
									onChange={(e) =>
										handleNewLicenseChange(e, "tobaccoPreference")
									}
								/>
							}
							label="Tobacco Preference"
							name={`TobaccoPreference-new`}
						/>
						<Button id="addButton" variant="contained" sx={{ color: "primary" }}>
							Add License
						</Button>
					</Box>
				</form>
			)}

		</Paper>
	);
};

export default LicensesComponent;

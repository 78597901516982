export const styles = {
    addLisenceButton: {
        height: '30px',
    },
    memberCard: (authorized) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 15px',
        borderRadius: '8px',
        border: '2px solid',
        borderColor: authorized ? '#28a745' : '#007bff',
        backgroundColor: authorized ? '#e7f1ff' : '#f8f9fa',
        fontWeight: 'bold',
        width: 'fit-content',
    }),
    membersDisplay: { padding: '20px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' },
    membersDisplayBox: { padding: '20px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' },
};
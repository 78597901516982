// ProfileComponent.js
import React, { useState } from "react";
import { TextField, Typography, Paper } from "@mui/material";

const PersonsComponent = ({ personsDetails }) => {
  const [persons] = useState(personsDetails);
  console.log(personsDetails);

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Persons Information
      </Typography>
      {personsDetails.length > 0 ? (
        persons?.map((person, index) => (
          <Paper key={index} elevation={3} sx={{ p: 3, mb: 4 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Person {index + 1}
            </Typography>
            <TextField
              margin="normal"
              fullWidth
              name="personId"
              label="Person ID"
              variant="outlined"
              value={person?.personId}
              disabled={true}
            />
            <TextField
              margin="normal"
              fullWidth
              name="legacyPersonId"
              label="Legacy Person ID"
              variant="outlined"
              value={person?.legacyPersonId}
              disabled={true}
            />
            <TextField
              margin="normal"
              fullWidth
              name="status"
              label="Status"
              variant="outlined"
              value={person?.status || ""}
              disabled={true}
            />
          </Paper>
        ))
      ) : (
        <Typography>Persons Information not available.</Typography>
      )}
    </Paper>
  );
};

export default PersonsComponent;

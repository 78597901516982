import React, { useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Card,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Autocomplete,
  Tooltip,
  Zoom,
} from "@mui/material";
import authConfig from "../../config/app-config.json";
import useAxiosInstance from "../../service/useAxiosInstance";
import merge from "deepmerge";
import { navigate } from "gatsby";
import { produce } from "immer";
import { msalInstance } from "../../service/msalConfig";
import { axiosPostPromise } from "../../service/axiosApi";
import { generateUUID } from "../../utils/generateUUID";
import { useCurrentAccountInfo } from "../../utils/useCurrentAccountInfo";
import { getDefaultPrivacyConsent } from "../../utils/getDefaultPrivacyConsent";
import { validateEmail } from "../../utils/validateEmailFormat";
import { getAge, deltaDate } from "../../utils/getAge";
import CountryPickerComponent from "../../components/CountryPickerComponent";
import identifyPersonDefault from "../../data/identifyPersonDefault.json";
import phoneCountryCodes from "../../data/phoneCountryCodes.json";
import stateList from "../../data/stateList.json";
import phoneTypes from "../../data/phoneTypes.json";
import emailTypes from "../../data/emailTypes.json";
import idDocumentsTypes from "../../data/idDocumentsTypes.json";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import { styles, iconButtonStyles } from "../../styles/actionCardsStyles";
import { Error } from "../../dataTypes/alert/alertTypes";
import { useImmerUpdaters } from "../../utils/useImmerUpdaters";
import {
  Country,
  StateProvinceCode,
} from "../../dataTypes/shared/locationTypes";
import { IdentifyPersonRequest } from "../../dataTypes/person/personRequestTypes";

const baseURLV3 = authConfig.basePersonUrlV3;
const isNotEmptyString = (str: string | null | undefined) =>
  str != null && str.trim().length > 0;
const producePrivacyConsent = (country: string, stateProvince: string | null) =>
  merge(
    identifyPersonDefault.privacyConsent,
    getDefaultPrivacyConsent(country, stateProvince),
  );

const IdentifyPerson = () => {
  const [countryCode, setCountryCode] = useState<Country | string>("USA");


  const [identifyPersonInfo, setIdentifyPersonInfo] = useState({
    ...identifyPersonDefault as IdentifyPersonRequest,
    privacyConsent: producePrivacyConsent(countryCode, ""),
  });

  const [updateIdentifyPersonInfo, handleUpdateIdentifyPersonInfo] =
    useImmerUpdaters(identifyPersonInfo, setIdentifyPersonInfo);

  const { username, warehouseLocation } = useCurrentAccountInfo();
  const axiosInstanceV3 = useAxiosInstance(baseURLV3, msalInstance);
  const [open, setOpen] = useState(false);
  const [identifyPersonId, setIdentifyPersonId] = useState<string | null>(null);
  const [invalidDocNumber, setInvalidDocNumber] = useState(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
  const [invalidPostalcode, setInvalidPostalcode] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const headers = { "country-code": countryCode };
  let dialogTitle = "Identify Person";

  const mgloIdentifyPersonTransactionId = generateUUID();

  const isUSA = countryCode === "USA";
  const isCAN = countryCode === "CAN";

  const handleClickOpen = () => {
    setOpen(true);
    setIdentifyPersonInfo(identifyPersonDefault);
  };

  const handleClose = () => {
    setShowErrors(false);
    setOpen(false);
    setIdentifyPersonInfo(identifyPersonDefault);
  };

  // Identify Another person
  const handleNewForm = () => {
    setIdentifyPersonInfo(identifyPersonDefault);
    setIdentifyPersonId(null);
    setShowErrors(false);
  };

  const adjustIdentifyPersonInfoFromLocation = (
    identifyPersonInfo: IdentifyPersonRequest,
    countryCode: string,
    stateProvince: string | null,
  ) => {
    // Adjusting privacy consent (USA, USA-Cali, CAN)
    identifyPersonInfo.privacyConsent = producePrivacyConsent(
      countryCode,
      stateProvince,
    );

    if (isUSA) {
      identifyPersonInfo.profile.languagePref = "en-US";
    } else if (isCAN) {
      identifyPersonInfo.profile.languagePref = "en-CA";
    } else {
      identifyPersonInfo.profile.languagePref = null;
    }

    identifyPersonInfo.profile.country = countryCode;
    identifyPersonInfo.residence.address.country = countryCode;
    identifyPersonInfo.marketingPref.country = countryCode;
    identifyPersonInfo.privacyConsent.country = countryCode;
  };

  const handleCountryCodeChange = (countryCode: string) => {
    // Clearing out the state province, idDocs when country changes
    setCountryCode(countryCode);
    updateIdentifyPersonInfo((p) => {
      adjustIdentifyPersonInfoFromLocation(p, countryCode, "");
      //if(!isUSA) {
      if (countryCode !== "USA") {
        p.profile.idDocuments[0].type = "";
        p.profile.idDocuments[0].number = "";
        p.profile.idDocuments[0].stateProvince = "";
        p.profile.idDocuments[0].country = "";
      }
      p.residence.address.stateProvince = "";
    });
  };

  const handleStateChange = (e: any, stateProvince: string | null) => {
    // Setting the privacy consent for the US based on state province (California and other states)
    updateIdentifyPersonInfo((p) => {
      p.residence.address.stateProvince = stateProvince;
      adjustIdentifyPersonInfoFromLocation(p, countryCode, stateProvince);
    });
  };

  // Check Id Documents Type
  const idType = identifyPersonInfo.profile.idDocuments[0].type;
  const handleIDTypeChange = (e: { target: { value: string } }) => {
    const newIdType = e.target.value;
    if (newIdType === "PASSPORT") {
      updateIdentifyPersonInfo((p) => {
        p.profile.idDocuments[0].type = newIdType;
        p.profile.idDocuments[0].stateProvince = "";
        p.profile.idDocuments[0].country = "";
      });
    } else if (newIdType === "MILITARYID") {
      updateIdentifyPersonInfo((p) => {
        p.profile.idDocuments[0].type = newIdType;
        p.profile.idDocuments[0].stateProvince = "";
      });
    } else {
      updateIdentifyPersonInfo((p) => {
        p.profile.idDocuments[0].type = newIdType;
      });
    }
  };

  // Check Valid Id Documents change
  const handleIDDocNumberChange = (e: { target: { value: string } }) => {
    updateIdentifyPersonInfo((p) => {
      p.profile.idDocuments[0].number = e.target.value;
      setInvalidDocNumber(false);
    });
  };

  // Check Valid phone number change
  const handlePhoneNumberChange = (e: { target: { value: string } }) => {
    updateIdentifyPersonInfo((p) => {
      p.contacts.phones[0].number = e.target.value;
      setInvalidPhoneNumber(false);
    });
  };

  // Check Valid Portalcode change
  const handlePostalCodeChange = (e: { target: { value: string } }) => {
    updateIdentifyPersonInfo((p) => {
      p.residence.address.postalCode = e.target.value;
      setInvalidPostalcode(false);
    });
  };

  // // Handle Errors
  const handleError = (error: Error) => {
    const invalidIdDocNumber =
      error.error.response.data.validationErrors[
        "Profile.IdDocuments[0].Number"
      ];
    const invalidPhoneNumber =
      error.error.response.data.validationErrors["Contacts.Phones[0].Number"];
    const invalidPostalCode =
      error.error.response.data.validationErrors[
        "Residence.Address.PostalCode"
      ];
    setInvalidDocNumber(invalidIdDocNumber);
    setInvalidPhoneNumber(invalidPhoneNumber);
    setInvalidPostalcode(invalidPostalCode);
  };

  // Form Validity
  const age = getAge(identifyPersonInfo.profile.birthDate);
  const minDate = deltaDate(new Date(), 0, 0, -120).toISOString().split("T")[0];
  const maxDate = deltaDate(new Date(), 0, 0, -16).toISOString().split("T")[0];
  const isIdStateRequired = idType !== "PASSPORT" && idType !== "MILITARYID";
  const isIdSCountryRequired = idType !== "PASSPORT";

  const isFieldValid = {
    age: age >= 16 && age <= 120,
    firstName: isNotEmptyString(identifyPersonInfo.profile.firstName),
    lastName: isNotEmptyString(identifyPersonInfo.profile.lastName),
    idDocType: !isUSA || isNotEmptyString(identifyPersonInfo.profile.idDocuments[0].type),
    idDocNumber: !isUSA || (!invalidDocNumber && isNotEmptyString(identifyPersonInfo.profile.idDocuments[0].number)),
    idDocState: !isUSA || !isIdStateRequired || isNotEmptyString(identifyPersonInfo.profile.idDocuments[0].stateProvince),
    idDocCountry: !isUSA || !isIdSCountryRequired || isNotEmptyString(identifyPersonInfo.profile.idDocuments[0].country),
    phoneCountryCode: isNotEmptyString(identifyPersonInfo.contacts.phones[0].countryCode),
    phoneNumber: !invalidPhoneNumber && isNotEmptyString(identifyPersonInfo.contacts.phones[0].number),
    phoneType: isNotEmptyString(identifyPersonInfo.contacts.phones[0].type),
    emailAddress: isNotEmptyString(identifyPersonInfo.contacts.emails[0].address) && validateEmail(identifyPersonInfo.contacts.emails[0].address),
    emailType: isNotEmptyString(identifyPersonInfo.contacts.emails[0].type),
    addressLine1: isNotEmptyString(identifyPersonInfo.residence.address.line1),
    addressCity: isNotEmptyString(identifyPersonInfo.residence.address.city),
    addressStateProvince: isNotEmptyString(identifyPersonInfo.residence.address.stateProvince),
    addressPotalCode: !invalidPostalcode && isNotEmptyString(identifyPersonInfo.residence.address.postalCode),
  };
  const isFormValid = Object.keys(isFieldValid).every((k) => isFieldValid[k]);

  // Adjusting full data
  const adjustIdentifyPersonInfo = (
    identifyPersonInfo: IdentifyPersonRequest,
  ) => {
    // The backend expects a timestamp, but only the year and month will be validated
    identifyPersonInfo.profile.birthDate = `${identifyPersonInfo.profile.birthDate}T00:00:00Z`;

    // Required property 'consentDate' (Privacy Consent)
    identifyPersonInfo.privacyConsent.objectToMarketing.consentDate =
      new Date().toISOString();
    identifyPersonInfo.privacyConsent.restrictPIUsage.consentDate =
      new Date().toISOString();
    identifyPersonInfo.privacyConsent.rejectPIProcessing.consentDate =
      new Date().toISOString();

    // ++++++  Add Audits to all categories ++++++
    for (const categoryName in identifyPersonInfo) {
      identifyPersonInfo[categoryName].audit = {
        createdByChannel: "Desktop",
        createdByUser: username,
        createdAtLocationNumber: warehouseLocation,
        mgloTransactionType: "MGLOUpdate",
        mgloTransactionId: mgloIdentifyPersonTransactionId,
        createdOnUtcDateTime: new Date().toISOString(),
      };
    }

    // ID Documents are only required for the USA, backend expects null for all other countries
    // Checking id types, set null as required.
    if (!isUSA) identifyPersonInfo.profile.idDocuments = [];
    if (idType === "MILITARYID" || idType === "PASSPORT")
      identifyPersonInfo.profile.idDocuments[0].stateProvince = null;
    if (idType === "PASSPORT")
      identifyPersonInfo.profile.idDocuments[0].country = null;
  };

  const handleIdentifyPerson = async () => {
    if (!isFormValid) {
      setShowErrors(true);
      return;
    }

    const identifyPersonFullData = produce(
      identifyPersonInfo,
      adjustIdentifyPersonInfo,
    );

    try {
      const result = await axiosPostPromise(
        axiosInstanceV3,
        "identify-person",
        identifyPersonFullData,
        headers,
      );
      setIdentifyPersonId(result.id);
    } catch (error) {
      console.error(error);
      handleError(error);
    }
  };

  const retrieveSubscriber = (id: string) => {
    return (
      <Paper elevation={2}>
        <Button
          fullWidth
          variant="outlined"
          sx={styles.personWithParams}
          onClick={() =>
            navigate(
              `/ManagePerson/retrievePerson?personID=${id}&countryCode=${countryCode}`,
            )
          }
        >
          <AccountCircleIcon fontSize="large" />
          {id}
          <ArrowForwardIosIcon />
        </Button>
      </Paper>
    );
  };

  const renderNewFormDialogContents = () => (
    <>
      <DialogContent>
        <DialogContentText sx={{ mt: 1, mb: 2 }}>
          To identify person, please enter the required information:
        </DialogContentText>

        {/* Profile: */}
        <Box component="section" sx={styles.boxContainer}>
          <Typography variant="subtitle1" sx={styles.boxSubTitle}>
            <b>Profile</b>
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                fullWidth
                name="firstlName"
                label="First Name"
                variant="outlined"
                id="firstName"
                error={showErrors && !isFieldValid.firstName}
                value={identifyPersonInfo.profile.firstName}
                onChange={handleUpdateIdentifyPersonInfo(
                  (p, v) => (p.profile.firstName = v),
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                fullWidth
                name="lastName"
                label="Last Name"
                variant="outlined"
                id="lastName"
                error={showErrors && !isFieldValid.lastName}
                value={identifyPersonInfo.profile.lastName}
                onChange={handleUpdateIdentifyPersonInfo(
                  (p, v) => (p.profile.lastName = v),
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                name="birthDate"
                label="Birth Date"
                type="date"
                variant="outlined"
                id="birthDate"
                error={showErrors && !isFieldValid.age}
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: minDate, max: maxDate } }}
                value={identifyPersonInfo.profile.birthDate}
                onChange={handleUpdateIdentifyPersonInfo(
                  (p, v) => (p.profile.birthDate = v),
                )}
                helperText="Must be 16 to 120 years old"
              />
            </Grid>
          </Grid>

          <Typography variant="subtitle1" sx={styles.boxSubTitle}>
            <b>ID Documents</b>{" "}
            {isCAN && (
              <Tooltip
                TransitionComponent={Zoom}
                title="ID Documents are only required for the USA"
              >
                <Button sx={iconButtonStyles.iconButton}>
                  <HelpRoundedIcon fontSize="small" />
                </Button>
              </Tooltip>
            )}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth required={isUSA} disabled={isCAN}>
                <InputLabel id="contact-type-label">Type</InputLabel>
                <Select
                  id="idDocumentType"
                  name="idDocumentType"
                  label="Type"
                  error={showErrors && !isFieldValid.idDocType}
                  value={identifyPersonInfo.profile.idDocuments[0].type as string}
                  onChange={handleIDTypeChange}
                >
                  {Object.keys(idDocumentsTypes).map((key) => (
                    <MenuItem key={key} value={key}>
                      {idDocumentsTypes[key]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormControl fullWidth>
                <TextField
                  required={isUSA}
                  disabled={isCAN}
                  name="idDocumentnumber"
                  id="idDocumentNumber"
                  label="Number"
                  variant="outlined"
                  error={showErrors && !isFieldValid.idDocNumber}
                  value={identifyPersonInfo.profile.idDocuments[0].number}
                  onChange={handleIDDocNumberChange}
                  helperText={
                    showErrors && !isFieldValid.idDocNumber
                      ? "Invalid ID Number"
                      : " "
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Autocomplete
                disabled={isCAN || !isIdStateRequired}
                options={stateList.USA}
                id="idDocumentstateProvince"
                value={identifyPersonInfo.profile.idDocuments[0].stateProvince}
                onChange={(e, v) =>
                  updateIdentifyPersonInfo((p) => {
                    p.profile.idDocuments[0].stateProvince =
                      v as StateProvinceCode;
                  })
                }
                renderInput={(params) => (
                  <TextField
                    error={showErrors && !isFieldValid.idDocState}
                    {...params}
                    name="idDocumentstateProvince"
                    value={
                      identifyPersonInfo.profile.idDocuments[0].stateProvince
                    }
                    label="State"
                    required={isUSA && isIdStateRequired}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl
                fullWidth
                disabled={isCAN || !isIdSCountryRequired}
                required={isUSA && isIdSCountryRequired}
              >
                <InputLabel id="country-label">Country</InputLabel>
                <Select
                  id="idDocumentCountry"
                  name="idDocumentCountry"
                  label="Country"
                  error={showErrors && !isFieldValid.idDocCountry}
                  value={
                    identifyPersonInfo.profile.idDocuments[0].country as Country
                  }
                  onChange={handleUpdateIdentifyPersonInfo(
                    (p, v) => (p.profile.idDocuments[0].country = v),
                  )}
                >
                  {["USA"].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        {/* Residence:*/}
        <Box component="section" sx={styles.boxContainer}>
          <Typography variant="subtitle1" sx={styles.boxSubTitle}>
            <b>Residential Address</b>
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                id="line1"
                name="line1"
                label="Line 1"
                variant="outlined"
                error={showErrors && !isFieldValid.addressLine1}
                value={identifyPersonInfo.residence.address.line1}
                onChange={handleUpdateIdentifyPersonInfo(
                  (p, v) => (p.residence.address.line1 = v),
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                id="city"
                name="city"
                label="City"
                variant="outlined"
                error={showErrors && !isFieldValid.addressCity}
                value={identifyPersonInfo.residence.address.city}
                onChange={handleUpdateIdentifyPersonInfo(
                  (p, v) => (p.residence.address.city = v),
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                fullWidth
                id="stateProvince"
                options={countryCode === "USA" ? stateList.USA : stateList.CAN}
                value={identifyPersonInfo.residence.address.stateProvince}
                onChange={handleStateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="State"
                    name="stateProvince"
                    error={showErrors && !isFieldValid.addressStateProvince}
                    value={identifyPersonInfo.residence.address.stateProvince}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                id="postalCode"
                name="postalCode"
                label="Postal Code"
                variant="outlined"
                error={showErrors && !isFieldValid.addressPotalCode}
                value={identifyPersonInfo.residence.address.postalCode}
                onChange={handlePostalCodeChange}
                helperText={
                  showErrors && !isFieldValid.addressPotalCode
                    ? "Invalid Postal Code"
                    : " "
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CountryPickerComponent
                required={true}
                countryCode={countryCode}
                setCountryCode={handleCountryCodeChange}
                fromItemsMenu={false}
                countryList={["USA", "CAN"]}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Contact:*/}
        <Box component="section" sx={styles.boxContainer}>
          <Typography variant="subtitle1" sx={styles.boxSubTitle}>
            <b>Phone</b>
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={2}>
              <Autocomplete
                fullWidth
                autoHighlight
                id="country-code-phone"
                options={phoneCountryCodes}
                getOptionLabel={(option) => `+${option.phone}`}
                onChange={(e, v) =>
                  updateIdentifyPersonInfo((p) => {
                    p.contacts.phones[0].countryCode = v?.phone as string;
                  })
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      alt=""
                    />
                    +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    error={showErrors && !isFieldValid.phoneCountryCode}
                    {...params}
                    required
                    label="Code"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="number"
                name="number"
                label="Number"
                fullWidth
                variant="outlined"
                error={showErrors && !isFieldValid.phoneNumber}
                value={identifyPersonInfo.contacts.phones[0].number}
                onChange={handlePhoneNumberChange}
                helperText={
                  showErrors && !isFieldValid.phoneNumber
                    ? "Invalid Phone Number"
                    : " "
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth required>
                <InputLabel id="contact-type-label">Phone Type</InputLabel>
                <Select
                  id="contactType"
                  name="contact type"
                  label="Contact Type"
                  error={showErrors && !isFieldValid.phoneType}
                  value={identifyPersonInfo.contacts.phones[0].type}
                  onChange={handleUpdateIdentifyPersonInfo(
                    (p, v) => (p.contacts.phones[0].type = v),
                  )}
                >
                  {Object.keys(phoneTypes).map((key) => (
                    <MenuItem key={key} value={key}>
                      {phoneTypes[key]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Typography variant="subtitle1" sx={{ mb: 2, mt: 1 }}>
            <b>Email</b>
          </Typography>
          <Grid container spacing={2}>
            {/* contacts */}
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                required
                id="email"
                name="email"
                label="Email"
                type="email"
                variant="outlined"
                error={showErrors && !isFieldValid.emailAddress}
                value={identifyPersonInfo.contacts.emails[0].address}
                onChange={handleUpdateIdentifyPersonInfo(
                  (p, v) => (p.contacts.emails[0].address = v),
                )}
                helperText={
                  showErrors && !isFieldValid.emailAddress
                    ? "Invalid Email Address"
                    : " "
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth required>
                <InputLabel id="email-type-label">Email Type</InputLabel>
                <Select
                  id="emailType"
                  name="Email type"
                  label="Email Type"
                  variant="outlined"
                  error={showErrors && !isFieldValid.emailType}
                  value={identifyPersonInfo.contacts.emails[0].type}
                  onChange={handleUpdateIdentifyPersonInfo(
                    (p, v) => (p.contacts.emails[0].type = v),
                  )}
                >
                  {Object.keys(emailTypes).map((key) => (
                    <MenuItem key={key} value={key}>
                      {emailTypes[key]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={styles.dialogActionsContainer}>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          type="button"
          disabled={showErrors && !isFormValid}
          onClick={handleIdentifyPerson}
        >
          Identify Person
        </Button>
      </DialogActions>
    </>
  );

  const renderSubscribeSuccessfulDialogContents = () => (
    <>
      <DialogContent>
        <Typography
          variant="subtitle1"
          alignItems={"center"}
          sx={styles.dialogMessage}
        >
          <CheckCircleOutlineIcon sx={styles.checkCircleOutlineIcon} />
          Successfully Identifying Person! Click to view/modify this person's
          information.
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={8} sx={{ ml: 3 }}>
            {identifyPersonId && retrieveSubscriber(identifyPersonId)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.dialogActionsContainer}>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button variant="contained" type="button" onClick={handleNewForm}>
          Identify Another Person
        </Button>
      </DialogActions>
    </>
  );

  return (
    <>
      <Button size="large" onClick={handleClickOpen}>
        <Card sx={styles.cardContainer}>
          <Typography sx={styles.actionCardText}>{dialogTitle}</Typography>
        </Card>
      </Button>
      <Dialog open={open} fullWidth={true} maxWidth="md">
        <DialogTitle textAlign={"left"}>{dialogTitle}</DialogTitle>
        {identifyPersonInfo &&
          !identifyPersonId &&
          renderNewFormDialogContents()}
        {identifyPersonInfo &&
          identifyPersonId &&
          renderSubscribeSuccessfulDialogContents()}
      </Dialog>
    </>
  );
};

export default IdentifyPerson;

import React, { FC } from "react";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { navigate } from "gatsby";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import { styles } from "../styles/textSearchStyles";
import {
  OrgResult,
  PersonResult,
  TextSearchProps,
} from "../dataTypes/search/textSearchTypes";
import { Address } from "../dataTypes/person/personTypes";

const TextSearchResultsComponent: FC<TextSearchProps> = ({ data }) => {
  const entityLinks = {
    memberId: "/ManageMember/retrieveMember?memberID=",
    personId: "/ManagePerson/retrievePerson?personID=",
    organizationId: "/ManageOrganization/retrieveOrganization/?orgID=",
    cardId: "/ManageCard/retrieveCard?CardID=",
    accountId: "/ManageAccount/retrieveAccount?accountID=",
  };

  const buttonLabels = {
    memberId: "Member",
    personId: "Person",
    organizationId: "Organization",
    cardId: "Card",
    accountId: "Account",
  };

  const getAddressString = (data: Address) => {
    const adressArray: Address[] = [];

    for (let text in data) {
      if (data[text] !== "") adressArray.push(data[text]);
    }

    return adressArray.length > 0
      ? adressArray.join(" ")
      : "- No location found -";
  };

  const orgCard = (card: OrgResult) => {
    const { address } = card;
    const addressData: Address = {
      city: address?.city || "",
      state: address?.state || "",
      postalCode: address?.postalCode || "",
      country: address?.country || "",
    };

    return (
      <Grid item>
        <Paper elevation={3} sx={styles.itemContainer}>
          <BusinessIcon sx={styles.photoIcon} />
          <Container
            disableGutters
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Container disableGutters>
              <Typography sx={styles.name}>{card?.organizationName}</Typography>
              <Typography sx={styles.address}>
                {getAddressString(addressData)}
              </Typography>
            </Container>
            <Button
              type="button"
              variant="contained"
              disabled={card?.organizationId === ""}
              onClick={() =>
                navigate(`${entityLinks.organizationId}${card?.organizationId}`)
              }
              sx={{ m: 1 }}
            >
              {buttonLabels.organizationId}
            </Button>
          </Container>
        </Paper>
      </Grid>
    );
  };

  const personCard = (card: PersonResult) => {
    const { identities, memberships, personId, lastName, firstName } = card;
    const addressData = identities
      ? {
          city: identities[0]?.city || "",
          state: identities[0]?.stateProvince || "",
          country: identities[0]?.country || "",
        }
      : {};
    const profileButtons = memberships
      ? {
          cardId: memberships[0]?.cardNumber || "",
          memberId: memberships[0]?.memberId || "",
          personId: personId || "",
          accountId: memberships[0]?.accountId || "",
        }
      : { personId: personId || "" };

    return (
      <Grid item>
        <Paper elevation={3} sx={styles.itemContainer}>
          <PersonIcon sx={styles.photoIcon} />
          <Container
            disableGutters
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Typography
              sx={styles.name}
            >{`${firstName || ""} ${lastName || ""}`}</Typography>
            <Typography sx={styles.address}>
              {getAddressString(addressData)}
            </Typography>
            <Grid container>
              {Object.keys(profileButtons).map((key) => (
                <Grid item sx={{ m: 1 }}>
                  <Button
                    type="button"
                    variant="contained"
                    disabled={profileButtons[key] === ""}
                    onClick={() =>
                      navigate(`${entityLinks[key]}${profileButtons[key]}`)
                    }
                    sx={styles.button}
                  >
                    {buttonLabels[key]}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Paper>
      </Grid>
    );
  };

  return (
    <Grid container sx={styles.commonContainer}>
      {data.map((card: PersonResult & OrgResult) =>
        card.organizationId ? orgCard(card) : personCard(card)
      )}
    </Grid>
  );
};

export default TextSearchResultsComponent;

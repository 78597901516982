import { PublicClientApplication, Configuration } from '@azure/msal-browser';
import authConfig from '../config/app-config.json';

interface AuthConfig {
    authClientId: string;
    authTenantURL: string;
}

const authSettings: AuthConfig = authConfig;

const msalConfig: Configuration = {
    auth: {
        clientId: authSettings.authClientId,
        authority: authSettings.authTenantURL,
        redirectUri: typeof window !== "undefined" ? window.location.origin : `/`,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: 'sessionStorage',
    },
};

export const msalInstance = new PublicClientApplication(msalConfig);

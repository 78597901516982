exports.components = {
  "component---src-pages-alert-tsx": () => import("./../../../src/pages/alert.tsx" /* webpackChunkName: "component---src-pages-alert-tsx" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-indentify-person-identify-person-tsx": () => import("./../../../src/pages/IndentifyPerson/identifyPerson.tsx" /* webpackChunkName: "component---src-pages-indentify-person-identify-person-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manage-account-retrieve-account-js": () => import("./../../../src/pages/ManageAccount/retrieveAccount.js" /* webpackChunkName: "component---src-pages-manage-account-retrieve-account-js" */),
  "component---src-pages-manage-card-retrieve-card-tsx": () => import("./../../../src/pages/ManageCard/retrieveCard.tsx" /* webpackChunkName: "component---src-pages-manage-card-retrieve-card-tsx" */),
  "component---src-pages-manage-member-retrieve-member-tsx": () => import("./../../../src/pages/ManageMember/retrieveMember.tsx" /* webpackChunkName: "component---src-pages-manage-member-retrieve-member-tsx" */),
  "component---src-pages-manage-organization-open-business-account-tsx": () => import("./../../../src/pages/ManageOrganization/OpenBusinessAccount.tsx" /* webpackChunkName: "component---src-pages-manage-organization-open-business-account-tsx" */),
  "component---src-pages-manage-organization-register-organization-tsx": () => import("./../../../src/pages/ManageOrganization/registerOrganization.tsx" /* webpackChunkName: "component---src-pages-manage-organization-register-organization-tsx" */),
  "component---src-pages-manage-organization-retrieve-organization-tsx": () => import("./../../../src/pages/ManageOrganization/retrieveOrganization.tsx" /* webpackChunkName: "component---src-pages-manage-organization-retrieve-organization-tsx" */),
  "component---src-pages-manage-person-retrieve-person-tsx": () => import("./../../../src/pages/ManagePerson/retrievePerson.tsx" /* webpackChunkName: "component---src-pages-manage-person-retrieve-person-tsx" */),
  "component---src-pages-manage-sale-order-retrieve-order-js": () => import("./../../../src/pages/ManageSaleOrder/retrieveOrder.js" /* webpackChunkName: "component---src-pages-manage-sale-order-retrieve-order-js" */),
  "component---src-pages-search-retrieve-search-tsx": () => import("./../../../src/pages/Search/retrieveSearch.tsx" /* webpackChunkName: "component---src-pages-search-retrieve-search-tsx" */),
  "component---src-pages-subscriber-person-subscriber-person-tsx": () => import("./../../../src/pages/SubscriberPerson/subscriberPerson.tsx" /* webpackChunkName: "component---src-pages-subscriber-person-subscriber-person-tsx" */)
}


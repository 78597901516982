// ProfileComponent.js
import React, { useState } from "react";
import { TextField, Typography, Paper } from "@mui/material";
import { navigate } from "gatsby";

const AccountsComponent = ({ accountsDetails }) => {
  const [accounts] = useState(accountsDetails);

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Accounts Information
      </Typography>
      {accounts?.map((account, index) => (
        <Paper key={index} elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Account {index + 1}
          </Typography>
          <TextField
            margin="normal"
            fullWidth
            name="accountId"
            label="Account ID"
            variant="outlined"
            value={account?.accountId}
            disabled={true}
            // 			 onClick={() =>
            //   navigate(`../../retrieveAccount?accountID=${account.accountId}`)            }
          />
          <TextField
            margin="normal"
            fullWidth
            name="legacyAccountId"
            label="Legacy Account Id"
            variant="outlined"
            value={account?.legacyAccountId}
            disabled={true}
          />
          <TextField
            margin="normal"
            fullWidth
            name="country"
            label="Country"
            variant="outlined"
            value={account?.country}
            disabled={true}
          />
          <TextField
            margin="normal"
            fullWidth
            name="type"
            label="Type"
            variant="outlined"
            value={account?.type || ""}
            disabled={true}
          />
          <TextField
            margin="normal"
            fullWidth
            name="tier"
            label="Tier"
            variant="outlined"
            value={account?.tier || ""}
            disabled={true}
          />
          <TextField
            margin="normal"
            fullWidth
            name="expirationDate"
            label="Expiration Date"
            variant="outlined"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={
              account?.expirationDate
                ? account.expirationDate.split("T")[0]
                : ""
            }
            disabled={true}
          />
          <TextField
            margin="normal"
            fullWidth
            name="status"
            label="Status"
            variant="outlined"
            value={account?.status || ""}
            disabled={true}
          />
        </Paper>
      ))}
    </Paper>
  );
};

export default AccountsComponent;

import React, { FC, useState } from "react";
import { Container, Snackbar, Alert } from "@mui/material";
import RegisterOrganizationComponent from "../../components/OrganizationComponents/RegisterOrganizationComponent";
import ErrorPopupComponent from "../../components/ErrorPopupComponent";
import authConfig from "../../config/app-config.json";
import useAxiosInstance from "../../service/useAxiosInstance";
import { msalInstance } from "../../service/msalConfig";
import { axiosPostPromise } from "../../service/axiosApi";
import { navigate } from "gatsby";
import { OrgDetailsReponse } from "../../dataTypes/organization/organization-details";
type SeverityType = 'error' | 'warning' | 'info' | 'success';

const baseURL = authConfig.manageOrganizationBaseURL;
const RegisterOrganization: FC = () => {
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [errorDetails, setErrorDetails] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<SeverityType>("error");
  const handleErrorClose = () => setErrorPopupOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const axiosInstance = useAxiosInstance(baseURL, msalInstance);
  const handleUpdate = async (updatePath: string, updateData: OrgDetailsReponse, onSuccessMessage: string) => {
    const fullUpdateData = {
      ...updateData,
    };
    axiosPostPromise(axiosInstance, updatePath, fullUpdateData, {
      "Content-Type": "application/json",
      "country-code": "USA",
    })
      .then((data) => {
        if (data.validationErrors || data.code) {
          setErrorDetails(data);
          setErrorPopupOpen(true);
        } else {
          setSnackbarMessage(onSuccessMessage);
          setSnackbarOpen(true);
          setSeverity("success");
          setTimeout(
            () =>
              navigate(
                `../../ManageOrganization/retrieveOrganization/?orgID=${data.organizationId}`
              ),
            2000
          );
        }
      })
      .catch((error) => {
        console.error("Update error:", error);
        setErrorDetails({
          message: "An error occurred while updating details.",
        });
        setErrorPopupOpen(true);
      });
  };

  return (
    <Container disableGutters sx={{ justifyContent: "center" }}>

      <RegisterOrganizationComponent
        onUpdate={(data) =>
          handleUpdate(
            "register-organization",
            data,
            "Organization Registered Successfully!"
          )
        }
      />
      <ErrorPopupComponent
        open={errorPopupOpen}
        onClose={handleErrorClose}
        errorDetails={errorDetails}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};
export default RegisterOrganization;

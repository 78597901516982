import React, { FC, useState } from "react";
import { Alert, AlertColor, Container, Snackbar } from "@mui/material";
import ErrorPopupComponent from "../components/ErrorPopupComponent";
import { AlertContext } from "../utils/context";
import {
  AlertProps,
  UpdateSuccessProps,
  ErrorResponse,
  TextSearchResult,
  updateCardEligibleSuccessProps,
} from "../dataTypes/alert/alertTypes";

const AlertComponent: FC<AlertProps> = ({ children }) => {
  const [errorPopupOpen, setErrorPopupOpen] = useState<Boolean>(false);
  const [errorDetails, setErrorDetails] = useState<{ message: string }>();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>();
  const [severity, setSeverity] = useState<AlertColor>("success");

  const handleErrorClose = () => setErrorPopupOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const updateTextSearchSuccess = (data: TextSearchResult) => {
    if (data.totalCount > 0) {
      setSnackbarMessage("Success!");
      setSeverity("success");
      setSnackbarOpen(true);
    } else if (data.totalCount === 0) {
      setSnackbarMessage("No results found!");
      setSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const updateError = (error: ErrorResponse) => {
    console.error("Error:", error);
    const message =
      error?.response?.data?.message ||
      `An error occurred while updating details. ${error}`;
    setErrorDetails({ message });
    setErrorPopupOpen(true);
  };

  const updateCardEligibleSuccess = ({
    data,
    buttons,
    checked,
  }: updateCardEligibleSuccessProps) => {
    if (data.eligible) {
      setSnackbarMessage(
        `Card is eligible to shop ${buttons[checked].channel}!`
      );
      setSeverity("success");
    } else {
      setSnackbarMessage(`Card is not eligible to shop ${buttons[checked].channel} 
    because ${data.denialReasonCode}, ${data.denialReasonDescription}`);
      setSeverity("error");
    }
    setSnackbarOpen(true);
  };

  const updateSuccess = ({ data, refresh, message }: UpdateSuccessProps) => {
    if (data.validationErrors || data.code) {
      setSnackbarMessage(data.message || "Enter valid card ID!");
      setSeverity("error");
      setSnackbarOpen(true);
    } else {
      setSeverity("success");
      setSnackbarMessage(message || "Success!");
      setSnackbarOpen(true);
      //We have data.id when we just fetched successfully, so no need to fetch again.
      //We get data.transactionState after replacing card, so no need to fetch again.
      //We get data.message after updating member.
      //TODO: better way to determin whether to refresh or not.
      if (refresh && ((!data.id && !data.transactionState) || data.message)) {
        setTimeout(() => {
          refresh();
        }, 2000);
      }
    }
  };

  const fireAlert = (func: string, data: {}) => {
    eval(func)(data);
  };

  return (
    <Container disableGutters>
      <AlertContext.Provider value={fireAlert}>
        {children}
      </AlertContext.Provider>
      <ErrorPopupComponent
        open={errorPopupOpen}
        onClose={handleErrorClose}
        errorDetails={errorDetails}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AlertComponent;

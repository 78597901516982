import React, { FC, useState } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
} from "@mui/material";
import { navigate } from "gatsby";
import { ReplaceCardDialogProps } from "../../dataTypes/cardComponentsTypes";
import { RuntimeStatus, TransactionState } from "../../dataTypes/cardTypes";

const ReplaceCardDialog: FC<ReplaceCardDialogProps> = ({
  open,
  handleDialogClose,
  data,
  error,
  onRefresh,
  refreshStatus,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const onClose = (e: React.MouseEvent) => {
    handleDialogClose(e);
    setShowDetails(false);
  };

  const getColor = (text: RuntimeStatus | TransactionState) => {
    switch (text) {
      case "Pending":
      case "Running":
        return "orange";
      case "Error":
        return "red";
      case "Success":
        return "green";
      default:
        return "black";
    }
  };

  let newCardNumber = "";

  const getNewCardNumber = () => {
    if (
      refreshStatus?.runtimeStatus === "Completed" &&
      refreshStatus?.transactionState === "Success"
    ) {
      const activities = refreshStatus?.completeActivities;
      if (activities) {
        for (let a of activities) {
          if (
            a.activityName === "ActivateCardActivity" &&
            a.activityStatus === 200
          )
            return (newCardNumber = a.cardNumber);
        }
      }
    }
  };

  const initialStatus: TransactionState = data?.transactionState;

  const refreshedStatus =
    refreshStatus?.runtimeStatus !== "Completed"
      ? refreshStatus?.runtimeStatus
      : refreshStatus?.transactionState;

  const showDetailsComponent = (
    <Typography
      lineHeight={2}
      onClick={() => setShowDetails(!showDetails)}
      style={{ color: "#808080", textDecoration: "underline" }}
    >
      {showDetails ? "Hide details" : "Show details"}
    </Typography>
  );

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>Replace Card</DialogTitle>
      <DialogContent>
        {data && !refreshStatus ? (
          <>
            <Typography lineHeight={2} color={getColor(initialStatus)}>
              {initialStatus?.toUpperCase()}
            </Typography>
            {showDetailsComponent}
            {showDetails && (
              <>
                <Typography lineHeight={2}>
                  Transaction id: {data?.transactionId}
                </Typography>
                <Typography lineHeight={2}>{data?.message}</Typography>
              </>
            )}
          </>
        ) : (
          <>
            <Typography lineHeight={2} color={getColor(refreshedStatus)}>
              {refreshedStatus}
            </Typography>
            {refreshStatus?.transactionState === "Success" && (
              <Grid display="flex" spacing={2}>
                <Grid item>
                  <Typography lineHeight={2}>{"New card number: "}</Typography>
                </Grid>
                <Grid item sx={{ ml: 1 }}>
                  <Typography
                    lineHeight={2}
                    style={{ color: "blue", textDecoration: "underline" }}
                    onClick={(e) => {
                      onClose(e);
                      navigate(
                        `/ManageCard/retrieveCard?CardID=${newCardNumber}`
                      );
                    }}
                  >
                    {getNewCardNumber()}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {showDetailsComponent}
            {showDetails && (
              <>
                <Typography lineHeight={2}>
                  Transaction id: {refreshStatus?.transactionId}
                </Typography>
                {refreshStatus?.runtimeStatus === "Completed" && (
                  <Typography lineHeight={2}>
                    Transaction state: {refreshStatus?.transactionState}
                  </Typography>
                )}
                {refreshStatus?.errorMessage && (
                  <Typography lineHeight={2}>
                    Error message: {refreshStatus?.errorMessage}
                  </Typography>
                )}
              </>
            )}
          </>
        )}
        {error && (
          <Typography
            lineHeight={2}
            color="red"
          >{`Error: ${error?.message}`}</Typography>
        )}
        <Grid display="flex" justifyContent="flex-end">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={refreshStatus?.runtimeStatus === "Completed"}
            sx={{ mt: 1 }}
            onClick={onRefresh}
          >
            {"Refresh"}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ReplaceCardDialog;

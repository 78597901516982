import {
  Button,
  Paper,
  TextField,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import authConfig from "../../config/app-config.json";
import { msalInstance } from "../../service/msalConfig";
import useAxiosInstance from "../../service/useAxiosInstance";
import { axiosPostPromiseWithConfig } from "../../service/axiosApi";
import { HandleImmerUpdateType, ImmerUpdateType, useImmerUpdaters } from "../../utils/useImmerUpdaters";
import stateList from "../../data/stateList.json";
import { styles } from "../../styles/actionCardsStyles";
import { Address, Residence } from "../../dataTypes/person/personTypes";
import { PersonViewResponse } from "../../dataTypes/person/personRequestTypes";
import { Audited } from "../../dataTypes/shared/sharedRequestTypes";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const addressValidationUrl = authConfig.addressValidationUrl;
//   const immigratePersonProcessUrl = authConfig.immigratePersonProcessUrl; @ Todo List

const isNotEmptyString = (str: string | undefined | null) =>
  str != null && str.trim().length > 0;
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
const normalizeCountry = (country: string | null | undefined) =>
  country === "US" ? "USA" : "CAN";
const normalizeInput = (text: string | null | undefined) =>
  text === undefined || text === null ? "" : text.trim();
const compareNormalizedInputs = (textA: string | null | undefined, textB: string | null | undefined) =>
  normalizeInput(textA) === normalizeInput(textB);

const compareAddresses = (a: Address, b: Address ) => {
  const validatedCountry = normalizeCountry(b?.country);
  return (
    compareNormalizedInputs(a?.line1, b?.line1) &&
    compareNormalizedInputs(a?.line2, b?.line2) &&
    compareNormalizedInputs(a?.line3, b?.line3) &&
    compareNormalizedInputs(a?.city, b?.city) &&
    compareNormalizedInputs(a?.postalCode, b?.postalCode) &&
    compareNormalizedInputs(a?.country, validatedCountry)
  );
};

interface PersonAddressProps {
    personInfo: Audited<Residence>;
    personDetails: PersonViewResponse | null;
    isProtectedPerson: boolean;
    onUpdate: (personInformation: Audited<Residence>) => void;
    handleSearch: (id: string) => void;
  }
  
  interface AddressInfoProps {
    personInfo: Residence;
    updatePersonInfo: ImmerUpdateType<Residence>;
    handleUpdatePersonInfo: HandleImmerUpdateType<Residence>;
    showErrors: boolean;
    isFieldValid: { [key: string]: boolean };
  }
  
  interface PersonalInfoProps {
    personInfo: Address;
  }

const CurrentAddressInfo = ({ personInfo }: PersonalInfoProps) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="line1"
          name="addressLine1"
          label="Line 1"
          variant="outlined"
          value={personInfo.line1}
          disabled
        />
      </Grid>
      {personInfo.line2 && personInfo.line2 !== "" && (
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="line2"
            name="addressLine2"
            label="Line 2"
            variant="outlined"
            value={personInfo.line2}
            disabled
          />
        </Grid>
      )}
      {personInfo.line3 && personInfo.line3 !== "" && (
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="line3"
            name="addressLine3"
            label="Line 3"
            variant="outlined"
            value={personInfo.line3}
            disabled
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          id="city"
          name="city"
          label="City"
          variant="outlined"
          value={personInfo.city}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          disabled
          fullWidth
          id="stateProvince"
          name="stateProvince"
          label="State"
          variant="outlined"
          value={personInfo.stateProvince}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          disabled
          fullWidth
          id="postalCode"
          name="postalCode"
          label="Postal Code"
          variant="outlined"
          value={personInfo.postalCode}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          disabled
          id="country"
          name="country"
          label="Country"
          value={personInfo.country}
          defaultValue="USA"
        />
      </Grid>
    </Grid>
  );
};

const EnteredAddressInfo = ({
  personInfo,
  updatePersonInfo,
  handleUpdatePersonInfo, 
  showErrors,
  isFieldValid,
}: AddressInfoProps) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="line1"
          name="addressLine1"
          label="Line 1"
          variant="outlined"
          size="small"
          value={personInfo.address.line1}
          error={showErrors && !isFieldValid.addressLine1}
          onChange={handleUpdatePersonInfo((p, v) => (p.address.line1 = v))}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="line2"
          name="addressLine2"
          label="Line 2"
          variant="outlined"
          size="small"
          value={personInfo.address.line2}
          onChange={handleUpdatePersonInfo((p, v) => (p.address.line2 = v))}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="line3"
          name="addressLine3"
          label="Line 3"
          variant="outlined"
          size="small"
          value={personInfo.address.line3}
          onChange={handleUpdatePersonInfo((p, v) => (p.address.line3 = v))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          id="city"
          name="city"
          label="City"
          variant="outlined"
          size="small"
          error={showErrors && !isFieldValid.addressCity}
          value={personInfo.address.city}
          onChange={handleUpdatePersonInfo((p, v) => (p.address.city = v))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          fullWidth
          id="stateProvince"
          size="small"
          options={
            personInfo.address.country === "USA" ? stateList.USA : stateList.CAN
          }
          value={personInfo.address.stateProvince}
          onChange={(e, v) =>
            updatePersonInfo((p) => (p.address.stateProvince = v as string))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="State"
              name="stateProvince"
              error={showErrors && !isFieldValid.addressState}
              value={personInfo.address.stateProvince}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          id="postalCode"
          name="postalCode"
          label="Postal Code"
          variant="outlined"
          size="small"
          error={showErrors && !isFieldValid.addressPortal}
          value={personInfo.address.postalCode}
          onChange={handleUpdatePersonInfo(
            (p, v) => (p.address.postalCode = v),
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth required variant="outlined">
          <InputLabel id="country-label">Country</InputLabel>
          <Select
            id="country"
            name="country"
            label="Country"
            size="small"
            value={personInfo.address.country}
            defaultValue="USA"
            onChange={handleUpdatePersonInfo(
                (p, v) => (p.address.country = v),
              )}
          >
            {[personInfo.address.country].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

const ValidatedAddressInfo = ({ personInfo }) => {
  const validatedCountry = normalizeCountry(personInfo?.country);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          disabled
          id="line1"
          name="addressLine1"
          label="Line 1"
          variant="outlined"
          size="small"
          color="warning"
          value={personInfo?.line1 || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          disabled
          id="line2"
          name="addressLine2"
          label="Line 2"
          variant="outlined"
          size="small"
          value={personInfo?.line2 || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          disabled
          id="validated-line3"
          name="validated-addressLine3"
          label="Line 3"
          variant="outlined"
          size="small"
          value={personInfo?.line3 || ""}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          disabled
          id="city"
          name="city"
          label="City"
          variant="outlined"
          size="small"
          value={personInfo?.city || ""}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          disabled
          fullWidth
          id="validated-stateProvince"
          name="validated-stateProvince"
          label="State"
          variant="outlined"
          size="small"
          value={personInfo?.stateProvince || ""}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          disabled
          id="validated-postalCode"
          name="validated-postalCode"
          label="Postal Code"
          variant="outlined"
          size="small"
          value={personInfo?.postalCode || ""}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          disabled
          fullWidth
          id="validated-country"
          name="validated-country"
          label="Country"
          variant="outlined"
          size="small"
          value={validatedCountry || ""}
        />
      </Grid>
    </Grid>
  );
};

const PersonAddress = ({
  personInfo,
  isProtectedPerson,
  onUpdate,
}: PersonAddressProps) => {
  const [personInformation, setPersonInformation] = useState(personInfo);
  const [updatePersonInfo, handleUpdatePersonInfo] = useImmerUpdaters(
    personInformation,
    setPersonInformation,
  );

  // const axiosInstanceImmigrate = useAxiosInstance(immigratePersonProcessUrl, msalInstance); @ Todo List
  const axiosInstanceValidateAddress = useAxiosInstance(
    addressValidationUrl,
    msalInstance,
  );
  const [open, setOpen] = useState(false);
  const [useSuggestedChecked, setUseSuggestedChecked] = useState(false);
  const [useCurrentChecked, setUseCurrentChecked] = useState(true);
  const [validatedAddress, setValidatedAddress] = useState<Address>(personInformation.address);
  const [updatedAddress, setUpdatedAddress] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const comparedAddress = compareAddresses(
    personInformation.address,
    validatedAddress,
  );
  const currentAddress = { ...personInfo.address };
  const headers = { 'Content-Type': 'application/json' };

  // Determine the country code based on the validated country value
  const validatedCountryCode = normalizeCountry(validatedAddress.country);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAndReload = async () => {
    await sleep(500);
    setOpen(false);
  };

 const handleValidateAddress = async () => {
    setIsValidating(true);
    await sleep(500);

    const invalidAddress = "^I[1-5]$";

    // Construct input address object, context and root object for validation request
    const inputAddressObj = {
      line: [
        personInformation.address.line1,
        personInformation.address.line2,
        personInformation.address.line3,
      ],
      city: personInformation.address.city,
      territory: personInformation.address.stateProvince,
      postalCode: personInformation.address.postalCode,
      country: personInformation.address.country,
      extension: { locality2: "" },
    };

    const contextObj = {
      messageName: "",
      timestamp: "",
      sendingApplication: "",
      trackingId: "",
      userId: "",
      localeCode:
        personInformation.address.country === "USA" ? "en-US" : "en-CA",
      applicationName: "",
    };

    const rootObject = {
      validate: {
        request: {
          context: contextObj,
          responseListIndicator: true,
          inputAddress: inputAddressObj,
          preferredScript: "",
        },
      },
    };

    try {
      const result = await axiosPostPromiseWithConfig(
        axiosInstanceValidateAddress,
        "",
        rootObject,
        { headers, responseType: "text" },
      );
      setValidatedAddress(result);
    } catch (error) {
      console.error(error);
    }
    setIsValidating(false);
   };

  // Open Dialog and validated the current address
  const handleChangeResidence = (e: React.MouseEvent) => {
    e.preventDefault();
    handleValidateAddress();
    setOpen(true);
  };

  const handleUseSuggestedAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setUseSuggestedChecked(e.target.checked);
    setUseCurrentChecked(false);
    // Validate the current address if entered !== Validated Addresses
    if (!comparedAddress) handleValidateAddress();
  };

  const handleUseEnteredAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setUseCurrentChecked(e.target.checked);
    setUseSuggestedChecked(false);
  };

  //object to track field validity
  const isFieldValid = {
    addressLine1: isNotEmptyString(personInformation.address.line1),
    addressCity: isNotEmptyString(personInformation.address.city),
    addressState: isNotEmptyString(personInformation.address.stateProvince),
    addressPortal: isNotEmptyString(personInformation.address.postalCode),
  };
  // Check if all fields are valid (i.e., not empty)
  const isFormValid = Object.keys(isFieldValid).every((k) => isFieldValid[k]);

  const handleConfirmResidenceChange = (e: React.MouseEvent) => {
    e.preventDefault();

    if (isFormValid && !comparedAddress) handleValidateAddress();

      if (useSuggestedChecked) {
        // Remove the 'statusCode' property from 'validatedAddress'
        delete validatedAddress.statusCode;
        // Update person information with the adjusted address
        const validateAddressPersonInfo = {
          ...personInformation,
          address: {
            ...validatedAddress,
            line2:
              validatedAddress.line2 === null
                ? (validatedAddress.line2 = "")
                : validatedAddress.line2,
            line3:
              validatedAddress.line3 === null
                ? (validatedAddress.line3 = "")
                : validatedAddress.line3,
            country: validatedCountryCode,
            status: personInformation.address.status,
            type: personInformation.address.type,
          },
        };
        onUpdate(validateAddressPersonInfo);
      } else {
        if (!isFormValid) {
          setShowErrors(true);
          return;
        }
        onUpdate(personInformation);
      }
      setUpdatedAddress(true);
    
  };

  const renderValidateAddressForm = () => (
    <>
      <DialogContent>
        <DialogContentText sx={{ mt: 1, mb: 2 }}>
          Please validate residential address.
        </DialogContentText>
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={8} md={6} sx={{ justifyContent: "center" }}>
            <Box
              component="section"
              sx={styles.boxContainer}
            >
              <Typography variant="subtitle1" sx={styles.boxSubTitle}>
                <Checkbox
                  checked={useCurrentChecked}
                  onChange={handleUseEnteredAddress}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <b>Use Current Address</b>
              </Typography>
              <EnteredAddressInfo
                personInfo={personInformation}
                updatePersonInfo={updatePersonInfo}
                handleUpdatePersonInfo={handleUpdatePersonInfo}
                showErrors={showErrors}
                isFieldValid={isFieldValid}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <Box
              component="section"
              sx={styles.validateAddressBoxContainer}
            >
              <Typography variant="subtitle1" sx={styles.boxSubTitle}>
                <Checkbox
                  checked={useSuggestedChecked}
                  onChange={handleUseSuggestedAddress}
                  inputProps={{ "aria-label": "controlled" }}
                  disabled={isValidating}
                />
                <b>Use Suggested Address</b>
              </Typography>

              {isValidating ? (
                <Grid container spacing={3}>
                  <Grid item xs={12} sx={styles.validateSpinner}
                  >
                    <CircularProgress />
                    <Typography variant="caption" sx={styles.boxSubTitle}>
                      <i>Validating...</i>
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <ValidatedAddressInfo
                  personInfo={validatedAddress}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.dialogActionsContainer}>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          type="button"
          onClick={handleValidateAddress}
          disabled={isValidating}
        >
          Validate Address
        </Button>
        <Button
          variant="contained"
          type="button"
          onClick={handleConfirmResidenceChange}
        >
          Confirm Address
        </Button>
      </DialogActions>
    </>
  );

  const renderUpdatedSuccess = () => (
    <>
      <DialogContent>
        <DialogContentText  
          sx={{ mt: 1, mb: 1, display: "flex", justifyContent: "center" }}
        >
          <CheckCircleOutlineIcon sx={styles.checkCircleOutlineIcon} />
          The residential address sucessfully updated.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
        <Button variant="outlined" onClick={handleCloseAndReload}>
          Close
        </Button>
      </DialogActions>
    </>
  );

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Residential Address
      </Typography>
      {/* Current Residence Address */}
      <CurrentAddressInfo personInfo={currentAddress} />

      {/* Change Residence Dialog */}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: 3 }}
        onClick={handleChangeResidence}
        disabled={isProtectedPerson}
      >
        Change Residence
      </Button>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={updatedAddress ? "sm" : "md"}
      >
        <DialogTitle textAlign={"left"}>Change Residence</DialogTitle>
        {/* Validate and revalidate address */}
        {!updatedAddress && renderValidateAddressForm()}

        {/* when confirm address and confirm Immigration success */}
        {updatedAddress && renderUpdatedSuccess()}

      </Dialog>
    </Paper>
  );
};

export default PersonAddress;
import { useMsal } from "@azure/msal-react";

export function useCurrentAccountInfo() {
  const { accounts } = useMsal();
  const account = accounts[0];
  if (account) {
    const username = account?.name?.split("[")[1].slice(0, -1);
    const warehouseLocation = parseInt(
      account?.idTokenClaims?.warehouseLocation as string,
      10,
    );
    return {
      username: username || "System",
      warehouseLocation: warehouseLocation || 99,
    };
  } else {
    return {
        username: "System",
        warehouseLocation: 99,
    }
  }
}

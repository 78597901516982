// ProfileComponent.js
import React, { useState } from "react";
import { TextField, Typography, Paper } from "@mui/material";

const MembersComponent = ({ membersDetails }) => {
	const [members] = useState(membersDetails);

	return (
		<Paper elevation={3} sx={{ p: 3, mb: 4 }}>
			<Typography variant="h6" sx={{ mb: 2 }}>
				Members Information
			</Typography>

			{members?.map((member, index) => (
				<Paper key={index} elevation={3} sx={{ p: 3, mb: 4 }}>
					<Typography variant="body1" sx={{ mb: 1 }}>
						Member {index + 1}
					</Typography>
					<TextField
						margin="normal"
						fullWidth
						name="memberId"
						label="Member ID"
						variant="outlined"
						value={member?.memberId}
						disabled={true}
					/>
					<TextField
						margin="normal"
						fullWidth
						name="legacyMemberId"
						label="Legacy Member Id"
						variant="outlined"
						value={member?.legacyMemberId}
						disabled={true}
					/>
					<TextField
						margin="normal"
						fullWidth
						name="accountId"
						label="Account ID"
						variant="outlined"
						value={member?.accountId}
						disabled={true}
					/>
					<TextField
						margin="normal"
						fullWidth
						name="personId"
						label="person ID"
						variant="outlined"
						value={member?.personId || ""}
						disabled={true}
					/>
					<TextField
						margin="normal"
						fullWidth
						name="role"
						label="Role"
						variant="outlined"
						value={member?.role || ""}
						disabled={true}
					/>
					<TextField
						margin="normal"
						fullWidth
						name="status"
						label="Status"
						variant="outlined"
						value={member?.status || ""}
						disabled={true}
					/>
				</Paper>
			))}
		</Paper>
	);
};

export default MembersComponent;

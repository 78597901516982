import React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
const LoginSuccess = ({ accounts }) => {
  return (
    <Stack sx={{ width: "100%", mb: 1 }} spacing={0}>
      <Alert severity="success">
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            fontSize: "1rem",
          }}
        >
          <b>Login Successful</b> - Welcome,{" "}
          {accounts[0]?.name || accounts[0]?.username}!
        </Typography>
      </Alert>
    </Stack>
  );
};

export default LoginSuccess;

import React, { useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Paper,
  Card,
} from "@mui/material";
import authConfig from "../../config/app-config.json";
import useAxiosInstance from "../../service/useAxiosInstance";
import { generateUUID } from "../../utils/generateUUID";
import { useCurrentAccountInfo } from "../../utils/useCurrentAccountInfo";
import { msalInstance } from "../../service/msalConfig";
import { axiosPostPromise } from "../../service/axiosApi";
import { navigate } from "gatsby";
import { produce } from "immer";
import { validateEmail } from "../../utils/validateEmailFormat";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CountryPickerComponent from "../../components/CountryPickerComponent";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { useImmerUpdaters } from "../../utils/useImmerUpdaters";
import { styles } from "../../styles/actionCardsStyles";
import { IdentifySubscriberRequest } from "../../dataTypes/person/personRequestTypes";
import identifySubscriberDefault from "../../data/identifySubscriberDefault.json";
import { Error } from "../../dataTypes/alert/alertTypes";

const baseURLV3 = authConfig.basePersonUrlV3;
const isNotEmptyString = (str: string) => str != null && str.trim().length > 0;

const SubscribePerson = () => {
  const { username, warehouseLocation } = useCurrentAccountInfo();
  const axiosInstanceV3 = useAxiosInstance(baseURLV3, msalInstance);
  const [open, setOpen] = useState(false);
  const [subcribeFailed, setSubcribeFailed] = useState(false);
  const [subcribeFailedMessage, setSubcribeFailedMessage] = useState("");
  const [countryCode, setCountryCode] = useState("USA");
  const headers = { "country-code": countryCode };
  const [showErrors, setShowErrors] = useState(false);
  const [subscribeDetails, setSubscribeDetails] = useState("");
  const [subscribeInfo, setSubscribeInfo] = useState(identifySubscriberDefault);
  const [updateSubscribeInfo, _] = useImmerUpdaters(
    subscribeInfo,
    setSubscribeInfo,
  );
  let dialogTitle = "Identify Subscriber";

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setSubscribeInfo(identifySubscriberDefault);
    setSubcribeFailed(false);
    setSubcribeFailedMessage("");
    setSubscribeDetails("");
  };

  // Handle Error
  const handleError = (error: Error) => {
    const code = error.error.response.data.code;
    const entityId = error.error.response.data.entityId;

    let subscribeDetails = "";
    let message = "";

    if (code === "IdentifyUniqueSubscriberFailed") {
      message = "Identify Unique Subscriber Failed!";
    } else if (code === "SubscriberEmailExists") {
      message = "Email already exists!";
      if (entityId) {
        subscribeDetails = entityId;
      }
    } else {
      message = "Subscribe Failed!";
    }
    setSubscribeDetails(subscribeDetails);
    setSubcribeFailed(true);
    setSubcribeFailedMessage(message);
  };

  // Form Validity
  const isFieldValid = {
    emailAddress:
      isNotEmptyString(subscribeInfo.email) &&
      validateEmail(subscribeInfo.email),
  };
  const isFormValid = Object.keys(isFieldValid).every((k) => isFieldValid[k]);

  // handle Subcribe Person Data
  const handleSubscribePerson = async () => {
    if (!isFormValid) {
      setShowErrors(true);
      return;
    }
    const subscribeData: IdentifySubscriberRequest = {
      ...subscribeInfo,
      audit: {
        ...subscribeInfo.audit,
        createdByChannel: "Desktop",
        createdByUser: username,
        createdAtLocationNumber: warehouseLocation,
        mgloTransactionType: "MGLOUpdate",
        mgloTransactionId: generateUUID(),
        createdOnUtcDateTime: new Date().toISOString(),
      },
    };

    try {
      const result = await axiosPostPromise(
        axiosInstanceV3,
        "identify-subscriber",
        subscribeData,
        headers,
      );
      setSubscribeDetails(result.id);
    } catch (error) {
      console.error(error);
      handleError(error);
    }
  };

  const handleNewForm = () => {
    setSubscribeInfo(identifySubscriberDefault);
    setSubcribeFailedMessage("");
    setSubcribeFailed(false);
    setSubscribeDetails("");
  };

  const retrieveSubscriber = (id: string) => {
    return (
      <Paper elevation={2}>
        <Button
          fullWidth
          variant="outlined"
          sx={styles.personWithParams}
          onClick={() =>
            navigate(
              `/ManagePerson/retrievePerson?personID=${id}&countryCode=${countryCode}`,
            )
          }
        >
          <AccountCircleIcon fontSize="large" />
          {id}
          <ArrowForwardIosIcon />
        </Button>
      </Paper>
    );
  };

  const renderNewFormDialogContents = () => (
    <>
      <DialogContent>
        <DialogContentText sx={{ mt: 1, mb: 2 }}>
          To subscribe, please enter email address
        </DialogContentText>
        <Grid
          container
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={3} sm={3}>
            <CountryPickerComponent
              required={true}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              fromItemsMenu={false}
              countryList={["USA", "CAN"]}
            />
          </Grid>
          <Grid item xs={12} sm={9} maxWidth={500}>
            <TextField
              autoFocus
              fullWidth
              required
              id="email"
              name="email"
              label="Email"
              type="email"
              variant="outlined"
              error={showErrors && !isFieldValid.emailAddress}
              onChange={(e) =>
                updateSubscribeInfo((p) => (p.email = e.target.value))
              }
            />
          </Grid>
        </Grid>
        <hr />
        <Typography
          variant="subtitle2"
          sx={{ mb: 1 }}
          style={{ color: "#9e9e9e" }}
        >
          <i>Optional</i>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="firstlName"
              label="First Name"
              variant="outlined"
              id="firstName"
              onChange={(e) =>
                updateSubscribeInfo((p) => (p.firstName = e.target.value))
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="lastName"
              label="Last Name"
              variant="outlined"
              id="lastName"
              onChange={(e) =>
                updateSubscribeInfo((p) => (p.lastName = e.target.value))
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.dialogActionsContainer}>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          type="button"
          disabled={showErrors && !isFormValid}
          onClick={handleSubscribePerson}
        >
          Identify Subscriber
        </Button>
      </DialogActions>
    </>
  );

  const renderSubscribeSuccessfulDialogContents = () => (
    <>
      <DialogContent>
        <Typography
          variant="subtitle1"
          alignItems={"center"}
          sx={styles.dialogMessage}
        >
          <CheckCircleOutlineIcon sx={styles.checkCircleOutlineIcon} />
          Successfully subscribed! Click to view/modify this person's
          information.
        </Typography>
        {subscribeDetails && retrieveSubscriber(subscribeDetails)}
      </DialogContent>
      <DialogActions sx={styles.dialogActionsContainer}>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button variant="contained" type="button" onClick={handleNewForm}>
          Subcribe Another
        </Button>
      </DialogActions>
    </>
  );

  const renderSubscribeFailedEmailExistsDialogContents = () => (
    <>
      <DialogContent>
        <Typography
          variant="subtitle1"
          alignItems={"center"}
          sx={styles.dialogMessage}
        >
          <InfoOutlinedIcon sx={styles.infoOutlinedIcon} fontSize="medium" />
          {subcribeFailedMessage} Click to view/modify this person's
          information.
        </Typography>
        {subscribeDetails && retrieveSubscriber(subscribeDetails)}
      </DialogContent>
      <DialogActions sx={styles.dialogActionsContainer}>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button variant="contained" type="button" onClick={handleNewForm}>
          Subcribe Another
        </Button>
      </DialogActions>
    </>
  );

  const renderSubscribeFailedOtherDialogContents = () => (
    <>
      <DialogContent>
        <Typography
          variant="subtitle1"
          alignItems={"center"}
          sx={styles.dialogMessage}
        >
          <ErrorOutlineOutlinedIcon sx={styles.errorOutlineOutlinedIcon} />
          {subcribeFailedMessage}
        </Typography>
      </DialogContent>
      <DialogActions sx={styles.dialogActionsContainer}>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" type="button" onClick={handleNewForm}>
          Try Different Email
        </Button>
      </DialogActions>
    </>
  );

  return (
    <>
      <Button size="large" onClick={handleClickOpen}>
        <Card sx={styles.cardContainer}>
          <Typography sx={styles.actionCardText}>{dialogTitle}</Typography>
        </Card>
      </Button>
      <Dialog open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle textAlign={"left"}>{dialogTitle}</DialogTitle>
        {!subcribeFailed && !subscribeDetails && renderNewFormDialogContents()}
        {!subcribeFailed &&
          subscribeDetails &&
          renderSubscribeSuccessfulDialogContents()}
        {subcribeFailed &&
          subscribeDetails &&
          renderSubscribeFailedEmailExistsDialogContents()}
        {subcribeFailed &&
          !subscribeDetails &&
          renderSubscribeFailedOtherDialogContents()}
      </Dialog>
    </>
  );
};

export default SubscribePerson;

import React, { FC } from "react";
import { TextField, Typography, Paper, Container } from "@mui/material";
import { navigate } from "gatsby";
import { styles } from "../../styles/generalStyles";
import { MemberAccountComponentProps } from "../../dataTypes/member/memberComponentTypes";

const MemberAccountComponent: FC<MemberAccountComponentProps> = ({
  memberAccountView,
}) => {
  return (
    <Container disableGutters sx={styles.cardWidth}>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Account Information
        </Typography>

        <TextField
          margin="normal"
          fullWidth
          name="accountId"
          label="Account Id"
          variant="outlined"
          value={memberAccountView.accountId}
          onClick={() =>
            navigate(
              `/ManageAccount/retrieveAccount?accountID=${memberAccountView.accountId}`
            )
          }
          InputProps={styles.underlineInputProps}
        />
        <TextField
          margin="normal"
          fullWidth
          name="country"
          label="Country"
          variant="outlined"
          value={memberAccountView.country}
          disabled={true}
        />
        <TextField
          margin="normal"
          fullWidth
          name="type"
          label="Type"
          variant="outlined"
          value={memberAccountView.type}
          disabled={true}
        />
        <TextField
          margin="normal"
          fullWidth
          name="tier"
          label="Tier"
          variant="outlined"
          value={memberAccountView.tier}
          disabled={true}
        />
        <TextField
          margin="normal"
          fullWidth
          name="status"
          label="Status"
          variant="outlined"
          value={memberAccountView.status}
          disabled={true}
        />
        <TextField
          margin="normal"
          fullWidth
          name="legacyAccountId"
          label="Legacy Account Id"
          variant="outlined"
          value={memberAccountView.legacyAccountId}
          disabled={true}
        />
      </Paper>
    </Container>
  );
};

export default MemberAccountComponent;

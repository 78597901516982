export const styles = {
  underlineInputProps: {
    readOnly: true,
    style: { color: "blue", textDecoration: "underline" }
  },
  cardWidth: {
    width: { xl: "60%", lg: "70%", md: "80%", sm: "85%", xs: "96%" },
  },
};

export const photoStyles = {
  stack: {
    p: 0, mb: 0
  },
  avatar:{
    width: 78, height: 78
  }
}

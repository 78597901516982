// DemographicsComponent.js
import React, { FC, useState } from "react";
import { TextField, Button, Typography, Paper, Grid } from "@mui/material";
import { Demographic, DemographicsComponentInput } from "../../dataTypes/organization/orgTypes";

const DemographicsComponent: FC<DemographicsComponentInput> = ({ demographicsData, onUpdate }) => {
  const [demographics, setDemographics] = useState<Demographic>(demographicsData);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setDemographics({
      ...demographics,
      address: { ...demographics.address, [name]: value },
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    await onUpdate(demographics);
    setIsSubmitting(false);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4, mt: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Business Address
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          margin="normal"
          fullWidth
          name="line1"
          label="Address Line 1"
          variant="outlined"
          value={demographics?.address?.line1}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          fullWidth
          name="line2"
          label="Address Line 2"
          variant="outlined"
          value={demographics?.address?.line2 || ""}
          onChange={handleChange}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="city"
              label="City"
              variant="outlined"
              value={demographics?.address?.city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="stateProvince"
              label="State/Province"
              variant="outlined"
              value={demographics?.address?.stateProvince}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <TextField
          margin="normal"
          fullWidth
          name="postalCode"
          label="Postal Code"
          variant="outlined"
          value={demographics?.address?.postalCode}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          fullWidth
          name="country"
          label="Country"
          variant="outlined"
          value={demographics?.address?.country}
          onChange={handleChange}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Updating..." : "Update Business Address"}
        </Button>
      </form>
    </Paper>
  );
};

export default DemographicsComponent;

import React, { FC, useState, useEffect } from "react";
import {
  Button,
  Container,
  Typography,
  TextField,
  Paper,
  Select,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { generateUUID } from "../../utils/generateUUID";
import { useCurrentAccountInfo } from "../../utils/useCurrentAccountInfo";
import { styles } from "../../styles/generalStyles";
import { RegisterOrganizationComponentType, OrgAudit } from "../../dataTypes/organization/orgTypes";

const RegisterOrganizationComponent: FC<RegisterOrganizationComponentType> = ({ onUpdate }) => {
  const { username, warehouseLocation } = useCurrentAccountInfo();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const auditData: OrgAudit = {
    createdAtLocationNumber: warehouseLocation,
    createdByChannel: "Desktop",
    createdByUser: username,
    createdOnUtcDateTime: new Date(),
    mgloTransactionId: generateUUID(),
    mgloTransactionType: "MGLOSignup",
  };
  const InitialProfileData = {
    docType: "organizationProfile",
    audit: auditData,
  };
  const InitilaDemographicData = {
    docType: "organizationDemographics",
    audit: auditData,
    address: {},
  };
  const InitilaContactData = {
    phone: {
      prefix: "+",
      active: true,
    },
    docType: "organizationContacts",
    audit: auditData,
  };

  const [orgData, setOrgData] = useState({});
  const [profileData, setProfileData] = useState(InitialProfileData);
  const [demographicsData, setDemographicsData] = useState(
    InitilaDemographicData
  );
  const [demographicsAddressData, setDemographicsAddressData] = useState(
    InitilaDemographicData.address
  );
  const [contactData, setContactData] = useState(InitilaContactData);
  const [contactPhoneData, setContactPhoneData] = useState(
    InitilaContactData.phone
  );
  const [selectedPhoneTypeValue, setSelectedPhoneTypeValue] = useState("");
  const [selectedAddressTypeValue, setSelectedAddressTypeValue] = useState("");

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleDemographicChange = (e) => {
    const { name, value } = e.target;
    setDemographicsAddressData({
      ...demographicsAddressData,
      [name]: value,
    });
  };

  const handleSelectedPhoneTypeChange = (e) => {
    const { name, value } = e.target;
    setContactPhoneData({
      ...contactPhoneData,
      [name]: value,
    });
    setSelectedPhoneTypeValue(value);
  };

  const handleSelectedAddressTypeChange = (e) => {
    const { name, value } = e.target;
    setDemographicsAddressData({
      ...demographicsAddressData,
      [name]: value,
    });
    setSelectedAddressTypeValue(value);
  };

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContactPhoneData({
      ...contactPhoneData,
      [name]: value,
    });
  };

  useEffect(() => {
    setDemographicsData({
      ...demographicsData,
      ["address"]: demographicsAddressData,
    });
  }, [demographicsAddressData]);

  useEffect(() => {
    setContactData({
      ...contactData,
      ["phone"]: contactPhoneData,
    });
  }, [contactPhoneData]);

  useEffect(() => {
    setOrgData({ ...orgData, ["profile"]: profileData });
  }, [profileData]);
  useEffect(() => {
    setOrgData({ ...orgData, ["demographic"]: demographicsData });
  }, [demographicsData]);
  useEffect(() => {
    setOrgData({ ...orgData, ["contact"]: contactData });
  }, [contactData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    await onUpdate(orgData);
    setIsSubmitting(false);
  };

  return (
    <Container disableGutters  sx={{ justifyContent: "center", cardWidth: styles.cardWidth}}>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <form onSubmit={handleSubmit}>
          <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Enter Business Address
            </Typography>
            <TextField
              margin="normal"
              fullWidth
              name="legalName"
              label="Legal Name"
              variant="outlined"
              required={true}
              onChange={handleProfileChange}
            />
            <TextField
              margin="normal"
              fullWidth
              name="businessLicenseNumber"
              label="Business License Number"
              variant="outlined"
              onChange={handleProfileChange}
            />
            <TextField
              margin="normal"
              fullWidth
              name="country"
              label="Country"
              variant="outlined"
              required={true}
              onChange={handleProfileChange}
            />
            <TextField
              margin="normal"
              fullWidth
              name="statusDate"
              label="Status Date"
              type="date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              required={true}
              onChange={handleProfileChange}
            />
            <TextField
              margin="normal"
              fullWidth
              name="legacyOrganizationId"
              label="Legacy OrganizationId"
              variant="outlined"
              //required={true}
              onChange={handleProfileChange}
            />
          </Paper>
          <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Enter Business Address
            </Typography>
            <TextField
              margin="normal"
              fullWidth
              name="line1"
              label="Address Line 1"
              variant="outlined"
              required={true}
              onChange={handleDemographicChange}
            />
            <TextField
              margin="normal"
              fullWidth
              name="line2"
              label="Address Line 2"
              variant="outlined"
              onChange={handleDemographicChange}
            />
            <TextField
              margin="normal"
              fullWidth
              name="line3"
              label="Address Line 3"
              variant="outlined"
              onChange={handleDemographicChange}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  name="city"
                  label="City"
                  variant="outlined"
                  required={true}
                  onChange={handleDemographicChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  name="stateProvince"
                  label="State/Province"
                  variant="outlined"
                  required={true}
                  onChange={handleDemographicChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  name="postalCode"
                  label="Postal Code"
                  variant="outlined"
                  required={true}
                  onChange={handleDemographicChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  name="country"
                  label="Country"
                  variant="outlined"
                  required={true}
                  onChange={handleDemographicChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>Address Type</InputLabel>
                <Select
                  margin="none"
                  fullWidth
                  name="type"
                  label="Address Type"
                  variant="outlined"
                  value={selectedAddressTypeValue}
                  onChange={handleSelectedAddressTypeChange}
                >
                  <MenuItem value="BUSINESSADDRESS">BUSINESSADDRESS</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Paper>
          <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Enter Contact Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  name="prefix"
                  label="Prefix"
                  variant="outlined"
                  defaultValue={"+"}
                  required={true}
                  disabled={true}
                  onChange={handleContactChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  name="countryCode"
                  label="Country Code"
                  variant="outlined"
                  required={true}
                  onChange={handleContactChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  name="number"
                  label="Phone Number"
                  variant="outlined"
                  required={true}
                  onChange={handleContactChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  name="extension"
                  label="Extension"
                  variant="outlined"
                  onChange={handleContactChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>Phone Type</InputLabel>
                <Select
                  margin="none"
                  fullWidth
                  name="type"
                  label="Phone Type"
                  variant="outlined"
                  value={selectedPhoneTypeValue}
                  onChange={handleSelectedPhoneTypeChange}
                >
                  <MenuItem value="BUSINESSPHONE">BUSINESSPHONE</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Paper>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Updating..." : "Register Organization"}
          </Button>
        </form>
      </Paper>
      </Container>
  );
};
export default RegisterOrganizationComponent;

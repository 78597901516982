import {
  Button,
  Paper,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Autocomplete,
  Box,
  Chip,
} from "@mui/material";
import React, { useState } from "react";
import authConfig from "../../config/app-config.json";
import { msalInstance } from "../../service/msalConfig";
import useAxiosInstance from "../../service/useAxiosInstance";
import { axiosPostPromise } from "../../service/axiosApi";
import {
  HandleImmerUpdateType,
  ImmerUpdateType,
  useImmerUpdaters,
} from "../../utils/useImmerUpdaters";
import emailTypes from "../../data/emailTypes.json";
import phoneTypes from "../../data/phoneTypes.json";
import phoneCountryCodeItems from "../../data/phoneCountryCodes.json";
import { validateEmail } from "../../utils/validateEmailFormat";
import { styles, chipStyles } from "../../styles/actionCardsStyles";
import {
  Contact,
  Email,
  Emails,
  EmailType,
  PhoneType,
} from "../../dataTypes/person/personTypes";
import { Audited } from "../../dataTypes/sharedRequestTypes";

const emailValidationURL = authConfig.emailValidationURL;
const phoneCountryCodes = [
  ...new Set(phoneCountryCodeItems.map((x) => x.phone)),
];
const isNotEmptyString = (str: string | null | undefined) =>
  str != null && str.trim().length > 0;
const isValidPhoneNumberCount = (num: string) =>
  num != null && num.length != 10;
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

interface PersonContactProps {
  personInfo: Audited<Contact>;
  onUpdate: (personInformation: Audited<Contact>) => void;
  countryCode: string | null;
  isProtectedPerson: boolean;
}

interface PhoneInfoProps {
  personInfo: Contact;
  index: number;
  updatePersonInfo: ImmerUpdateType<Contact>;
  handleUpdatePersonInfo: HandleImmerUpdateType<Contact>;
  isProtectedPerson: boolean;
  isPhonesInvalid: boolean[];
  setPhonesChanged: (value: boolean[]) => void;
  phonesChanged: boolean[];
  isPhonesCountryCodeInvalid: boolean[];
}

interface EmailInfoProps {
  personInfo: Contact;
  index: number;
  updatePersonInfo: ImmerUpdateType<Contact>;
  isProtectedPerson: boolean;
  isValidating: boolean;
  setEmailsChanged: (value: boolean[]) => void;
  emailsValidity: boolean[];
  emailsChanged: boolean[];
}

function PhoneInfo({
  personInfo,
  index,
  updatePersonInfo,
  handleUpdatePersonInfo,
  isProtectedPerson,
  isPhonesInvalid,
  setPhonesChanged,
  phonesChanged,
  isPhonesCountryCodeInvalid,
}: PhoneInfoProps) {
  const handleTrackingPhoneChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    updatePersonInfo((p) => (p.phones[index].number = e.target.value));
    const phonesCopy = [...phonesChanged];
    phonesCopy[index] = true;
    setPhonesChanged(phonesCopy);
  };

  return (
    <Grid container spacing={3} sx={{ mb: 2 }}>
      <Grid item xs={12} sm={3}>
        <Autocomplete
          fullWidth
          autoHighlight
          id="country-code-phone"
          options={phoneCountryCodes}
          getOptionLabel={(phone) => `+${phone}`}
          value={personInfo.phones[index].countryCode}
          onChange={(e, v) =>
            updatePersonInfo((p) => {
              p.phones[index].prefix = v ? "+" : null;
              p.phones[index].countryCode = v;
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              defaultValue="+1"
              required
              error={!isPhonesCountryCodeInvalid[index]}
              label="Code"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          required
          id="number"
          name="number"
          label="Number"
          error={isPhonesInvalid[index]}
          fullWidth
          variant="outlined"
          value={personInfo.phones[index].number}
          onChange={handleTrackingPhoneChange}
          disabled={isProtectedPerson}
          helperText={isPhonesInvalid[index] ? "Invalid Phone Number" : " "}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth required>
          <InputLabel id="email-type-label">Phone Type</InputLabel>
          <Select
            id="type"
            name="type"
            label="Phone Type"
            variant="outlined"
            fullWidth
            value={personInfo.phones[index].type}
            onChange={handleUpdatePersonInfo(
              (p, v) => (p.phones[index].type = v as PhoneType),
            )}
          >
            {Object.keys(phoneTypes).map((key) => (
              <MenuItem key={key} value={key}>
                {phoneTypes[key]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

function EmailInfo({
  personInfo,
  index,
  updatePersonInfo,
  isProtectedPerson,
  isValidating,
  setEmailsChanged,
  emailsValidity,
  emailsChanged,
  
}: EmailInfoProps) {
  const renderSuccessChip = () => (
    <Chip
      label="Valid"
      size="small"
      sx={chipStyles.chip}
      color="success"
      variant="outlined"
    />
  );
  const renderWaningChip = () => (
    <Chip
      label="Invalid"
      size="small"
      sx={chipStyles.chip}
      color="warning"
      variant="outlined"
    />
  );
  const renderNAChip = () => (
    <Chip
      label="Invalid"
      sx={chipStyles.chip}
      size="small"
      variant="outlined"
    />
  );
  const renderValidatingChip = () => (
    <Chip
      label="Validating..."
      sx={chipStyles.chip}
      size="small"
      variant="outlined"
    />
  );

  const renderEmailChip = () => {
    if (isValidating) {
      return renderValidatingChip();
    } else if (!emailsValidity[index]) {
      return renderNAChip();
    } else if (!emailsChanged[index]) {
      if (personInfo.emails[index].valid) {
        return renderSuccessChip();
      } else {
        return renderWaningChip();
      }
    } else {
      return null;
    }
  };

  const handleTrackingEmailchange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updatePersonInfo((p) => (p.emails[index].address = e.target.value));
    const emailsCopy = [...emailsChanged];
    emailsCopy[index] = true;
    setEmailsChanged(emailsCopy);
  };

  return (
    <Grid container spacing={3} sx={{ mb: 1, mt: 0, p: 0 }}>
      <Grid item xs={12} sm={9} sx={{ mt: 0, pt: 0 }}>
        <TextField
          key={`email-${index}`}
          required
          id="address"
          name="email"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={personInfo.emails[index].address}
          onChange={(e) => handleTrackingEmailchange(e)}
          disabled={isProtectedPerson || isValidating}
          InputProps={{ endAdornment: renderEmailChip() }}
          helperText={
            !personInfo.emails[index].valid ? "Validation Required" : null
          }
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth required>
          <InputLabel id="email-type-label">Email Type</InputLabel>
          <Select
            id="emailType"
            name="Email type"
            label="Email Type"
            variant="outlined"
            value={personInfo.emails[index].type}
            onChange={(e) =>
              updatePersonInfo((p) => (p.emails[index].type = e.target.value as EmailType))
            }
            disabled={isProtectedPerson || isValidating}
          >
            {Object.keys(emailTypes).map((key) => (
              <MenuItem key={key} value={key}>
                {emailTypes[key]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

const PersonContact = ({
  personInfo,
  onUpdate,
  countryCode,
  isProtectedPerson,
}: PersonContactProps) => {
  const [personInformation, setPersonInformation] = useState(personInfo);
  const [updatePersonInfo, handleUpdatePersonInfo] = useImmerUpdaters(
    personInformation,
    setPersonInformation,
  );
  const axiosEmailValidationInstance = useAxiosInstance(
    emailValidationURL,
    msalInstance,
  );
  const [emailsChanged, setEmailsChanged] = useState(
    personInformation.emails.map((x) => false),
  );
  const [phonesChanged, setPhonesChanged] = useState(
    personInformation.phones.map((p) => false),
  );
  const [isValidating, setIsValidating] = useState(false);
  const headers = { "country-code": countryCode };

  // emails
  const allEmailValid = personInformation.emails.every((x) => x.valid === true);

  const handleValidateEmail = async (validateEmailFullData: Emails[]) => {
    setIsValidating(true);
    await sleep(800);
    try {
      const promises = validateEmailFullData.map((email: Emails) =>
        axiosPostPromise(
          axiosEmailValidationInstance,
          "validate-email",
          { email: email.address },
          headers,
        ),
      );

      await Promise.all(promises).then((results) => {
        updatePersonInfo((p) => {
          for (let i = 0; i < results.length; ++i) {
            p.emails[i].valid = results[i];
          }
        });
        setEmailsChanged(results.map(() => false));
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsValidating(false);
    }
  };

  // Validate Emails Form
  const emailsValidity = personInformation.emails.map(
    (email: Email) =>
      isNotEmptyString(email.address) && validateEmail(email.address),
  );

  // Validate Phones Form
  const isPhonesInvalid = personInformation.phones.map((phone) => {
    return (
      isNotEmptyString(phone.number) && isValidPhoneNumberCount(phone.number)
    );
  });

  const isPhonesCountryCodeInvalid = personInformation.phones.map((phone) => {
    return isNotEmptyString(phone.countryCode);
  });

  const handleSubmit = () => {
    if (!isPhonesInvalid && !isPhonesCountryCodeInvalid) {
      return;
    }
    onUpdate(personInformation);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Contact
      </Typography>
      <Grid container spacing={2} sx={{ justifyContent: "center" }}>
        <Grid item xs={12} sx={{ justifyContent: "center" }}>
          <Box component="section" sx={styles.boxContainer}>
            <Typography variant="subtitle1" sx={styles.boxSubTitle}>
              <b>Phone</b>
            </Typography>
            {personInformation.phones.length > 0 ? (
              personInformation?.phones.map((_: any, index: number) => (
                <PhoneInfo
                  key={index}
                  index={index}
                  personInfo={personInformation}
                  handleUpdatePersonInfo={handleUpdatePersonInfo}
                  updatePersonInfo={updatePersonInfo}
                  isProtectedPerson={isProtectedPerson}
                  isPhonesInvalid={isPhonesInvalid}
                  isPhonesCountryCodeInvalid={isPhonesCountryCodeInvalid}
                  setPhonesChanged={setPhonesChanged}
                  phonesChanged={phonesChanged}
                />
              ))
            ) : (
              <Typography
                variant="subtitle2"
                align="center"
                sx={{ mb: 2, mt: 1 }}
              >
                - Phone Not Found -
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ justifyContent: "center" }}>
        <Grid item xs={12} sx={{ justifyContent: "center" }}>
          <Box component="section" sx={styles.boxContainer}>
            <Typography variant="subtitle1" sx={styles.boxSubTitle}>
              <b>Email</b>
              <Grid
                item
                xs={4}
                sx={{
                  ml: "auto",
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 0,
                  alignItems: "center",
                  mb: 0,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => handleValidateEmail(personInformation.emails)}
                  disabled={isValidating || isProtectedPerson}
                >
                  Validate
                </Button>
              </Grid>
            </Typography>
            {personInformation.emails.length > 0 ? (
              personInformation?.emails.map((_: any, index: number) => (
                <EmailInfo
                  key={index}
                  index={index}
                  personInfo={personInformation}
                  isProtectedPerson={isProtectedPerson}
                  updatePersonInfo={updatePersonInfo}
                  emailsValidity={emailsValidity}
                  isValidating={isValidating}
                  emailsChanged={emailsChanged}
                  setEmailsChanged={setEmailsChanged}
                />
              ))
            ) : (
              <Typography
                variant="subtitle2"
                align="center"
                sx={{ mb: 2, mt: 1 }}
              >
                - Email Not Found -
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isProtectedPerson || isValidating || !allEmailValid}
      >
        Update Contact
      </Button>
    </Paper>
  );
};

export default PersonContact;

import createAxiosInstanceSilent from './createAxiosInstanceSilent';
import { useMsal } from "@azure/msal-react";

const useAxiosInstance = (baseURL: string, instance: any) => {  
  
  const { accounts } = useMsal()
  const axiosInstance = createAxiosInstanceSilent(baseURL, instance, accounts);

  return axiosInstance;
};

export default useAxiosInstance;
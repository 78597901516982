// AccountMemberComponent.js
import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Typography,
  Select,
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
} from "@mui/material";
import memberRoles from "../../data/memberRoles.json";
import { navigate } from "gatsby";
import { styles } from "../../styles/generalStyles";

const AccountMemberComponent = ({ memberInfoData }) => {
  const [members] = useState(memberInfoData);

  function MemberRoles(role) {
    const handleSelectChange = (e) => {
      role.onChange(e);
    };

    return (
      <Grid item xs={12} sm={6}>
        <FormControl margin="normal" fullWidth>
          <InputLabel>{role.label}</InputLabel>
          <Select
            defaultValue={role.value}
            id={role.id}
            //value={role.selectValue}
            name={role.name}
            label={role.label}
            onChange={handleSelectChange}
            disabled
          >
            {" "}
            {Object.keys(memberRoles).map(function (key) {
              return <MenuItem value={memberRoles[key]}>{key}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Grid>
    );
  }

  return (
    <Container disableGutters sx={styles.cardWidth}>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Member Information
        </Typography>
        {members.length > 0
          ? members.map((member, index) => (
              <Container key={index} sx={{ marginBottom: "20px" }}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  Member {index + 1}
                </Typography>
                <>
                  <TextField
                    margin="normal"
                    fullWidth
                    name="memberId"
                    label="Member Id"
                    variant="outlined"
                    className="redirect-link"
                    value={member.memberId}
                    InputProps={{
                      readOnly: true,
                    }}
                    onClick={() =>
                      navigate(
                        `../../ManageMember/retrieveMember?memberID=${member.memberId}`
                      )
                    }
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    name="legacyMemberId"
                    label="Legacy Member Id"
                    variant="outlined"
                    value={member.legacyMemberId}
                    disabled={true}
                  />
                  <MemberRoles
                    margin="normal"
                    fullWidth
                    name="role"
                    label="Member Role"
                    variant="outlined"
                    value={member.role}
                    disabled={true}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    name="status"
                    label="Member Status"
                    variant="outlined"
                    value={member.status}
                    disabled={true}
                  />
                </>
              </Container>
            ))
          : ""}
      </Paper>
    </Container>
  );
};

export default AccountMemberComponent;

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import BusinessIcon from "@mui/icons-material/Business";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import costcoLogo from "../../assets/CostcoLogo.png";
import { navigate } from "gatsby";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CountryPickerComponent from "../CountryPickerComponent";
import { CountryCodeContext } from "../../utils/context";

import "../../pages/index.css";

const drawerWidth = 300;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const MainListItems = ({
  setContext,
  handleLogin,
  countryCode,
  setCountryCode,
  open,
}) => {
  return (
    <>
      <ListItemButton
        className="MenuItem"
        onClick={() => {
          {
            setContext("Home");
            navigate("/");
          }
        }}
      >
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>

        <ListItemText primary="Home" />
      </ListItemButton>

      <Divider />

      <ListItemButton
        className="MenuItem"
        onClick={() => {
          {
            setContext("Membership Account");
            navigate("/");
          }
        }}
      >
        <ListItemIcon>
          <AccountBoxIcon />
        </ListItemIcon>

        <ListItemText primary="Membership Account" />
      </ListItemButton>
      <ListItemButton
        className="MenuItem"
        onClick={() => {
          {
            setContext("Membership Card");
            navigate("/");
          }
        }}
      >
        <ListItemIcon>
          <CardMembershipIcon />
        </ListItemIcon>
        <ListItemText primary="Membership Card" />
      </ListItemButton>
      <ListItemButton
        className="MenuItem"
        onClick={() => {
          {
            setContext("Organization Profile");
            navigate("/");
          }
        }}
      >
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary="Organization Profile" />
      </ListItemButton>
      <ListItemButton
        className="MenuItem"
        onClick={() => {
          {
            setContext("Person Profile");
            navigate("/");
          }
        }}
      >
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Person Profile" />
      </ListItemButton>

      <ListItemButton
        className="MenuItem"
        onClick={() => {
          {
            setContext("Member Profile");
            navigate("/");
          }
        }}
      >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Member Profile" />
      </ListItemButton>

      <ListItemButton className="MenuItem" onClick={handleLogin}>
        <ListItemIcon>
          <LockOpenIcon />
        </ListItemIcon>

        <ListItemText primary="Login with Azure AD" />
      </ListItemButton>

      <ListItemButton style={{ paddingLeft: open ? 15 : 0 }}>
        <CountryPickerComponent
          fromItemsMenu={true}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          countryList={["USA", "CAN"]}
        />

        <ListItemText primary="Pick a country" style={{ marginLeft: 20 }} />
      </ListItemButton>
    </>
  );
};

export default function MembershipItemsMenu({
  children,
  setCurrentContext,
  handleLogin,
}) {
  const [countryCode, setCountryCode] = useState("USA");
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const setContext = (value) => {
    setCurrentContext(value);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Membership Manager
          </Typography>
          <img style={{ width: "150px", margin: "10px" }} src={costcoLogo} />
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>

        <Divider />
        <List component="nav">
          <MainListItems
            setContext={setContext}
            handleLogin={handleLogin}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            open={open}
          />
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <CountryCodeContext.Provider value={countryCode}>
          {children}
        </CountryCodeContext.Provider>
      </Box>
    </Box>
  );
}

import { Container } from "@mui/material";
import { useLocation } from "@reach/router";
import React, { FC, useContext, useEffect, useState } from "react";
import CardComponent from "../../components/CardComponents/CardComponent";
import CardMemberComponent from "../../components/CardComponents/CardMemberComponent";
import SearchComponent from "../../components/SearchComponent";
import authConfig from "../../config/app-config.json";
import { axiosGetV2, axiosPostV2 } from "../../service/axiosApi";
import { msalInstance } from "../../service/msalConfig";
import useAxiosInstance from "../../service/useAxiosInstance";
import { useCurrentAccountInfo } from "../../utils/useCurrentAccountInfo";
import { generateUUID } from "../../utils/generateUUID";
import { useFetchByText } from "../../utils/useFetchByText";
import buttons from "../../data/cardEligibility.json";
import CardEligibilityComponent from "../../components/CardComponents/CardEligibilityComponent";
import TextSearchResultsComponent from "../../components/TextSearchResultsComponent";
import ReplaceCardDialog from "../../components/CardComponents/ReplaceCardDialog";
import { AlertContext, CountryCodeContext } from "../../utils/context";
import {
  Card,
  CardActionRequestBody,
  CardResponse,
  ReplaceCardResponse,
  ReplaceCardRequestBody,
  UpdateCardRequestBody,
  CardEligibleResponse,
  RetrieveCardProps,
} from "../../dataTypes/card/cardTypes";
import { TextSearchPersonResponse } from "../../dataTypes/search/textSearchTypes";
import { Action } from "../../dataTypes/shared/sharedRequestTypes";
import { Country } from "../../dataTypes/shared/locationTypes";

const { manageCardBaseURL, memberCardEligibilityUrl, replaceCardBaseURL } =
  authConfig;

const RetrieveCard: FC<RetrieveCardProps> = ({ fromAnySearch, searchId }) => {
  const [cardID, setCardID] = useState<string | null | undefined>(searchId);
  const [cardDetails, setCardDetails] = useState<CardResponse | null>(null);
  const [textSearchResult, setTextSearchResult] =
    useState<TextSearchPersonResponse | null>(null);
  const [checked, setChecked] = useState(0);
  const [isFromText, setIsFromText] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [replaceCardData, setReplaceCardData] =
    useState<ReplaceCardResponse | null>(null);
  const [replaceCardError, setReplaceCardError] = useState(null);
  const [replaceStatus, setReplaceStatus] = useState(null);

  const countryCode: Country = useContext(CountryCodeContext);

  const axiosInstance = useAxiosInstance(manageCardBaseURL, msalInstance);
  const axiosEligibilityInstance = useAxiosInstance(
    memberCardEligibilityUrl,
    msalInstance
  );
  const axiosReplaceInstance = useAxiosInstance(
    replaceCardBaseURL,
    msalInstance
  );
  const { username, warehouseLocation } = useCurrentAccountInfo();
  const getTextResults = useFetchByText();
  const alertFunction = useContext(AlertContext);
  const refreshCard = () => cardID != undefined && fetchCard(cardID);

  const updateSuccess = (data: CardResponse) =>
    alertFunction("updateSuccess", { data, refresh: refreshCard });
  const updateError = (error: Error) => alertFunction("updateError", error);
  const updateTextSearchResults = (data: TextSearchPersonResponse) =>
    alertFunction("updateTextSearchSuccess", data);
  const updateCardEligibleSuccess = (data: CardEligibleResponse) =>
    alertFunction("updateCardEligibleSuccess", { data, buttons, checked });

  const handleDialogClose = (e: React.MouseEvent) => {
    e.preventDefault();
    setDialogOpen(false);
    setReplaceCardData(null);
    setReplaceCardError(null);
    setReplaceStatus(null);
  };

  const headers = { headers: { "country-code": countryCode } };
  const location = useLocation();

  useEffect(() => {
    if (fromAnySearch) searchId != undefined && fetchCard(searchId);
  }, []);

  useEffect(() => {
    // Parse the query string to get the CardID value
    const queryParams = new URLSearchParams(location.search);
    const cardIdFromQuery = queryParams.get("CardID");
    const countryCodeFromQuery = queryParams.get("countryCode") as Country;
    if (cardIdFromQuery) {
      setCardID(cardIdFromQuery);
      if (location.pathname === "/ManageCard/retrieveCard/") {
        fetchCard(cardIdFromQuery);
      }
    }
    if (countryCodeFromQuery)
      headers.headers["country-code"] = countryCodeFromQuery;
  }, [location.search, location.pathname]);

  const handleCardIDChange = (id: string) => {
    setCardID(id);
  };

  const fetchCard = (id: string) => {
    setCardDetails(null);
    setTextSearchResult(null);
    setChecked(0);
    const isCardId = /^[1-9]{1}[0-9]{5,14}$/i.test(id);
    if (isCardId) {
      setIsFromText(false);
      axiosGetV2(
        axiosInstance,
        `retrieve-card-details/?cardNumber=${id}`,
        headers,
        setCardDetails,
        updateSuccess,
        updateError
      );
    } else {
      setIsFromText(true);
      getTextResults(
        id,
        false,
        countryCode,
        setTextSearchResult,
        updateTextSearchResults,
        updateError
      );
    }
  };

  const handleUpdate = (updateData: Card) => {
    const fullUpdateData: UpdateCardRequestBody = {
      memberId: cardDetails?.member?.memberId || "",
      cardNumber: cardDetails?.cardNumber || "",
      country: updateData.country || "USA",
      status: updateData.status,
      statusDate: updateData.statusDate,
      validFrom: updateData.validFrom,
      type: updateData.type,
      createdByChannel: "Desktop",
      createdByUser: username,
      createdAtLocationNumber: warehouseLocation || 99,
      mgloTransactionType: "MGLOUpdate",
      mgloTransactionId: generateUUID(),
      transactionDate: new Date().toISOString().toString(),
    };

    headers.headers["country-code"] =
      (updateData.country as Country) || countryCode;

    axiosPostV2(
      axiosInstance,
      "overwrite-card",
      fullUpdateData,
      headers,
      setCardDetails,
      updateSuccess,
      updateError
    );
  };

  const handleAction = (actionData: Card, action: Action) => {
    const fullActionData: CardActionRequestBody = {
      cardNumber: cardDetails?.cardNumber || "",
      mgloTransactionType: "MGLOUpdate",
      mgloTransactionId: generateUUID(),
      createdByUser: username,
      createdAtLocationNumber: warehouseLocation || 99,
      createdByChannel: "Desktop",
      transactionDate: new Date().toISOString(),
    };

    headers.headers["country-code"] =
      (actionData?.country as Country) || countryCode;
    const url: string[] = (action?.api?.url).split("/");
    const endpoint: string = url[url.length - 1];

    axiosPostV2(
      axiosInstance,
      endpoint,
      fullActionData,
      headers,
      setCardDetails,
      updateSuccess,
      updateError
    );
  };

  const handleReplaceCard = () => {
    const fullReplaceData: ReplaceCardRequestBody = {
      oldCardNumber: cardID || "",
      type: cardDetails?.card?.type || "", // Business, Goldstar, Transferable Company Card
      countryCode: countryCode || "USA",
      newCardInfo: {
        validFrom: new Date().toISOString().slice(0, 10),
      },
      audit: {
        createdByChannel: "Process",
        createdByUser: username,
        createdAtLocationNumber: warehouseLocation || 99,
        createdOnUtcDateTime: new Date().toISOString().slice(0, 10) || "",
        mgloTransactionType: "MGLOReplaceCard",
        mgloTransactionId: generateUUID(),
      },
    };

    headers.headers["country-code"] =
      (cardDetails?.card?.country as Country) || countryCode;

    axiosPostV2(
      axiosReplaceInstance,
      "replace-card",
      fullReplaceData,
      headers,
      () => {},
      setReplaceCardData,
      setReplaceCardError
    );

    setDialogOpen(true);
  };

  const handleReplaceRefresh = () => {
    headers.headers["country-code"] =
      (cardDetails?.card?.country as Country) || countryCode;

    axiosGetV2(
      axiosReplaceInstance,
      `status/${replaceCardData?.transactionId}`,
      headers,
      setReplaceStatus,
      () => {},
      setReplaceCardError
    );
  };

  const getEligibility = () => {
    axiosGetV2(
      axiosEligibilityInstance,
      `${buttons[checked].endpoint}?cardNumber=${cardID}`,
      {},
      () => {},
      updateCardEligibleSuccess,
      updateError
    );
  };

  return (
    <Container disableGutters sx={{ justifyContent: "center" }}>
      {!fromAnySearch && (
        <SearchComponent
          callback={fetchCard}
          label={"Enter Card ID"}
          handleIdChange={handleCardIDChange}
        />
      )}
      {cardDetails ? (
        !isFromText ? (
          <>
            <CardComponent
              cardData={cardDetails.card}
              onUpdate={handleUpdate}
              onAction={handleAction}
              onReplace={handleReplaceCard}
            />
            <CardEligibilityComponent
              getEligibility={getEligibility}
              checked={checked}
              setChecked={setChecked}
            />
            <CardMemberComponent memberData={cardDetails.member} />
          </>
        ) : textSearchResult?.results ? (
          <TextSearchResultsComponent data={textSearchResult?.results} />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      <ReplaceCardDialog
        open={dialogOpen}
        handleDialogClose={handleDialogClose}
        data={replaceCardData}
        error={replaceCardError}
        onRefresh={handleReplaceRefresh}
        refreshStatus={replaceStatus}
      />
    </Container>
  );
};

export default RetrieveCard;

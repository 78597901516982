import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from "@mui/material";

const ErrorPopupComponent = ({ open, onClose, errorDetails }) => {
	const { validationErrors, code, message } = errorDetails || {};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Error: {message}</DialogTitle>
			<DialogContent>
				{validationErrors &&
					Object.keys(validationErrors).map((key) => (
						<DialogContentText key={key}>
							{key}: {validationErrors[key]}
						</DialogContentText>
					))}
				<DialogContentText>Error Code: {code}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ErrorPopupComponent;

import React, { FC, useState } from "react";
import { OrgContacts, ContactsComponentInput } from "../../dataTypes/organization/orgTypes";

import {
  TextField,
  Button,
  Typography,
  Paper,
  Switch,
  FormControlLabel,
  Grid,
} from "@mui/material";


const ContactsComponent: FC<ContactsComponentInput> = ({ contactsData, onUpdate }) => {
  // Initialize state directly with the contactsData structure
  const [contact, setContact] = useState<OrgContacts>(contactsData);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldType: string) => {
    // Update state based on input field changes
    setContact({
      ...contact,
      [fieldType]: {
        ...contact[fieldType],
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    await onUpdate(contact);
    setIsSubmitting(false);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4, mt: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Contact Information
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          margin="normal"
          fullWidth
          name="address"
          label="Email Address"
          variant="outlined"
          value={contact?.email?.address}
          onChange={(e) => handleChange(e, "email")}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="normal"
          fullWidth
          name="type"
          label="Email Type"
          variant="outlined"
          value={contact?.email?.type}
          onChange={(e) => handleChange(e, "email")}
          sx={{ mb: 2 }}
          select
          SelectProps={{
            native: true,
          }}
        >
          <option>BUSINESSEMAIL</option>
        </TextField>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="prefix"
              label="Prefix"
              variant="outlined"
              value={contact?.phone?.prefix}
              onChange={(e) => handleChange(e, "phone")}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="countryCode"
              label="Country Code"
              variant="outlined"
              value={contact?.phone?.countryCode}
              onChange={(e) => handleChange(e, "phone")}
              sx={{ mb: 2 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="number"
              label="Phone Number"
              variant="outlined"
              value={contact?.phone?.number}
              onChange={(e) => handleChange(e, "phone")}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="extension"
              label="Extension"
              variant="outlined"
              value={contact?.phone?.extension}
              onChange={(e) => handleChange(e, "phone")}
              sx={{ mb: 2 }}
            />
          </Grid>
        </Grid>
        <TextField
          margin="normal"
          fullWidth
          name="type"
          label="Phone Type"
          variant="outlined"
          value={contact?.phone?.type}
          onChange={(e) => handleChange(e, "phone")}
          sx={{ mb: 2 }}
          select
          SelectProps={{
            native: true,
          }}
        >
          <option>BUSINESSPHONE</option>
        </TextField>
        <FormControlLabel
          control={
            <Switch
              checked={contact?.phone?.active}
              onChange={(e) => handleChange(e, "phone")}
              sx={{ mb: 2 }}
            />
          }
          label="Active"
          sx={{ mb: 2 }}
        />
        <br />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Updating..." : "Update Contacts"}
        </Button>
      </form>
    </Paper>
  );
};

export default ContactsComponent;

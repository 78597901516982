import { MemberRoleAndEndpoint } from "../dataTypes/member/memberTypes";

export const memberRoles: MemberRoleAndEndpoint[] = [
  {
    role: "Primary",
    endpoint: "initiate-primary-member",
  },
  {
    role: "Household",
    endpoint: "initiate-household-member",
  },
  {
    role: "Payer",
    endpoint: "initiate-payer-member",
  },
  {
    role: "Administrator",
    endpoint: "initiate-admin-member",
  },
  {
    role: "Advocate",
    endpoint: "initiate-advocate-member",
  },
  {
    role: "Addon",
    endpoint: "initiate-addon-member",
  },
  {
    role: "(Business) Owner",
    endpoint: "initiate-owner-member",
  },
  {
    role: "(Business) Manager",
    endpoint: "initiate-manager-member",
  },
];

// OrderComponent.js
import React, { useState } from "react";
import { TextField, Button, Container, Typography, Paper } from "@mui/material";
import countryList from "../../data/countryList.json";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { styles } from "../../styles/generalStyles";

function CountryList(country) {
  const handleSelectChange = (e) => {
    country.onChange(e);
  };
  return (
    <Grid item xs={12} sm={6}>
      <FormControl margin="normal" fullWidth>
        <InputLabel>{country.label}</InputLabel>
        <Select
          defaultValue={country.value}
          id={country.id}
          name={country.name}
          label={country.label}
          onChange={handleSelectChange}
          readOnly
        >
          {Object.keys(countryList).map(function (key) {
            return <MenuItem value={countryList[key]}>{key}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </Grid>
  );
}
const OrderComponent = ({ orderData, orderId }) => {
  const [order, setOrder] = useState({ ...orderData, orderId });
  const [updatePayload, setUpdatePayload] = useState({
    orderId: orderId,
  });

  return (
    <Container disableGutters sx={styles.cardWidth}>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Order Information
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          name="orderId"
          label="Order ID"
          variant="outlined"
          value={order.orderId}
          readonly
        />
        {order.organizationId && (
          <TextField
            margin="normal"
            fullWidth
            name="organizationId"
            label="Organization Id"
            variant="outlined"
            value={order.organizationId}
            readonly
          />
        )}
        <TextField
          margin="normal"
          fullWidth
          name="accountId"
          label="Account ID"
          variant="outlined"
          value={order.accountId}
          readonly
        />
        <TextField
          margin="normal"
          fullWidth
          name="memberCardNumber"
          label="Member Card Number"
          variant="outlined"
          value={order.memberCardNumber}
          readonly
        />
        <TextField
          margin="normal"
          fullWidth
          name="accountTier"
          label="Account Tier"
          variant="outlined"
          value={order.accountTier}
          readonly
        />
        {order.accountCountry && (
          <CountryList
            margin="normal"
            fullWidth
            name="accountCountry"
            label="Account Country"
            variant="outlined"
            value={order.accountCountry}
            readonly
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="paymentReferenceId"
              label="Payment Reference Id"
              variant="outlined"
              value={order.paymentReferenceId}
              readonly
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="amountBeforeTax"
              label="Amount Before Tax"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={order.amountBeforeTax}
              readonly
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="tax"
              label="Tax"
              variant="outlined"
              value={order.tax}
              readonly
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="totalAmount"
              label="Total Amount"
              variant="outlined"
              value={order.totalAmount}
              readonly
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="currency"
              label="Currency"
              variant="outlined"
              value={order.currency}
              readonly
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="status"
              label="Status"
              variant="outlined"
              value={order.status}
              readonly
            />
          </Grid>
        </Grid>
      </Paper>
      {order.lineItems?.map((val, index) => (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Line Item {index + 1}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                margin="normal"
                fullWidth
                name="itemNumber"
                label="Item Number"
                variant="outlined"
                value={val.itemNumber}
                readonly
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="normal"
                fullWidth
                name="quantity"
                label="Quantity"
                variant="outlined"
                value={val.quantity}
                readonly
              />
            </Grid>
          </Grid>
          {(val.itemAccountId || val.itemTransferableCardNumber) && (
            <Grid container spacing={2}>
              {val.itemAccountId && (
                <Grid item xs={12} md={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    name="itemAccountId"
                    label="Item Account Id"
                    variant="outlined"
                    value={val.itemAccountId}
                    readonly
                  />
                </Grid>
              )}
              {val.itemTransferableCardNumber && (
                <Grid item xs={12} md={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    name="itemTransferableCardNumber"
                    label="Item Transferable Card Number"
                    variant="outlined"
                    value={val.itemTransferableCardNumber}
                    readonly
                  />
                </Grid>
              )}
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                margin="normal"
                fullWidth
                name="baseFee"
                label="baseFee"
                variant="outlined"
                value={val.baseFee}
                readonly
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="normal"
                fullWidth
                name="tax"
                label="Tax"
                variant="outlined"
                value={val.tax}
                readonly
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                margin="normal"
                fullWidth
                name="adjustedFee"
                label="Adjusted Fee"
                variant="outlined"
                value={val.adjustedFee}
                readonly
              />
            </Grid>
          </Grid>
        </Paper>
      ))}
    </Container>
  );
};

export default OrderComponent;

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import authConfig from "../config/app-config.json";

interface Scope {
    url: string;
    scope: string;
}

const getServiceScopeByUrl = (baseUrl: string) => {
  const authScopes: Scope[] = authConfig?.authScopes || null;
  if (authScopes) {
    for (const scope of authScopes) {
        if (scope?.url === baseUrl) {
            return scope?.scope;
        }
    }
  }
  return null;
}

const createAxiosInstanceSilent = (baseURL: string, instance: any, accounts: any): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL
  });
  const scope = getServiceScopeByUrl(baseURL);
    axiosInstance.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        try {
          const silentTokenResponse = await instance.acquireTokenSilent({ scopes: [scope], account: accounts[0],
        });
          if (silentTokenResponse && silentTokenResponse.accessToken) {
            if (config.headers) {
              config.headers['Authorization'] = `Bearer ${silentTokenResponse.accessToken}`;
            } else {
              config.headers = { Authorization: `Bearer ${silentTokenResponse.accessToken}` };
            }
          }
        } catch (error) {
          console.error("Silent token acquisition failed:", error);
        }
  
        return config;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: any) => {
      if (error.response && error.response.status === 401) {
        try {
          await instance.loginRedirect();
        } catch (error) {
          console.error("Login redirect failed:", error);
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default createAxiosInstanceSilent;
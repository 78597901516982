import React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
const LoginFailed = () => {
  return (
    <Stack sx={{ width: "100%", mb: 1 }} spacing={0}>
      <Alert severity="error">
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            fontSize: "1rem",
          }}
        >
          <b>Login Failed, Please try again.</b> 
        </Typography>
      </Alert>
    </Stack>
  );
};
export default LoginFailed;
import React, { FC } from "react";
import { Container, TextField, Typography, Paper } from "@mui/material";
import { navigate } from "gatsby";
import { styles } from "../../styles/generalStyles";
import { MemberPersonComponentProps } from "../../dataTypes/member/memberComponentTypes";

const MemberPersonComponent: FC<MemberPersonComponentProps> = ({
  memberPersonView,
}) => {
  return (
    <Container disableGutters sx={styles.cardWidth}>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Person Information
        </Typography>

        <TextField
          margin="normal"
          fullWidth
          name="personId"
          label="Person Id"
          variant="outlined"
          value={memberPersonView.personId}
          onClick={() =>
            navigate(
              `/ManagePerson/retrievePerson?personID=${memberPersonView.personId}`
            )
          }
          InputProps={styles.underlineInputProps}
        />
        <TextField
          margin="normal"
          fullWidth
          name="status"
          label="Status"
          variant="outlined"
          value={memberPersonView.status}
          disabled={true}
        />
        <TextField
          margin="normal"
          fullWidth
          name="legacyCrmBpNumber"
          label="Legacy Person Id"
          variant="outlined"
          value={memberPersonView.legacyCrmBpNumber}
          disabled={true}
        />
      </Paper>
    </Container>
  );
};

export default MemberPersonComponent;

import React, { useState, useEffect } from "react";
import { Stack, Avatar } from "@mui/material";
import useAxiosInstance from "../../service/useAxiosInstance";
import { msalInstance } from "../../service/msalConfig";
import { axiosGetExtended } from "../../service/axiosApi";
import authConfig from "../../config/app-config.json";
import { photoStyles } from "../../styles/generalStyles";
import {
  PersonViewResponse,
  PhotoResponse,
} from "../../dataTypes/person/personRequestTypes";
import { Card } from "../../dataTypes/person/personViewTypes";

const baseURLV3 = authConfig.basePersonUrlV3;

interface PersonDetailsProps {
  personDetails: PersonViewResponse | null;
}

const PersonPhoto = ({ personDetails }: PersonDetailsProps) => {
  const [personPhoto, setPersonPhoto] = useState<PhotoResponse | null>(null);
  const { personId } = personDetails!;
  const countryCode =  personDetails!.person.profile.country || "USA";
  const axiosInstance = useAxiosInstance(baseURLV3, msalInstance);
  const headers = { "country-code": countryCode };
  const personCardNumber = personDetails?.cards?.find(
    (c: Card) => c.status === "Active",
  )?.cardNumber;

  const v3FetchPhoto = async (cardNumber?: string) => {
    setPersonPhoto(null);
    axiosGetExtended(
      axiosInstance,
      "retrieve-person-photo",
      null,
      { personId, cardNumber },
      headers,
      setPersonPhoto,
      null,
      null,
    );
  };

  useEffect(() => {
    v3FetchPhoto(personCardNumber);
  }, [personCardNumber]);

  return (
    <Stack direction="row" spacing={2} sx={photoStyles.stack}>
      <Avatar
        alt={personDetails?.person?.profile?.firstName}
        src={
          personPhoto ? `data:image/jpeg;base64,${personPhoto.photo.image}` : ""
        }
        sx={photoStyles.avatar}
      />
    </Stack>
  );
};

export default PersonPhoto;

import React, { useContext, useState, useEffect, FC } from "react";
import { Typography, Container, Paper } from "@mui/material";
import MemberComponent from "../../components/MemberComponents/MemberComponent";
import { useLocation } from "@reach/router";
import MemberPersonComponent from "../../components/MemberComponents/MemberPersonComponent";
import MemberCardComponent from "../../components/MemberComponents/MemberCardComponent";
import SearchComponent from "../../components/SearchComponent";
import MemberAccountComponent from "../../components/MemberComponents/MemberAccountComponent";
import { generateUUID } from "../../utils/generateUUID";
import { useCurrentAccountInfo } from "../../utils/useCurrentAccountInfo";
import useAxiosInstance from "../../service/useAxiosInstance";
import { msalInstance } from "../../service/msalConfig";
import { axiosGetV2, axiosPostV2 } from "../../service/axiosApi";
import authConfig from "../../config/app-config.json";
import { useFetchByText } from "../../utils/useFetchByText";
import TextSearchResultsComponent from "../../components/TextSearchResultsComponent";
import { styles } from "../../styles/generalStyles";
import { AlertContext, CountryCodeContext } from "../../utils/context";
import { RetrieveMemberProps } from "../../dataTypes/member/memberComponentTypes";
import {
  MemberCard,
  MemberCardRequestBody,
  MemberDetailsResponse,
  MemberInfoResponse,
} from "../../dataTypes/member/memberTypes";
import { TextSearchPersonResponse } from "../../dataTypes/search/textSearchTypes";
import { ErrorResponse, SuccessData } from "../../dataTypes/alert/alertTypes";
import { Country } from "../../dataTypes/shared/locationTypes";

const { baseManageMemberUrl, manageCardBaseURL } = authConfig;

const RetrieveMember: FC<RetrieveMemberProps> = ({
  fromAnySearch,
  searchId,
}) => {
  const [memberID, setMemberID] = useState<string | undefined>(searchId);
  const [memberInfo, setMemberInfo] = useState<MemberInfoResponse | null>(null);
  const [memberDetails, setMemberDetails] =
    useState<MemberDetailsResponse | null>(null);
  const [isFromText, setIsFromText] = useState<boolean>(false);
  const [textSearchResults, setTextSearchResults] =
    useState<TextSearchPersonResponse | null>(null);
  const alertFunction = useContext(AlertContext);
  const countryCode = useContext(CountryCodeContext);
  const { username, warehouseLocation } = useCurrentAccountInfo();
  const axiosInstance = useAxiosInstance(baseManageMemberUrl, msalInstance);
  const axiosInstanceCard = useAxiosInstance(manageCardBaseURL, msalInstance);
  const headers = { headers: { "country-code": countryCode } };
  const getTextResults = useFetchByText();
  const location = useLocation();
  const refreshMember = () => memberID && fetchMember(memberID);

  const updateTextSearchSuccess = (data: TextSearchPersonResponse) =>
    alertFunction("updateTextSearchSuccess", data);
  const updateError = (error: ErrorResponse) =>
    alertFunction("updateError", error);
  const updateSuccess = (
    data: SuccessData,
    refresh: () => void,
    message: string
  ) => alertFunction("updateSuccess", { data, refresh, message });

  useEffect(() => {
    if (fromAnySearch) searchId && fetchMember(searchId);
  }, []);

  useEffect(() => {
    // Parse the query string to get the memberID value
    const queryParams = new URLSearchParams(location.search);
    const memberIdFromQuery = queryParams.get("memberID");
    const countryCodeFromQuery = queryParams.get("countryCode") as Country;
    if (memberIdFromQuery) {
      setMemberID(memberIdFromQuery);
      if (location.pathname === "/ManageMember/retrieveMember/") {
        fetchMember(memberIdFromQuery);
      }
    }
    if (countryCodeFromQuery)
      headers.headers["country-code"] = countryCodeFromQuery;
  }, [location.search, location.pathname]);

  const handleMemberIDChange = (id: string) => {
    setMemberID(id);
  };

  const fetchMember = (id: string) => {
    setMemberInfo(null);
    setMemberDetails(null);

    const isMemberId =
      /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/i.test(id);

    if (isMemberId) {
      setIsFromText(false);
      axiosGetV2(
        axiosInstance,
        `retrieve-member-info/${id}`,
        headers,
        setMemberInfo,
        updateSuccess,
        updateError
      );
      axiosGetV2(
        axiosInstance,
        `retrieve-member-details?memberId=${id}`,
        headers,
        setMemberDetails,
        updateSuccess,
        updateError
      );
    } else {
      setIsFromText(true);
      getTextResults(
        id,
        false,
        countryCode,
        setTextSearchResults,
        updateTextSearchSuccess,
        updateError
      );
    }
  };

  const handleUpdate = (data: MemberInfoResponse) => {
    const updatePath = "overwrite-member";
    const onSuccessMessage = "Member info updated successfully!";
    const fullUpdateData = {
      ...data,
      audit: {
        createdByChannel: "Desktop",
        createdByUser: username,
        createdAtLocationNumber: warehouseLocation || 99,
        mgloTransactionType: "MGLOUpdate",
        mgloTransactionId: generateUUID(),
        createdOnUtcDateTime: new Date(),
      },
    };
    headers.headers["country-code"] = data.enrollment.country || countryCode;

    const updateOverwriteSuccess = (data: SuccessData) =>
      updateSuccess(data, refreshMember, onSuccessMessage);

    axiosPostV2(
      axiosInstance,
      updatePath,
      fullUpdateData,
      headers,
      () => {},
      updateOverwriteSuccess,
      updateError
    );
  };
  // handle Actions Data
  const handleAction = (
    actionURL: string,
    actionData: MemberInfoResponse,
    onSuccessMessage: string
  ) => {
    const fullActionData = {
      ...actionData,
      createdByChannel: "Desktop",
      createdByUser: username,
      createdAtLocationNumber: warehouseLocation || 99,
      mgloTransactionType: "MGLOUpdate",
      mgloTransactionId: generateUUID(),
      createdOnUtcDateTime: new Date(),
      pncAcknowledgementDate: new Date(),
    };
    headers.headers["country-code"] =
      actionData.enrollment.country || countryCode;

    const updateActionSuccess = (data: SuccessData) =>
      updateSuccess(data, refreshMember, onSuccessMessage);

    axiosPostV2(
      axiosInstance,
      actionURL,
      fullActionData,
      headers,
      () => {},
      updateActionSuccess,
      updateError
    );
  };
  //handle adding New Card
  const handleCardUpdate = (data: MemberCard) => {
    const updatePath = "issue-card";
    const onSuccessMessage = "Card added successfully!";

    const updateCardSuccess = (data: SuccessData) =>
      updateSuccess(data, refreshMember, onSuccessMessage);

    axiosPostV2(
      axiosInstanceCard,
      updatePath,
      data,
      headers,
      () => {},
      updateCardSuccess,
      updateError
    );
  };

  return (
    <Container disableGutters sx={{ justifyContent: "center" }}>
      {!fromAnySearch && (
        <SearchComponent
          callback={fetchMember}
          label={"Enter Member ID"}
          handleIdChange={handleMemberIDChange}
        />
      )}
      {memberInfo && memberDetails && !isFromText ? (
        <>
          <MemberComponent
            memberInfoData={memberInfo}
            memberDetailsData={memberDetails}
            onUpdate={handleUpdate}
            onAction={handleAction}
          />
          {memberDetails.account ? (
            <MemberAccountComponent memberAccountView={memberDetails.account} />
          ) : (
            <Container disableGutters sx={styles.cardWidth}>
              <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Account Information
                </Typography>
                <Typography>Account Information not available.</Typography>
              </Paper>
            </Container>
          )}
          {memberDetails.person ? (
            <MemberPersonComponent memberPersonView={memberDetails.person} />
          ) : (
            <Container disableGutters sx={styles.cardWidth}>
              <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Person Information
                </Typography>
                <Typography>Person Information not available.</Typography>
              </Paper>
            </Container>
          )}
          {memberDetails.cards ? (
            <MemberCardComponent
              memberCardView={memberDetails.cards}
              onAdd={handleCardUpdate}
            />
          ) : (
            <Container disableGutters sx={styles.cardWidth}>
              <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Card Information
                </Typography>
                <Typography>Card Information not available.</Typography>
              </Paper>
            </Container>
          )}
        </>
      ) : (
        isFromText &&
        textSearchResults?.results && (
          <TextSearchResultsComponent data={textSearchResults?.results} />
        )
      )}
    </Container>
  );
};

export default RetrieveMember;
